import { Component, OnInit } from '@angular/core';
import { isInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { AppSettingsService } from '../app-settings.service';
import { QuoteService } from '../services/quote.service';
import { CodeValueDto } from './CodeValueDto.Model';
import { ClientDto } from './ClientDto.Model';
import { ProductDto } from './ProductDto.Model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Identifiers } from '@angular/compiler/src/render3/r3_identifiers';
import { SecondaryQuoteDto } from './SecondaryQuoteDto.Model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-quoting',
  templateUrl: './quoting.component.html',
  styleUrls: ['./quoting.component.scss'],
  providers: [QuoteService]
})
export class QuotingComponent implements OnInit {

  disabledControls: boolean;
  currencies: CodeValueDto[];
  issuers: CodeValueDto[];
  quote: SecondaryQuoteDto = new SecondaryQuoteDto();
  isSaving = false;
  isCloneChild = false;
  isStampDuty = false;
  valError = false;
  errors: string[];
  clonefrom = '';

  ngOnInit() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.clonefrom = queryParams.get("clonefrom");
      if (this.clonefrom && this.clonefrom.indexOf("child") >= 0) {
        this.isCloneChild = true;
        this.clonefrom = this.clonefrom.replace("child", "");
      }

    })
  
    this.activatedRoute.params.subscribe(params => {
      let nbr = params['nbr'];
      if (nbr) {
        this.quoteService.GetByChildNbr(nbr).subscribe(r => this.quote = r);
      } else {
        this.quote = new SecondaryQuoteDto();
        this.quote.New = true;
        this.quote.SendAuto = true;
      }

    });
    if (Boolean(this.clonefrom)) {
      this.quoteService.GetByChildNbr(this.clonefrom).subscribe(r => this.quote = r);
   
    }

  }

  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute, private notifierService: NotifierService, private router: Router) {

    this.quoteService.GetAllCurrency().subscribe(data => this.currencies = data,
      error => console.log(error),
      () => console.log('Get all currencies complete'));

    this.quoteService.GetAllIssuer().subscribe(data => this.issuers = data,
      error => console.log(error),
      () => console.log('Get all issuers complete'));

  }

  sides = [
    { id: 1, name: "Bid" },
    { id: 2, name: "Ask" }
  ];

  parseClient(client: ClientDto) {
    this.quote.ClientCode = client.ClientCode;
    this.quote.ClientId = client.Id;
  }
  checkStampduty(event) {
    if (this.quote && this.quote.ClientCode && this.quote.ISIN)
      this.quoteService.checkStampduty(this.quote.ClientCode, this.quote.ISIN).subscribe(c => {
        this.isStampDuty = c;
      },
        e => {
          this.isStampDuty = false;

        });
    else

      return;
  }

  onChangeSelected(event) {
    this.quote.CurrencyDesc = this.currencies.find(s => s.Code === this.quote.CurrencyCode).Description;
  }

  parseProd(product: ProductDto) {
    console.log("selected : " + product.ISIN);
    this.quote.Product = product;
    this.quote.ProductId = product.Id;
    this.quote.ProductDesc = product.Name;
    this.quote.ISIN = product.ISIN;
    this.quote.IssuerCode = product.Issuer;
    this.quote.CurrencyCode = product.Currency;
    this.disabledControls = true;

  }

  parseIsin(val: string) {
    console.log("selected : " + val);
    this.quote.ISIN = val;
  }

  async updateDealCatcher() {

    let CefSharp = (window as any).CefSharp;
    if (!CefSharp)
      return;

    let dealcatcherevents = (window as any).dealcatcherevents;

    if (!dealcatcherevents) {
      await CefSharp.BindObjectAsync("dealcatcherevents");
    }

    dealcatcherevents = (window as any).dealcatcherevents;
    dealcatcherevents.newsecondaryquotedone();
  }

  sendRequest() {
    if (Boolean(this.clonefrom)) {
      
      this.quote.New = true;
      this.quote.SendAuto = true;
      this.quote.QuoteChildNbr = "";
      if (this.isCloneChild) this.quote.CloneChild = true;
    }

    this.valError = false;
    this.errors = [];
    if (!this.quote.ISIN) {
      this.errors.push("Isin is required");
      this.valError = true;
    }
    if (!this.quote.Side) {
      this.errors.push("Side is required");
      this.valError = true;
    }
    if (!this.quote.IssuerCode) {
      this.errors.push("Issuer is required");
      this.valError = true;
    }
    if (!this.quote.Size) {
      this.errors.push("Size is required");
      this.valError = true;
    }
    if (!this.quote.CurrencyCode) {
      this.errors.push("Currency is required");
      this.valError = true;
    }

    console.log(this.errors);
    let self = this;

    if (!this.valError) {
      this.isSaving = true;
      this.quoteService.SaveSecondaryQuote(this.quote).subscribe(c => {
        this.isSaving = false;
        this.notifierService.notify("success", "Quote sent");
        this.router.navigate(['/quoting/list']);
        self.updateDealCatcher();
      },
        e => {
          this.isSaving = false;
          this.notifierService.notify("error", "Retry later");
        });
    }
  }


  updateQuote() {
    let self = this;

    this.isSaving = true;
    this.quoteService.UpdateSecondaryQuote(this.quote).subscribe(c => {
      this.isSaving = false;
      this.quote = c;
      this.notifierService.notify("success", "Quote updated")
      self.updateDealCatcher();
    },
      e => {
        this.isSaving = false;
        this.notifierService.notify("error", "Retry later");
      });
  }


}
