

export class SolveForDto {
 

  public IssuerCode: string;
  public QuoteNbr: string;
  public SolveForValue: number;

  


}
