import { EventEmitter } from '@angular/core';
import { Component, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';
import { DataService } from '../services/data.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'code-value',
  templateUrl: './code-value.component.html',
  styleUrls: ['./code-value.component.scss']
})
export class CodeValueComponent implements OnInit {

  codeValues: Array<CodeValueDto>;
  _code: string;
  value: string;
  @Input() parentCode: string;

  @Input()
  set type(val: string) {
    console.log("codetype", val);
  
    this.value = val;
    let id = "";
    if (val == "Issuer") {
      if (this.filter) {
        setTimeout(() => {
          this.dataService.GetQuoteChildId(this.quoteNbr).subscribe(e => {
            if (e != null) {
              id = e.Id.toString();
              this.dataService.GetPricingIssuers(id).subscribe(c => {
                c = c.filter(function (el) {
                  return el.Description != null && el.Description != "";
                });
                this.codeValues = c;
                if (!this.isPQ && this.codeValues.length == 1) {
                  this.sendSQIssuerValue.emit(this.codeValues[0].Code);
                }
              });
            }
          });
        }, 1800);
      
     
      } else {
        this.dataService.GetOpenedIssuers().subscribe(c => {

          c = c.filter(function (el) {
            return el.Description != null && el.Description != "";
          });
          this.codeValues = c;

        });
      }
     
   

    } else if (val == "StrikeType") {
      this.dataService.GetCodeValuesByType(val).subscribe(c => {
        c = c.filter(function (el) {
          return el.Description != null && el.Description != "" && el.Description != "Look back";
        });
        this.codeValues = c;

      });
    }

    else {
      this.dataService.GetCodeValuesByType(val).subscribe(c => {
        let THIS = this;
        if (this.parentCode == '#1#2' ) {
          c = c.filter(function (el) {
            return el.Description != null && el.Description != "" && el.ParentCode !='#1#2';
          });
        } else  {
          c = c.filter(function (el) {
            return el.Description != null && el.Description != "";
          });
        }
     
        this.codeValues = c;

      });
    }
  
  }

  @Input()
  set code(val: string) {
    if (this._code != val) {
      this._code = val;
      this.codeChange.emit(val);
 
      
    }
  }

  get code() {
    return this._code;
  }

  @Output()
  codeChange: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  changeIssuer: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  changeCusto: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  sendSQIssuerValue: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled;

  @Input()
  isPQ;

  @Input()
  quoteNbr;

  @Input()
  filter = false;

  @Input()
  pricingIssuers = "";

  bestIssuer = "Best Between requested Issuer";

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    // this.dataService.GetCodeValues().subscribe(c => this.codeValues = c);
   }
 
  getDescription(event) {
    if (this.value == 'Issuer') {
      let text = event.target.options[event.target.options.selectedIndex].text;
      this.changeIssuer.emit("!" + text + ":" + this._code + ";");
    } else if (this.value == 'Custodian') {
      let text = event.target.options[event.target.options.selectedIndex].text;
      this.changeCusto.emit(text);

    }
  }


}
