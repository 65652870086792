<div class="container">
  <h3 mat-dialog-title>Add new Line</h3>

  <form (ngSubmit)="submit" #formControl="ngForm">

    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1">Issuer</label>
        <div class="col-md-3">
          <select class="form-control" matInput #input placeholder="Issuer" required [(ngModel)]="data.IssuerCode" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let cv of data.issuers" [ngValue]="cv.Code">
              {{cv.Description}}
            </option>
          </select>
        </div>
      </div>


      <div class="form-group form-group-sm">
        <label class="col-md-1">Currency</label>
        <div class="col-md-3">
          <select class="form-control" matInput #input placeholder="Currency" required [(ngModel)]="data.CurrencyCode" [ngModelOptions]="{standalone: true}">
            <option *ngFor="let cv of data.currencies" [ngValue]="cv.Code">
              {{cv.Description}}
            </option>
          </select>
        </div>
      </div>

    </div>
    <div class="row"></div>
    <div class="row">

      <div class="form-group form-group-sm">
        <label class="col-md-2">Bloomberg Code</label>
        <div *ngIf="!data.PriceOff" class="col-md-3">
          <input type="text" class="form-control" [(ngModel)]="data.BloombergCode" [ngModelOptions]="{standalone: true}" />
        </div>
      </div>

      <div class="form-group form-group-sm">
        <label class="col-md-1">ISIN</label>
        <div class="col-md-3">
          <input type="text" class="form-control" placeholder="ISIN" [(ngModel)]="data.ISIN" id="comment" rows="3" name="comment" />
        </div>
      </div>

    </div>


    <div mat-dialog-actions>
      <button type="button" class="btn btn-primary" [type]="submit" [disabled]="!formControl.valid" [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
      <button type="button" class="btn btn-primary" (click)="onNoClick()" tabindex="-1">Cancel</button>
    </div>
  </form>
</div>



