<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<ol class="breadcrumb row">
  <li><a (click)="goToHome()">Home</a></li>
  <li><a (click)="goToProducts()">Products</a></li>
  <!--<li>{{isin}} - {{productName}} </li>-->
</ol>
<br />
<br />
<div class="row" *ngIf="valError">
  <span *ngFor="let item of errors" style="margin-left:5px ;padding: 5px;" class="alert-danger">
    {{item}}
  </span>
</div>
<br />
<br />

<div class="row">
  <h4 class="col-md-4">Nominal</h4>
</div>

<div class="row">
  <div class="col-md-2">
    <size-input [(size)]="pricingObject.Nominal"></size-input>
  </div>
</div>

<br />

<div class="row">
  <h4 class="col-md-4">Quote Selection</h4>
  <div class="col-md-4">
    <div class="form-group">
      <label class="col-md-3">
        <input type="checkbox" [(ngModel)]="allQuotesSelected" (change)="allQuotesSelect($event)" />
        &nbsp; Select all Quotes
      </label>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label class="col-md-3">
        <input type="checkbox" [(ngModel)]="eraseQuoteValues" (change)="eraseQuoteValue($event)" />
        &nbsp; Erase Values
      </label>
    </div>
  </div>
</div>

<div class="bloc">
  <div class="extended-bloc">

    <div *ngFor="let quote of pricingQuotes; let i = index">
      <div class="row" *ngIf="i % 4 == 0">
        <div class="form-group form-group-sm" *ngIf="i + 0 < pricingQuotes.length">
          <label class="col-md-3" for="Active"><input type="checkbox"  [(ngModel)]="pricingQuotes[i].Checked" />&nbsp;{{pricingQuotes[i].Label}}</label>

        </div>

        <div class="form-group form-group-sm" *ngIf="i + 1 < pricingQuotes.length">
          <label class="col-md-3" for="Active"><input type="checkbox" [(ngModel)]="pricingQuotes[i+1].Checked" />&nbsp;{{pricingQuotes[i+1].Label}}</label>

        </div>

        <div class="form-group form-group-sm" *ngIf="i + 2 < pricingQuotes.length">

          <label class="col-md-3" for="Active"><input type="checkbox" [(ngModel)]="pricingQuotes[i+2].Checked" />&nbsp;{{pricingQuotes[i+2].Label}}</label>

        </div>


        <div class="form-group form-group-sm" *ngIf="i + 3 < pricingQuotes.length">
          <label class="col-md-3" for="Active"><input type="checkbox" [(ngModel)]="pricingQuotes[i+3].Checked" />&nbsp;{{pricingQuotes[i+3].Label}}</label>

        </div>


      </div>
    </div>
  </div>
</div>
<div class="row">
  <h4 class="col-md-4">Issuers</h4>
  <div class="col-md-4">
    <div class="form-group">
      <label class="col-md-3">
        <input type="checkbox" [(ngModel)]="allIssuersSelected" (change)="allIssuersSelect($event)" />
        &nbsp; Select all Issuers
      </label>
    </div>
  </div>
  <div class="col-md-4">
    <div class="form-group">
      <label class="col-md-3">
        <input type="checkbox" [(ngModel)]="eraseIssuerValues" (change)="eraseIssuerValue($event)" />
        &nbsp; Erase Values
      </label>
    </div>
  </div>
</div>
<div class="bloc">
  <div class="extended-bloc">
    <div *ngFor="let issuer of issuers; let i = index">
      <div class="row" *ngIf="i % 4 == 0">
        <div class="form-group form-group-sm" *ngIf="i + 0 < issuers.length">
          <label class="col-md-3" for="Active"><input type="checkbox"  [disabled]="issuers[i].Disabled" [(ngModel)]="issuers[i].Checked" />&nbsp;{{issuers[i].Label}}</label>

        </div>

        <div class="form-group form-group-sm" *ngIf="i + 1 < issuers.length">
          <label class="col-md-3" for="Active"><input type="checkbox"  [disabled]="issuers[i+1].Disabled" [(ngModel)]="issuers[i+1].Checked" />&nbsp;{{issuers[i+1].Label}}</label>

        </div>

        <div class="form-group form-group-sm" *ngIf="i + 2 < issuers.length">

          <label class="col-md-3" for="Active"><input type="checkbox"  [disabled]="issuers[i+2].Disabled" [(ngModel)]="issuers[i+2].Checked" />&nbsp;{{issuers[i+2].Label}}</label>

        </div>


        <div class="form-group form-group-sm" *ngIf="i + 3 < issuers.length">
          <label class="col-md-3" for="Active"><input type="checkbox"  [disabled]="issuers[i+3].Disabled" [(ngModel)]="issuers[i+3].Checked" />&nbsp;{{issuers[i+3].Label}}</label>

        </div>


      </div>
    </div>
  </div>
</div>

<h4>Optimizer</h4>
<div class="bloc">
  <div class="extended-bloc">

    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="from">From (%) :</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0" [(ngModel)]="pricingObject.From" style="width: 100%;" />

        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="to">To (%) :</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0" [(ngModel)]="pricingObject.To" style="width: 100%;" />

        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Step : </label>
        <div class="col-md-1">

        </div>
        <div class="col-md-1">
          <label class="col-md-1" for="Name">1</label>

        </div>
        <div class="col-md-1">
          <button type="button" [ngClass]="{'disabled': buttonClicked }" class="btn btn-primary" (click)="ExecuteOptimizedPricer()">Optimize</button>

        </div>

      </div>
    </div>

  </div>
</div>
<div class="footer">
  <div class="command">
    <div class="form-group col-md-12" style="text-align: right;">
      <button id="Rqst" type="button" [ngClass]="{'disabled': clicked }" (click)="ExecutePricing()" class="btn btn-primary">Send</button>
    </div>
  </div>
</div>
