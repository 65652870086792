<mat-form-field style="width: 100%;">
  <input matInput aria-label="ISIN" [matAutocomplete]="auto" [formControl]="searchProductCtrl" >
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option *ngFor="let prod of filteredProducts" [value]="prod.ISIN" (onSelectionChange)="setProduct(prod)">
        <span><b>{{prod.ISIN}}</b> ({{prod.Name}})</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
<!-- <div>
  <input id="typeahead-http" type="text" class="form-control" [(ngModel)]="isin" [ngbTypeahead]="search"
    [resultTemplate]="rt" />

  <ng-template #rt let-r="result" let-t="term">
    <b>{{r.ISIN}}</b> ({{r.Name}})
  </ng-template>
</div> -->
