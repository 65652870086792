import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DataService } from '../../../../services/data.service';
import { map, startWith } from 'rxjs/operators';
import { QuoteChildIssuerDto } from '../../../../models/QuoteChildIssuerDto';
import { CodeValueDto } from '../../../CodeValueDto.Model';


//export class Issuer {
//  constructor(public Code: string, public Description: string, public selected?: boolean) {
//    if (selected === undefined) selected = false;
//  }
//}

@Component({
  selector: 'app-add-batch-dialog',
  templateUrl: './add-batch-dialog.component.html',
  styleUrls: ['./add-batch-dialog.component.scss']
})
export class AddBatchDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AddBatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public dataService: DataService
  ) { }

  userControl = new FormControl();

  selectedIssuers: CodeValueDto[] = new Array<CodeValueDto>();

  filteredIssuers: Observable<CodeValueDto[]>;
  lastFilter: string = '';

  ngOnInit() {
    this.filteredIssuers = this.userControl.valueChanges.pipe(
      startWith<string | CodeValueDto[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filter(filter))
    );
    this.selectedIssuers = new Array<CodeValueDto>();

    //this.data.issuers.forEach(function (obj) {
    //  if (obj.Selected) this.selectedIssuers.push(obj);
    //});
    for (let i = 0; i < this.data.issuers.length; i++) {
      if (this.data.issuers[i].Selected) {
        this.selectedIssuers.push(this.data.issuers[i]);
        this.userControl.setValue(this.selectedIssuers);

        event.stopPropagation();

      }
    }

  }

  filter(filter: string): CodeValueDto[] {
    this.lastFilter = filter;
    if (filter) {
      return this.data.issuers.filter(option => {
        return option.Code.toLowerCase().indexOf(filter.toLowerCase()) >= 0
          || option.Description.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      })
    } else {
      return this.data.issuers.slice();
    }
  }
  trackByMethod(obj: any): any {
    return obj.Code;
  }

  displayFn(value: CodeValueDto[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {
      value.forEach((issuer, index) => {
        if (index === 0) {
          displayValue = issuer.Description;
        } else {
          displayValue += ', ' + issuer.Description;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  optionClicked(event: Event, issuer: CodeValueDto) {
    event.stopPropagation();
    this.toggleSelection(issuer, false, event);
  }


  toggleSelection(issuer: CodeValueDto, fromSpan: Boolean, event: Event) {
    if (fromSpan) {
      issuer.Selected = !issuer.Selected;
      event.stopPropagation();
    }
    if (issuer.Selected) {
      this.selectedIssuers.push(issuer);
    } else {
      const i = this.selectedIssuers.findIndex(value => value.Code === issuer.Code && value.Description === issuer.Description);
      if (i != -1) this.selectedIssuers.splice(i, 1);
    }

    this.userControl.setValue(this.selectedIssuers);
  }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
  }

  onNoClick(): void {
    this.data.issuers.forEach(function (obj) {
      obj.Selected = false;
    });
    this.dialogRef.close();
  }

  public confirmAdd(): void {

    if (this.selectedIssuers && this.selectedIssuers.length > 0) {
      var data = this.data;
      var entites = [];
      this.selectedIssuers.forEach(function (si) {
        var entity = new QuoteChildIssuerDto;
        entity.QuoteChildId = data.QuoteChildId,
          entity.IssuerCode = si.Code,
          entity.SolveFor = null,
          entity.Comment = null
        entites.push(entity);
      })
    }
    this.dataService.addBatchPrice(entites);

    this.data.issuers.forEach(function (obj) {
      obj.Selected = false;
    });

  }

}
