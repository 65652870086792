import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ClientService } from '../services/client.service';
import { Observable, merge } from 'rxjs';
import { startWith, map, debounceTime, tap, switchMap, finalize, distinctUntilChanged, catchError } from 'rxjs/operators';
import { ClientDto } from '../quoting/ClientDto.Model';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'client-autocomplete',
  templateUrl: './client-autocomplete.component.html',
  styleUrls: ['./client-autocomplete.component.scss']
})
export class ClientAutocompleteComponent implements OnInit {


  _clientId: string;
  _onBehalfSales: string;
  _isForTrading: boolean;

  searchClientCtrl = new FormControl();
  filteredClients: any;
  disabledControls: boolean;
  isLoading = false;
  searching = false;
  searchFailed = false;
  _client: ClientDto;

  @Input()
  disabled = false;

  @Input()
  isForTrading = false;

  @Input()
  onBehalfSales = '';

  @Input()
  set clientId(val: string) {
    this._clientId = val;

    if (val) {
      this.clientService.GetClientById(val).subscribe(c => {
        this.searchClientCtrl.setValue(c.ClientCode);
        this.setClient(c);
      });

    }
  }

  @Output()
  clientIdChange: EventEmitter<string> = new EventEmitter<string>();

  get client() {
    return this._client;
  }

  set client(val: ClientDto) {
    this._client = val;
  }



  @Output()
  clientChange: EventEmitter<ClientDto> = new EventEmitter<ClientDto>();

  @ViewChild('myInput')
  myInputVariable: ElementRef;

  constructor(private clientService: ClientService) { }

  ngOnInit(): void {
    if (this.onBehalfSales && this.onBehalfSales != '') {
      this.searchClientCtrl.valueChanges
        .pipe(
          debounceTime(500),
          tap(() => {
            this.filteredClients = [];
            this.isLoading = true;
          }),
          switchMap(value => this.clientService.GetOnBehalfClientsByCode(value, this.onBehalfSales)
            .pipe(
              finalize(() => {
                this.isLoading = false
              }),
            )
          )
        )
        .subscribe(data => {
          this.filteredClients = data;
          if (this.isForTrading == true) this.filteredClients = this.filteredClients.filter(item => item.HasAccessToTrading);

        });
    }
    else {
      this.searchClientCtrl.valueChanges
        .pipe(
          debounceTime(500),
          tap(() => {
            this.filteredClients = [];
            this.isLoading = true;
          }),
          switchMap(value => this.clientService.GetClientsByCode(value)
            .pipe(
              finalize(() => {
                this.isLoading = false
              }),
            )
          )
        )
        .subscribe(data => {
          this.filteredClients = data;
          if (this.isForTrading == true) this.filteredClients = this.filteredClients.filter(item => item.HasAccessToTrading);

        });
    }

  }
  onBlur() {
    if (this.filteredClients != null) {        
      let isValid = false;
      let listOfClients = [];
      listOfClients = this.filteredClients;
      listOfClients.forEach(c => {
        if (c.ClientCode == this.myInputVariable.nativeElement.value) {
          isValid = true;
        }
      });
      if (!isValid) {
        this.myInputVariable.nativeElement.value = "";
      }
    }
  
  }


  setClient(val: ClientDto) {
    this.client = val;
    this.clientChange.emit(val);
  }

}
