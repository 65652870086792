import { ProductDto } from '../quoting/ProductDto.Model';


export class MiniFutureDto {

  public Isin: string;
  public FirstDealNbr: string;
  public ProductId: string;
  public Currency: any;
  public ClientId: string;
  public ClientCode: string;
  public Side: string;
  public IssuerCode: string;
  public AssetClass: string;
  public Strike: number;
  public StopLoss: number;
  public LevierInitial: number;
  public UnderlyingPrice: number;
  public TotalSize: number;
  public Size: number;
  public Underlyings: string;
  public UnderlyingId: string;
  public Validity: string;
  public Type: string;
  public ChildQuoteNbr: string;
  public Id: string;
  public Sens: string;
  public Broker: string;
  public GTDDate: Date;
  public IsNew: boolean;



  
 


}
