<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<ol class="breadcrumb row">
  <li><a (click)="goToHome()">Home</a></li>
  <li><a (click)="goToProducts()">Products</a></li>
  <li>{{isin}} - {{productName}} </li>
</ol>

<!--Send Orders-->

<div *ngIf="!doneDeal && !doneDealMinifut">
  <div class="row" *ngIf="valError">
    <span *ngFor="let item of errors" style="margin-left:5px ;padding: 5px;" class="alert-danger">
      {{item}}
    </span>
  </div>
  <h4>Order allocation</h4>

  <div class="bloc">
    <div class="extended-bloc">


      <div class="row" *ngIf="!toEdit">
        <div class="form-group form-group-sm">
          <label class="col-md-1" for="Name">CLIENT</label>
          <div class="col-md-3">
            <client-autocomplete [clientId]="primaryQuote.firstDeal?.ClientId" (clientChange)="parseClient($event)" [onBehalfSales]="onBehalfSales" [isForTrading]="true" class="col-md-12">
            </client-autocomplete>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-md-1" for="Sides">Quantity</label>
          <div class="col-md-2">
            <size-input [(size)]="primaryQuote.firstDeal.Quantity"></size-input>
          </div>
        </div>
        <div class="form-group form-group-sm">
          <div class="col-md-1">
          </div>
        </div>
        <div class="form-group form-group-sm" *ngIf="!isMinif && !idParam">
          <label class="col-md-1" for="Sides">Settlement</label>
          <div class="col-md-2">
            <input type="number" class="form-control input-sm" [disabled]="isSecondaryQuote && !isMinif && !idParam && primaryQuote.SecondaryQuote.AtMarketBid.Value"
                   [(ngModel)]="primaryQuote.firstDeal.Settlement" step="0.0001" required
                   [placeholder]="isSecondaryQuote && !isMinif && !idParam && primaryQuote.SecondaryQuote.AtMarketBid.Value ? 'MKT' : ''" />
          </div>
          <div class="col-md-1">
            <button type="button" class="btn btn-secondary" (click)="copySettlements()">Apply to all</button>
          </div>
        </div>

      </div>
      <!-- Add new deal-->
      <div class="row" *ngFor="let d of primaryQuote.deals; let i = index">
        <div class="form-group form-group-sm">
          <label class="col-md-1">CLIENT</label>
          <div class="col-md-3" [id]="i">
            <client-autocomplete [clientId]="d.ClientId" [disabled]="toEdit && !isFirstOrder" (clientChange)="parseDealClient($event)" class="col-md-12">
            </client-autocomplete>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-md-1" for="Sides">Quantity</label>
          <div class="col-md-2">
            <size-input [disabled]="toEdit && !isFirstOrder" [(size)]="d.Quantity"></size-input>

          </div>

        </div>
        <div class="form-group form-group-sm">
          <div class="col-md-1">
          </div>

        </div>
        <div class="form-group form-group-sm">
          <label class="col-md-1" for="Sides" *ngIf="!isMinif && !idParam">Settlement</label>
          <div class="col-md-2" *ngIf="!isMinif && !idParam">
            <input type="number" class="form-control input-sm" [disabled]="(isSecondaryQuote && !isMinif && !idParam && primaryQuote.SecondaryQuote.AtMarketBid.Value) || (toEdit && !isFirstOrder)"
                   [(ngModel)]="d.Settlement" step="0.0001" required
                   [placeholder]="isSecondaryQuote && !isMinif && !idParam && primaryQuote.SecondaryQuote.AtMarketBid.Value ? 'MKT' : ''" />
          </div>
          <div class="" *ngIf="!toEdit">
            <div class="delete-link"><i class="glyphicon glyphicon-remove" (click)="removeDeal(i)"></i></div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!toEdit">
        <div class="col-md-3">
          
          
          <div class="dynamic-link" title="Add New Deal" (click)="addItem()">
            <i class="far fa-plus-circle"></i>
            <span class="dynamic-link-span">Add New Deal</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Second part-->
  <!--Minifiture-->
  <div *ngIf="isMinif">
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Side</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select class="form-control input-sm" [disabled]="toEdit && !isFirstOrder" [(ngModel)]="primaryQuote.MiniFuture.Side">
            <option value="Buy">Buy</option>
            <option value="Sell">Sell</option>

          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Isin</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <product-autocomplete [(productId)]="primaryQuote.MiniFuture.ProductId" [disabled]="toEdit && !isFirstOrder" [type]="'secondaryOrder'" required (productChange)="parseProd($event)">

          </product-autocomplete>

        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Sens</label>
        <div class="col-md-1">

        </div>
        <div class="col-md-1">
          <label for="Name">{{primaryQuote.MiniFuture.Sens}}</label>

        </div>
        <div class="col-md-1">
          <label for="Name">{{primaryQuote.MiniFuture.IssuerCode}}</label>

        </div>
        <div class="col-md-1">
          <label for="Name"><span style="margin-right: 53px;">Strike</span>  {{primaryQuote.MiniFuture.Strike}}</label>

        </div>

      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Underlyings</label>
        <div class="col-md-1">

        </div>
        <div class="col-md-1">
          <label for="Name">{{primaryQuote.MiniFuture.Underlyings}}</label>

        </div>
        <div class="col-md-1">
          <label for="Name">{{primaryQuote.MiniFuture.Currency}}</label>

        </div>
        <div class="col-md-1">
          <label for="Name"><span style="margin-right: 36px;">Stop loss</span>   {{primaryQuote.MiniFuture.StopLoss}}</label>

        </div>

      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Broker</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select class="form-control input-sm" [required]="isExternalSales" [(ngModel)]="primaryQuote.MiniFuture.Broker">
            <option value=""></option>
            <option *ngFor="let option of issuers" [value]="option.Code">
              {{option.Description}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Total Size</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <label for="Name">{{totalSize()}}</label>

        </div>


      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Type </label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <select name="DateEtendues" class="form-control input-sm" [disabled]="toEdit && !isFirstOrder" [(ngModel)]="primaryQuote.MiniFuture.Type">
            <option value="MKT">MKT</option>
            <option value="LMT">LMT</option>
            <option value="STOP">STOP</option>
          </select>

        </div>
      </div>

    </div>
    <div class="row" *ngIf="primaryQuote.MiniFuture.Type != 'MKT'">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Validity</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <select name="DateEtendues" class="form-control input-sm" [disabled]="toEdit && !isFirstOrder" [(ngModel)]="primaryQuote.MiniFuture.Validity">
            <option value="DAY">DAY</option>
            <option value="GTC">GTC</option>
            <option value="GTD">GTD</option>
          </select>

        </div>
        <div class="col-md-1" *ngIf="primaryQuote.MiniFuture.Validity == 'GTD'">
          <input style=" width: 120px;" type="date" [disabled]="toEdit && !isFirstOrder" [(ngModel)]="primaryQuote.MiniFuture.GTDDate" />
        </div>
      </div>

    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Underlying price</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <input style=" width: 120px;" type="number" step="0.0001" [(ngModel)]="primaryQuote.MiniFuture.UnderlyingPrice" [placeholder]="primaryQuote.MiniFuture.Type == 'MKT' ? 'MKT' : ''" [disabled]="(primaryQuote.MiniFuture.Type == 'MKT' ? true : false) ||( toEdit && !isFirstOrder)" />
        </div>
      </div>

    </div>

  </div>

  <!--Secondery quote-->
  <div class="row" *ngIf="isSecondaryQuote && !isMinif && !idParam">
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Issuer</label>
        <div class="col-md-1">
        </div>

        <div class="col-md-2">
          <code-value [(code)]="primaryQuote.SecondaryQuote.IssuerCode" (sendSQIssuerValue)="SQIssuerValue($event)" [quoteNbr]="primaryQuote.QuoteNbr" (changeIssuer)="issuerChange($event)" [filter]="true" [disabled]="!isFirstOrder"  [type]="'Issuer'">
          </code-value>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">{{primaryQuote.SecondaryQuote.Side}}</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <label *ngIf="!primaryQuote.SecondaryQuote.AtMarketBid.Value" for="Name">{{primaryQuote.SolveForValue}}</label>
          <label *ngIf="primaryQuote.SecondaryQuote.AtMarketBid.Value" for="Name">MKT</label>

        </div>
        <label class="col-md-1" for="Active"><input type="checkbox" [disabled]="toEdit && !isFirstOrder" (change)="onChangeBid(true)" [(ngModel)]="primaryQuote.SecondaryQuote.OrBetterBid.Value" />&nbsp;Or Better</label>
        <label class="col-md-1" for="Active"><input type="checkbox" [disabled]="toEdit && !isFirstOrder" (change)="onChangeBid(false)" [(ngModel)]="primaryQuote.SecondaryQuote.AtMarketBid.Value" />&nbsp;At Market</label>


      </div>
    </div>
  </div>
  <!--Primary quote-->
  <div *ngIf="!isSecondaryQuote && !isMinif && !idParam">
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Issuer</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [(code)]="primaryQuote.IssuerCode" [isPQ]="true" [quoteNbr]="primaryQuote.QuoteNbr" [filter]="true" [disabled]="!isFirstOrder && primaryQuote.SolveForValue != 0"  [type]="'Issuer'"  (changeIssuer)="issuerChange($event)">
          </code-value>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">{{primaryQuote.SolveForCode}}</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <label *ngIf=" !primaryQuote.AtMarketReoffer.Value && !primaryQuote.AtMarketSolve.Value && !IsBestIssuer" for="Name">{{primaryQuote.SolveForValue}}</label>
          <label *ngIf=" !primaryQuote.AtMarketReoffer.Value && !primaryQuote.AtMarketSolve.Value && IsBestIssuer" for="Name">at best</label>
          <label *ngIf="primaryQuote.AtMarketSolve.Value" for="Name">MKT</label>
          <input style="width: 80px;margin-top: 10px;" [(ngModel)]="primaryQuote.SolveForValue" [disabled]="!isFirstOrder" *ngIf=" primaryQuote.AtMarketReoffer.Value" />


        </div>
        <label class="col-md-1" for="Active"><input [disabled]="IsBestIssuer || !isFirstOrder" type="checkbox" (change)="onChange('OrBetterSolve')"  [(ngModel)]="primaryQuote.OrBetterSolve.Value"/>&nbsp;Or Better</label>
        <label class="col-md-1" for="Active"><input [disabled]="IsBestIssuer || !isFirstOrder" type="checkbox" (change)="onChange('AtMarketSolve')" [(ngModel)]="primaryQuote.AtMarketSolve.Value" />&nbsp;At Market</label>



      </div>


    </div>
    <div class="row" *ngIf="primaryQuote.SolveForCode != 'Reoffer'">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Reoffer (%)</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <label *ngIf=" !primaryQuote.AtMarketSolve.Value && !primaryQuote.AtMarketReoffer.Value" for="Name">{{primaryQuote.Reoffer | number }}</label>
          <label *ngIf=" primaryQuote.AtMarketReoffer.Value" for="Name">MKT</label>
          <input style="width: 80px;margin-top: 10px;" *ngIf=" primaryQuote.AtMarketSolve.Value" [(ngModel)]="primaryQuote.Reoffer" />


        </div>
        <label class="col-md-1" for="Active"><input [disabled]="IsBestIssuer || !isFirstOrder" type="checkbox" (change)="onChange('OrBetterReoffer')" [(ngModel)]="primaryQuote.OrBetterReoffer.Value" />&nbsp;Or Better</label>
        <label class="col-md-1" for="Active"><input [disabled]="IsBestIssuer || !isFirstOrder" type="checkbox" (change)="onChange('AtMarketReoffer')" [(ngModel)]="primaryQuote.AtMarketReoffer.Value" />&nbsp;At Market</label>


      </div>
    </div>

    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Strike Type</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'StrikeType'" [disabled]="!isFirstOrder" [(code)]="primaryQuote.StrikeType">
          </code-value>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Delivery Type</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'DeliveryType'" [disabled]="!isFirstOrder" [(code)]="primaryQuote.DeliveryType">
          </code-value>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Amortissement</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select class="form-control input-sm" [disabled]="!isFirstOrder" required [(ngModel)]="primaryQuote.Amortissement">
            <option value="1">1 month</option>
            <option value="2">2 months</option>
            <option value="3">3 months</option>
            <option value="4">4 months</option>
            <option value="5">5 months</option>
            <option value="6">6 months</option>
            <option value="7">7 months</option>
            <option value="8">8 months</option>
            <option value="9">9 months</option>
            <option value="10">10 months</option>
            <option value="11">11 months</option>
            <option value="12">12 months</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Issue Date <span class="small-text">(Business Days)</span>  </label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select name="DateEtendues" class="form-control input-sm" [disabled]="!isFirstOrder" required [(ngModel)]="primaryQuote.IssueDates">
            <option value="">....</option>
            <option value="5">T+5</option>
            <option value="10">T+10</option>
            <option value="15">T+15</option>
          </select>

        </div>


      </div>


    </div>
  </div>
  <div class="row" *ngIf="!isMinif && !idParam">
    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Name">Total Size</label>
      <div class="col-md-1">
      </div>
      <div class="col-md-1">
        <label for="Name">{{totalSize()}}</label>

      </div>
      <label *ngIf="!isSecondaryQuote" class="col-md-1" for="Active">{{primaryQuote.Currency}}</label>
      <label *ngIf="isSecondaryQuote" class="col-md-1" for="Active">{{primaryQuote.SecondaryQuote.CurrencyCode}}</label>


    </div>
  </div>
  <br />
  <br />
  <div *ngIf="!toEdit">
    <h4>{{primaryQuote.firstDeal.ClientCode}}</h4>

    <div class="bloc">
      <div class="extended-bloc">

        <div class="row">
          <div class="form-group form-group-sm">
            <label class="col-md-1" for="Name">Custo</label>
            <!--<div class="col-md-1">
          </div>-->
            <label class="col-md-1" for="Active"><input type="checkbox" (change)="onChangeTbd(true,primaryQuote.firstDeal)" [(ngModel)]="primaryQuote.firstDeal.TBD" />&nbsp;TBD</label>

            <div class="col-md-1">
              <label for="Name">Size</label>

            </div>
            <label class="col-md-1" for="Active"><input type="checkbox" [(ngModel)]="primaryQuote.firstDeal.IsTBD" />&nbsp;TBD</label>


          </div>
          <div class="form-group form-group-sm">

            <label class="col-md-1" for="Active"></label>

            <div class="col-md-2">
              <label *ngIf=" !primaryQuote.firstDeal.IsEqualSizes && !primaryQuote.firstDeal.IsTBD " style="color:red" for="Name" class="all-size">{{allSizeFirstCusto()}}</label>
              <label *ngIf=" primaryQuote.firstDeal.IsEqualSizes && !primaryQuote.firstDeal.IsTBD" style="color:green" for="Name" class="all-size">{{allSizeFirstCusto()}}</label>
              <label *ngIf=" primaryQuote.firstDeal.IsTBD" style="color:green" for="Name" class="all-size">TBD</label>

            </div>





          </div>
        </div>
        <div class="row">
          <div class="form-group form-group-sm">

            <div class="col-md-2">
              <code-value [(code)]="primaryQuote.CustodianCode" [type]="'Custodian'" (changeCusto)="custoSelect($event)">
              </code-value>
            </div>
            <!--<label class="col-md-1"></label>-->

            <div class="col-md-1">
              <label *ngIf="!primaryQuote.firstDeal.Custodians?.length" class="" for="Active"><input type="checkbox" (change)="onChangeSize(true,primaryQuote.firstDeal)" [(ngModel)]="primaryQuote.firstDeal.ShowAllSize" />&nbsp;All Size</label>

            </div>
            <div class="col-md-1">
              <size-input [(size)]="primaryQuote.CustodianSize" [label]="primaryQuote.firstDeal.IsTBD ? 'TBD' : ''"></size-input>


            </div>


          </div>

        </div>
        <div class="row" *ngFor="let custo of primaryQuote.firstDeal.Custodians; let i = index ">
          <div class="form-group form-group-sm" *ngIf="!custo.IsDeleted">

            <div class="col-md-2">
              <code-value [(code)]="custo.CustodianCode" [type]="'Custodian'" (changeCusto)="custoSelect($event, custo)">
              </code-value>
            </div>
            <!--<label class="col-md-1"></label>-->

            <div class="col-md-1">
              <div class="">
                <label *ngIf="primaryQuote.firstDeal.Custodians?.length == (i + 1) " for="Active"><input type="checkbox" (change)="onChangeSize(true,primaryQuote.firstDeal)" [(ngModel)]="primaryQuote.firstDeal.ShowAllSize" />&nbsp;All Size</label>
              </div>

            </div>
            <div class="col-md-1">
              <size-input [(size)]="custo.CustodianSize" [label]="primaryQuote.firstDeal.IsTBD ? 'TBD' : ''"></size-input>


            </div>
            <div>
              <div class="delete-link" style="margin-right: 890px !important;"><i class="glyphicon glyphicon-remove" (click)="removeCustodian(primaryQuote.firstDeal,i,true)"></i></div>
            </div>

          </div>
        </div>
        <div class="row" *ngIf="!toEdit">
          <div class="col-md-3">
            <div class="dynamic-link" [class.disabled]="primaryQuote.firstDeal.IsEqualSizes" title="Add New Custodian" (click)="addCusto()">
              <i class="far fa-plus-circle"></i>
              <span class="dynamic-link-span">Add New Custodian</span>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div *ngFor="let deal of primaryQuote.deals; let i = index">
    <h4>{{deal.ClientCode}}</h4>

    <div class="bloc">
      <div class="extended-bloc">

        <div class="row">
          <div class="form-group form-group-sm">
            <label class="col-md-1" for="Name">Custo</label>
            <!--<div class="col-md-1">
            </div>-->
            <label class="col-md-1" for="Active"><input [disabled]="toEdit && !isFirstOrder" type="checkbox" (change)="onChangeTbd(false,deal)" [(ngModel)]="deal.TBD" />&nbsp;TBD</label>

            <div class="col-md-1">
              <label for="Name">Size</label>

            </div>
            <label class="col-md-1" for="Active"><input [disabled]="toEdit && !isFirstOrder" type="checkbox" [(ngModel)]="deal.IsTBD" />&nbsp;TBD</label>


          </div>
          <div class="form-group form-group-sm">

            <label class="col-md-1" for="Active">
            </label>

            <div class="col-md-2">
              <label for="Name" class="all-size" style="color:red" *ngIf="!deal.IsEqualSizes && !deal.IsTBD">{{allSizeCusto(deal)}}</label>
              <label *ngIf="deal.IsEqualSizes && !deal.IsTBD" style="color:green" for="Name" class="all-size">{{allSizeCusto(deal)}}</label>
              <label *ngIf="deal.IsTBD" style="color:green" for="Name" class="all-size">TBD</label>

            </div>


          </div>
        </div>
        <div class="row" *ngFor="let c of deal.Custodians; let i = index">
          <div class="form-group form-group-sm">

            <div class="col-md-2">
              <code-value [(code)]="c.CustodianCode" [disabled]="toEdit && !isFirstOrder" [type]="'Custodian'" (changeCusto)="custoSelect($event, c)">
              </code-value>
            </div>
            <!--<label class="col-md-1"></label>-->

            <div class="col-md-1">
              <label *ngIf="deal.Custodians?.length == i + 1" class="" for="Active"><input [disabled]="toEdit && !isFirstOrder" type="checkbox" (change)="onChangeSize(false,deal)" [(ngModel)]="deal.ShowAllSize" />&nbsp;All Size</label>


            </div>
            <div class="col-md-1">
              <size-input [(size)]="c.CustodianSize" [disabled]="toEdit && !isFirstOrder" [label]="deal.IsTBD ? 'TBD' : ''"></size-input>


            </div>
            <div>
              <div *ngIf="i > 0" class="delete-link" style="margin-right: 890px !important;"><i class="glyphicon glyphicon-remove" (click)="removeCustodian(deal,i,true)"></i></div>
            </div>


          </div>
        </div>
        <div class="row" *ngIf="!toEdit">
          <div class="col-md-3">
            <div class="dynamic-link" [class.disabled]="deal.IsEqualSizes" title="Add New Custodian" (click)="addCusto(deal)">
              <i class="far fa-plus-circle"></i>
              <span class="dynamic-link-span">Add New Custodian</span>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
  <div class="row ">
    <label class="col-md-1">Comment:</label>
    <div class="col-md-7">
      <textarea class="form-control" id="comment" rows="3" [disabled]="(isMinif && !isFirstOrder) || !isFirstOrder" [(ngModel)]="primaryQuote.Comment"></textarea>
    </div>
  </div>

  <div class="footer">
    <div class="command">
        <div class="form-group col-md-12" style="text-align: right;">
          <button id="Rqst" [ngClass]="{'disabled': clicked }" type="button" class="btn btn-primary" (click)="sendOrder()"><span *ngIf="!toEdit">Send</span><span *ngIf="toEdit">Edit</span> Order</button>
        </div>
    </div>
  </div>

</div>

<!--Done Orders-->
<div *ngIf="doneDeal || doneDealMinifut">

  <div class="row" *ngIf="valError">
    <span *ngFor="let item of errors" style="margin-left:5px ;padding: 5px;" class="alert-danger">
      {{item}}
    </span>
  </div>
  <h4>Order allocation</h4>

  <div class="bloc">
    <div class="extended-bloc">

      <!--Deals list-->
      <div class="row" *ngFor="let d of primaryQuote.deals; let i = index">
        <div class="form-group form-group-sm">
          <label class="col-md-1">CLIENT</label>
          <div class="col-md-1" [id]="i">
            <client-autocomplete [disabled]="true" [clientId]="d.ClientId" (clientChange)="parseDealClient($event)" class="col-md-12">
            </client-autocomplete>
          </div>
        </div>

        <div class="form-group form-group-sm">
          <label class="col-md-1" for="Sides">Quantity</label>
          <div class="col-md-2">
            <size-input [disabled]="(group=='trader'|| group=='super-user') ? false : true" [(size)]="d.Quantity"></size-input>

          </div>

        </div>
        <div class="form-group form-group-sm">
          <div class="col-md-1">
          </div>

        </div>
        <div class="form-group form-group-sm">
          <label class="col-md-1" for="Sides" *ngIf="!isMinif && !idParam">Settlement</label>
          <div class="col-md-2" *ngIf="!isMinif && !idParam">
            <input class="form-control input-sm" type="number" step="0.0001"
                   [(ngModel)]="d.Settlement" required />
          </div>

        </div>
        <div class="form-group form-group-sm" *ngIf="isBond">
          <label class="col-md-1" for="Sides">Accrued</label>
          <div class="col-md-1" >
            <input class="form-control input-sm" type="number" step="0.0001"
                   [(ngModel)]="d.Accrued" required />
          </div>

        </div>
        <div *ngIf="i==0" class="col-md-1">
          <button type="button" class="btn btn-secondary" (click)="copySettlements()">Apply to all</button>
        </div>
      </div>



    </div>
  </div>

  <!--Second Part-->
  <!--Primary Quote--> <!--secondary quote-->
  <div *ngIf="!isSecondaryQuote && !isMinif && !doneDealMinifut">
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Issuer</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [(code)]="primaryQuote.IssuerCode" [quoteNbr]="primaryQuote.QuoteNbr" [filter]="true" [disabled]="!isFirstOrder" [type]="'Issuer'" (changeIssuer)="issuerChange($event)">
          </code-value>
        </div>
      </div>

      <div class="form-group form-group-sm" *ngIf="primaryQuote.IssuerCode =='issuer.hsbc'">
        <div class="col-md-1">
        </div>
        <label class="col-md-1" for="Name">Team</label>

        <div class="col-md-2">
          <select class="form-control input-sm" required [(ngModel)]="primaryQuote.Team">
            <option value="Flux">Flux</option>
            <option value="FX">FX</option>

          </select>
        </div>
      </div>
    </div>
    <!--<div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Broker2</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select class="form-control input-sm" [required]="isExternalSales" [(ngModel)]="primaryQuote.Broker">
            <option value=""></option>
            <option *ngFor="let option of issuers" [value]="option.Code">
              {{option.Description}}
            </option>
          </select>
        </div>
      </div>
    </div>-->
    <div class="row" *ngIf="isBond">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Gross Price (%)</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0.0001" class="form-control input-sm" style="width: 100%;" [(ngModel)]="primaryQuote.GrossPrice" />

        </div>
      </div>
    </div>

    <div class="row" *ngIf="primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Strike Type</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'StrikeType'" [disabled]="!isFirstOrder" [(code)]="primaryQuote.StrikeType">
          </code-value>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Delivery Type</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'DeliveryType'" [disabled]="!isFirstOrder" [(code)]="primaryQuote.DeliveryType">
          </code-value>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">{{primaryQuote.SolveForCode}}</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input [disabled]="!isFirstOrder" type="number" step="0.0001" class="form-control input-sm" style="width: 100%;" [(ngModel)]="primaryQuote.SolveForValue" />

        </div>
      </div>
    </div>
    <div class="row" *ngIf="primaryQuote.SolveForCode != 'Reoffer' && primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Reoffer (%)</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0.0001" class="form-control input-sm" style="width: 100%;" [(ngModel)]="primaryQuote.Reoffer" />

        </div>
      </div>
    </div>
    <div class="row" *ngIf="!primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Reoffer (%)</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0.0001" class="form-control input-sm" style="width: 100%;" [(ngModel)]="primaryQuote.Reoffer" />

        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Percent / Unit</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'QuotationType'" [disabled]="!isFirstOrder && primaryQuote.QuotationTypeCode " [(code)]="primaryQuote.QuotationTypeCode">
          </code-value>

        </div>
      </div>
    </div>
    <div class="row" *ngIf="primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Amortissement</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select class="form-control input-sm" [disabled]="!isFirstOrder" [(ngModel)]="primaryQuote.Amortissement">
            <option value="1">1 month</option>
            <option value="2">2 months</option>
            <option value="3">3 months</option>
            <option value="4">4 months</option>
            <option value="5">5 months</option>
            <option value="6">6 months</option>
            <option value="7">7 months</option>
            <option value="8">8 months</option>
            <option value="9">9 months</option>
            <option value="10">10 months</option>
            <option value="11">11 months</option>
            <option value="12">12 months</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="primaryQuote.IsPrimaryQuote">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Issue Date <span class="small-text">(Business Days)</span>  </label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select name="DateEtendues" class="form-control input-sm" [disabled]="!isFirstOrder" [(ngModel)]="primaryQuote.IssueDates">
            <option value="">....</option>
            <option value="5">T+5</option>
            <option value="10">T+10</option>
            <option value="15">T+15</option>
          </select>

        </div>

      </div>


    </div>

    <div class="row" *ngIf="!isMinif">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Total Size</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-1">
          <label for="Name">{{totalSize()}}</label>

        </div>
        <label *ngIf="!isSecondaryQuote" class="col-md-1" for="Active">{{primaryQuote.Currency}}</label>
        <label *ngIf="isSecondaryQuote" class="col-md-1" for="Active">{{primaryQuote.SecondaryQuote.CurrencyCode}}</label>


      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Trade Date</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input style="width: 100%;" type="date" class="form-control input-sm" [(ngModel)]="primaryQuote.TradeDate" />


        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Trade time</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="time" class="form-control input-sm" style="width: 100%;" [(ngModel)]="primaryQuote.TradeTime" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Support</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'Channel'" [(code)]="primaryQuote.Support">
          </code-value>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="row ">
      <label class="col-md-1">Comment:</label>
      <div class="col-md-7">
        <textarea class="form-control" id="comment" rows="3" [disabled]="!isFirstOrder" [(ngModel)]="primaryQuote.Comment"></textarea>
      </div>
    </div>

  </div>

  <!--Minifuture-->
  <div *ngIf="doneDealMinifut">
    <h4>Execution Details</h4>

    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Issuer</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [(code)]="primaryQuote.IssuerCode" [disabled]="" [type]="'Issuer'" (changeIssuer)="issuerChange($event)">
          </code-value>
        </div>
      </div>

      <div class="form-group form-group-sm" *ngIf="primaryQuote.IssuerCode =='issuer.hsbc'">
        <div class="col-md-1">
        </div>
        <label class="col-md-1" for="Name">Team</label>

        <div class="col-md-2">
          <select class="form-control input-sm" required [(ngModel)]="primaryQuote.Team">
            <option value="Flux">Flux</option>
            <option value="FX">FX</option>

          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Broker</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <select class="form-control input-sm" [required]="isExternalSales" [(ngModel)]="primaryQuote.Broker">
            <option value=""></option>
            <option *ngFor="let option of issuers" [value]="option.Code">
              {{option.Description}}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">USS Price</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0.0001" style="width: 100%;" [(ngModel)]="primaryQuote.Reoffer" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Nb Certificates</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="number" step="0.0001" style="width: 100%;" [(ngModel)]="primaryQuote.NbUnit" />

        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Trade Date</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input style="width: 100%;" type="date" [(ngModel)]="primaryQuote.TradeDate" />


        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Trade time</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <input type="time" style="width: 100%;" [(ngModel)]="primaryQuote.TradeTime" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1" for="Name">Support</label>
        <div class="col-md-1">
        </div>
        <div class="col-md-2">
          <code-value [type]="'Channel'" [(code)]="primaryQuote.Support">
          </code-value>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="row ">
      <label class="col-md-1">Comment:</label>
      <div class="col-md-7">
        <textarea class="form-control" id="comment" rows="3" [disabled]="!isFirstOrder" [(ngModel)]="primaryQuote.Comment"></textarea>
      </div>
    </div>
    <div class="footer">
      <div class="command">
        <div class="form-group col-md-12" style="text-align: right;">
          <button [ngClass]="{'disabled': clicked }" id="Rqst" type="button" (click)="SendMinifutDoneDeal()" class="btn btn-primary">Done Deal</button>
        </div>
      </div>
    </div>


  </div>
  <div class="footer" *ngIf="!doneDealMinifut">
    <div class="command">
      <div class="form-group col-md-12" style="text-align: right;">
        <button [ngClass]="{'disabled': clicked }" id="Rqst" type="button" (click)="sendDoneDeal()" class="btn btn-primary">Done Deal</button>
      </div>
    </div>
  </div>


</div>
