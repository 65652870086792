import { Injectable } from '@angular/core';
import { NotifResponseDto } from '../models/NotifResponseDto';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  notifData: NotifResponseDto
  constructor() { }

  setNotifications(data) {
    this.notifData = data;
  }

  getNotifications() {
    return this.notifData;
  }
}
