<div class="row">
    <h4>Deal Catcher Sync. Service</h4>
</div>
<div class="row">
    <button type="button" class="btn btn-primary" (click)="ping()">Ping</button>
    &nbsp;
    <!-- <button type="button" class="btn btn-primary" (click)="sync('Issuers')">Sync. Issuer</button>     -->
    &nbsp;    
    <button type="button" class="btn btn-primary" (click)="sync('Instruments')">Sync. Instrument</button>    
    &nbsp;
    <button type="button" class="btn btn-primary" (click)="sync('Quotes')">Sync. Quotes</button>
</div>
<div class="row">
    &nbsp;
</div>
<div class="row">
    <textarea class="form-control" id="comment" rows="3" [(ngModel)]="message"></textarea>
</div>