import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PrimaryQuoteDto } from './PrimaryQuoteDto.Model';
import { QuoteService } from '../services/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ClientDto } from '../quoting/ClientDto.Model';
import { DealDto } from './DealDto.Model';
import { SecondaryQuoteDto } from '../quoting/SecondaryQuoteDto.Model';
import { SolveForDto } from './SolveForDto.Model';
import { ProductDto } from '../quoting/ProductDto.Model';
import { MiniFutureDto } from './MiniFutureDto.Model';
import { SettlementDto } from './SettlementDto.Model';
import { SalesDto } from './SalesDto.Model';
import { DataService } from '../services/data.service';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';



@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  primaryQuote: PrimaryQuoteDto = new PrimaryQuoteDto();
  solveForDto: SolveForDto = new SolveForDto();
  issuers: CodeValueDto[];
  isSecondaryQuote = false;
  public errors = [];
  valError = false;
  isMinif;
  isBond;
  idParam;
  minifitUnder;
  IsBestIssuer;
  doneDeal = false;
  doneDealMinifut = false;
  isFirstOrder = true;
  isFirstDoneDeal = true;
  dealsList: DealDto[] = [];
  isin = "";
  productName = "";
  nbr = "";
  onBehalfSales = "";
  clicked = false;
  toEdit = false;
  issuer = "";
  prevIssuerCode: string = '';
  groupEntity: SalesDto;
  group: string = "";
  scopeTradeValue: string = 'today';
  deals: Array<DealDto> = [];
  public isExternalSales: boolean = false;


  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute, private notifierService: NotifierService, private router: Router, private _chRef: ChangeDetectorRef, private dataService: DataService) {
    this.activatedRoute.url.subscribe(queryParams => {
      if (queryParams[0].path == "order") {
        if (queryParams[1] != null && queryParams[1].path == "edit") {
          this.toEdit = true;
          this.activatedRoute.params.subscribe(params => {

            this.nbr = params['nbr'] ? params['nbr'].toString() : '';
            this.onBehalfSales = params['sales'] ? params['sales'].toString() : '';

            if (this.nbr) {
              this.primaryQuote = new PrimaryQuoteDto();
              this.quoteService.GetOrder(this.nbr).subscribe(r => {
                if (r.IsPrimaryQuote && !r.IsMinifut) {
                  this.primaryQuote = r;
                  this.isin = this.primaryQuote.ISIN;
                  this.productName = this.primaryQuote.ProductName;
                  this.quoteService.GetSolveForValue(this.primaryQuote.QuoteNbr, this.primaryQuote.IssuerCode).subscribe(v => {
                    this.primaryQuote.SolveForValue = Boolean(v) ? parseFloat(v) : 0
                  });
                } else if (!r.IsPrimaryQuote && !r.IsMinifut) {
                  this.primaryQuote = r;
                  this.isin = this.primaryQuote.SecondaryQuote.ISIN;
                  this.productName = this.primaryQuote.SecondaryQuote.ProductName;
                  this.isSecondaryQuote = true;
                  this.quoteService.GetSolveForValue(this.primaryQuote.QuoteNbr, this.primaryQuote.SecondaryQuote.IssuerCode).subscribe(v => {
                    this.primaryQuote.SolveForValue = Boolean(v) ? parseFloat(v) : 0
                  });
                  if (this.isSecondaryQuote && !this.isMinif && !this.idParam && !this.toEdit) {
                    this.primaryQuote.SecondaryQuote.OrBetterBid.Value = true;
                    this.primaryQuote.SecondaryQuote.AtMarketBid.Value = false;
                  }


                }
                else if (r.IsMinifut) {
                  this.isMinif = true;
                  this.primaryQuote.MiniFuture = new MiniFutureDto();
                  this.primaryQuote = r;
                  this.minifitUnder = this.primaryQuote.MiniFuture.Underlyings;
                  this.issuer = this.primaryQuote.MiniFuture.IssuerCode;
                  this.dataService.GetAlternativeBrokerIssuers().subscribe(c => {
                    this.issuers = c;
                  });
                }
                if (!this.primaryQuote.IsFirstOrder) {
                  this.isFirstOrder = false;

                }

                this.primaryQuote.SolveForValue = Boolean(this.primaryQuote.SolveForValue) ? this.primaryQuote.SolveForValue : 0

              });

            } else {

              this.primaryQuote = new PrimaryQuoteDto();

            }
          });

        }
        else {
          this.isMinif = true;
          this.dataService.GetAlternativeBrokerIssuers().subscribe(c => {
            this.issuers = c;
          });
          this.activatedRoute.params.subscribe(params => {

            this.nbr = params['nbr'] ? params['nbr'].toString() : '';

            if (this.nbr) {
              this.primaryQuote = new PrimaryQuoteDto();
              this.primaryQuote.MiniFuture = new MiniFutureDto();
              this.primaryQuote.firstDeal = new DealDto();
              this.primaryQuote.MiniFuture.IsNew = false;
              this.primaryQuote.MiniFuture.ChildQuoteNbr = this.nbr;
              this.quoteService.GetDealMinifut(this.nbr).subscribe(r => {
                this.primaryQuote = r;
                this.minifitUnder = this.primaryQuote.MiniFuture.Underlyings;
                this.issuer = this.primaryQuote.MiniFuture.IssuerCode;
                //this.primaryQuote.MiniFuture.FirstDealNbr = nbr;
                if (!this.primaryQuote.IsFirstOrder) {
                  this.isFirstOrder = false;

                }

              });

            } else {

              this.primaryQuote = new PrimaryQuoteDto();
              this.primaryQuote.MiniFuture = new MiniFutureDto();
              this.primaryQuote.MiniFuture.IsNew = true;
              this.primaryQuote.MiniFuture.ChildQuoteNbr = this.nbr;


            }
          });
        }


        //DoneDeals
      }
      else if (queryParams[0].path == "done" && queryParams[2].path != "minifut") {
        this.doneDeal = true;
        this.primaryQuote = new PrimaryQuoteDto();
        //GetPQDoneDeal
        //this.dataService.GetAlternativeBrokerIssuers().subscribe(c => {
        //  this.issuers = c;
        //});
        this.activatedRoute.params.subscribe(params => {

          this.nbr = params['nbr'] ? params['nbr'].toString() : '';

          if (this.nbr) {
            let date = new Date();
            let nowTime = this.pad(date.getHours(), 2) + ":" + this.pad(date.getMinutes(), 2);

            this.primaryQuote.QuoteNbr = this.nbr;
            this.quoteService.GetDoneDeal(this.nbr).subscribe(r => {
              this.primaryQuote = r;
              //this.primaryQuote.QuotationTypeCode = (this.primaryQuote.ProductType == 'producttype.otc-warrant') ? 'quotationtype.unit' : this.primaryQuote.QuotationTypeCode;
              if (this.primaryQuote.ProductType == 'producttype.bond') this.isBond = true;
              else this.isBond = false;
              this.isin = this.primaryQuote.ISIN;
              this.productName = this.primaryQuote.ProductName;
              if (this.primaryQuote.TradeDate != "0001-01-01 00:00") {
                this.isFirstDoneDeal = false;
                this.primaryQuote.TradeTime = this.primaryQuote.TradeDate.split(' ')[1];
                this.primaryQuote.TradeDate = this.primaryQuote.TradeDate.split(' ')[0].replace("/", "-").replace("/", "-");

              } else {
                this.primaryQuote.Support = "channel.bloomberg";
                this.primaryQuote.TradeDate = this.formatDate();
                this.primaryQuote.TradeTime = nowTime;
              }

              if (this.primaryQuote.IsFirstOrder) {
                //this.quoteService.GetPricingIssuers(this.primaryQuote.QuoteNbr).subscribe(v => {
                //  this.issuers = v;
                //});
              }
              else {
                this.isFirstOrder = false;

              }

              this.quoteService.GetSolveForValue(this.primaryQuote.QuoteNbr, this.primaryQuote.IssuerCode).subscribe(v => {
                this.primaryQuote.SolveForValue = Boolean(v) ? parseFloat(v) : 0
              });
              this.primaryQuote.SolveForValue = Boolean(this.primaryQuote.SolveForValue) ? this.primaryQuote.SolveForValue : 0

            });

          } else {
            this.primaryQuote = new PrimaryQuoteDto();
            this.primaryQuote.SendAuto = true;

          }
        });

        //doneDeal Minifut
      }
      else if (queryParams[0].path == "done" && queryParams[2] != null && queryParams[2].path == "minifut") {
        this.doneDealMinifut = true;
        this.dataService.GetAlternativeBrokerIssuers().subscribe(c => {
          this.issuers = c;
        });
        this.activatedRoute.params.subscribe(params => {

          this.nbr = params['nbr'] ? params['nbr'].toString() : '';

          if (this.nbr) {
            this.primaryQuote = new PrimaryQuoteDto();
            this.quoteService.GetMinifutDoneDeal(this.nbr).subscribe(r => {
              this.primaryQuote = r;
              this.isin = this.primaryQuote.ISIN;
              this.productName = this.primaryQuote.ProductName;
              this.primaryQuote.TradeTime = this.primaryQuote.TradeDate.split(' ')[1];
              this.primaryQuote.TradeDate = this.primaryQuote.TradeDate.split(' ')[0].replace("/", "-").replace("/", "-");


            });

          } else {

            this.primaryQuote = new PrimaryQuoteDto();
            this.primaryQuote.MiniFuture = new MiniFutureDto();
            this.primaryQuote.MiniFuture.IsNew = true;
            this.primaryQuote.MiniFuture.ChildQuoteNbr = this.nbr;

          }
        });

      }
      else if (queryParams[0].path == "deal" && queryParams[1] != null && queryParams[1].path == "new" && queryParams[2].path != "addSize") {
        this.activatedRoute.params.subscribe(params => {

          this.nbr = params['nbr'] ? params['nbr'].toString() : '';
          this.onBehalfSales = params['sales'] ? params['sales'].toString() : '';

        });
      }
    });


    if (!this.isMinif && !this.doneDeal && !this.doneDealMinifut && !this.toEdit) {
      this.activatedRoute.url.subscribe(queryParams => {

          this.activatedRoute.params.subscribe(params => {

            this.nbr = params['nbr'] ? params['nbr'].toString() : '';
            let isPrimary;


            if (this.nbr) {
              this.quoteService.IsPrimaryQuote(this.nbr).subscribe(v => {
                isPrimary = v;
                if (isPrimary) {
                  this.primaryQuote.QuoteNbr = this.nbr;
                  this.quoteService.GetPQByChildNbr(this.nbr).subscribe(r => {
                    this.primaryQuote = r;
                    this.primaryQuote.firstDeal.Quantity = this.primaryQuote.TotalSize;
                    this.primaryQuote.firstDeal.ClientId = this.primaryQuote.ClientId;
                    this.primaryQuote.firstDeal.Settlement = null;
                    this.isin = this.primaryQuote.ISIN;
                    this.productName = this.primaryQuote.ProductName;
                    if (this.primaryQuote.IsFirstOrder) {
                      //this.quoteService.GetPricingIssuers(this.primaryQuote.QuoteNbr).subscribe(v => {
                      //  this.issuers = v;
                      //});
                    }
                    else {
                      this.isFirstOrder = false;
                      //this.quoteService.GetPricingIssuers(this.primaryQuote.QuoteNbr).subscribe(v => {
                      //  this.issuers = v;
                      //});
                    }
                  
                  });
                  this.primaryQuote.IsPrimaryQuote = true;


                } else {
                  this.primaryQuote.QuoteNbr = this.nbr;
                  this.primaryQuote.SecondaryQuote = new SecondaryQuoteDto();
                  this.quoteService.GetSQByChildNbr(this.nbr).subscribe(r => {
                    this.primaryQuote.SecondaryQuote = r;
                    this.primaryQuote.SecondaryQuote.OrBetterBid.Value = true;
                    this.primaryQuote.SecondaryQuote.AtMarketBid.Value = false;
                    this.primaryQuote.firstDeal.Quantity = this.primaryQuote.SecondaryQuote.Size;
                    this.primaryQuote.firstDeal.ClientId = this.primaryQuote.SecondaryQuote.ClientId;
                    this.isin = this.primaryQuote.SecondaryQuote.ISIN;
                    this.productName = this.primaryQuote.SecondaryQuote.ProductName;


                    if (!Boolean(this.primaryQuote.SolveForValue)) {
                      //this.quoteService.GetPricingIssuers(this.primaryQuote.QuoteNbr).subscribe(v => {
                      //  this.issuers = v;
                      //});
                    } else {
                      this.isFirstOrder = false;
                    }
                  });
                  this.isSecondaryQuote = true;
                  this.primaryQuote.IsPrimaryQuote = false;
                }
              });


            } else {
              this.primaryQuote = new PrimaryQuoteDto();
              this.primaryQuote.SendAuto = true;

            }
          });

      });


    }

  }

  // Display error to user
  private processError(e: any) {
    console.log(e);

    if (e.status == 401 || e.status == 403) {
      this.notifierService.notify("warning", "You are not authorized to do this action.");
    } else {
      let message = "Unexpected error";
      if (e.error && e.error.Message) {
        message = e.error.Message;
      } else if (e.error) {
        message = e.error;
      } else if (e.status) {
        message += " (" + e.status + ") ";
      }

      console.log(message);
      this.notifierService.notify("error", message);
    }
  }
 
  ngOnInit(): void {

    if (!this.doneDeal && !this.doneDealMinifut && !this.toEdit) {
      setTimeout(() => {
        this.primaryQuote.firstDeal.ShowAllSize = true;
      }, 2000);
    }
    this.quoteService.GetGroupCode()
      .subscribe(
        x => {
          this.groupEntity = x;
          this.group = this.groupEntity.ApplicationGroupCode.replace("group.", "");
          this.isExternalSales = this.groupEntity.IsExternal;

          this.quoteService.GetAllDeals(25, 0, this.scopeTradeValue, this.group)
            .subscribe(
              x => this.deals = x,
              error => this.processError(error)
            );
          error => this.processError(error)
        });
    
  }


 
  ngDoCheck() {
    if (this.isSecondaryQuote && !this.isMinif && !this.idParam && this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value) {
      if (this.primaryQuote.firstDeal)this.primaryQuote.firstDeal.Settlement = null;
      if (this.primaryQuote.deals.length > 0) {
        this.primaryQuote.deals.forEach(d => {
          d.Settlement = null;
        });
      }

    }



    if (this.primaryQuote.IssuerCode && this.primaryQuote.IssuerCode !== this.prevIssuerCode) {
      let issuer = (this.isSecondaryQuote) ? this.primaryQuote.SecondaryQuote.IssuerCode : this.primaryQuote.IssuerCode;
      if (this.primaryQuote.QuoteNbr && issuer)
      {  this.quoteService.GetSolveForValue(this.primaryQuote.QuoteNbr, issuer).subscribe(v => {
        this.primaryQuote.SolveForValue = Boolean(v) ? parseFloat(v) : 0
      });
    }
      this.prevIssuerCode = issuer;
    }

    setTimeout(() => {
      if (this.primaryQuote.firstDeal) {
        this.primaryQuote.firstDeal.IsEqualSizes = (this.primaryQuote.firstDeal.AllSize == this.primaryQuote.firstDeal.Quantity) && this.primaryQuote.firstDeal.AllSize != 0 && this.primaryQuote.firstDeal.Quantity != 0;
        //AllSize
        if (this.primaryQuote.firstDeal.ShowAllSize && !this.primaryQuote.firstDeal.IsEqualSizes) {
          if ((!this.primaryQuote.firstDeal.Custodians || this.primaryQuote.firstDeal.Custodians.length == 0) && !this.primaryQuote.firstDeal.IsEqualSizes) {
            this.primaryQuote.CustodianSize = this.primaryQuote.firstDeal.Quantity;
          } else if (this.primaryQuote.firstDeal.Custodians && this.primaryQuote.firstDeal.Custodians.length > 0 && !this.primaryQuote.firstDeal.IsEqualSizes) {
            this.primaryQuote.firstDeal.Custodians[this.primaryQuote.firstDeal.Custodians.length - 1].CustodianSize = this.primaryQuote.firstDeal.Quantity - this.primaryQuote.firstDeal.AllSize;
          }
        }
      }
      if (this.primaryQuote.deals != null) {
        this.primaryQuote.deals.forEach(d => {
          d.IsEqualSizes = (d.AllSize == d.Quantity) && d.AllSize != 0 && d.Quantity != 0;
          //All size behaviour
          if (d.ShowAllSize && !d.IsEqualSizes) {

            if (d.Custodians.length == 1 && !d.IsEqualSizes) {
              d.Custodians[d.Custodians.length - 1].CustodianSize = d.Quantity;
            } else if (d.Custodians.length > 1 && !d.IsEqualSizes) {
              d.Custodians[d.Custodians.length - 1].CustodianSize = d.Quantity - d.AllSize;
            }
          }
        })
      }

    });
  }

  pad(number, length) {

  var str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }

  return str;

}

  parseProd(product: ProductDto) {
    this.isin = product.ISIN;
    this.primaryQuote.ISIN = this.isin;
    this.productName = product.Name;
    this.primaryQuote.MiniFuture.Currency = product.CurrencyCode;
    this.primaryQuote.MiniFuture.IssuerCode = product.IssuerCode;
    this.primaryQuote.MiniFuture.Underlyings = product.Underlyings;
    this.primaryQuote.MiniFuture.Strike = product.Strike;
    this.primaryQuote.MiniFuture.Id = product.Id;
    this.primaryQuote.MiniFuture.Sens = product.Sens;
    this.primaryQuote.MiniFuture.StopLoss = product.StopLoss;
    this.primaryQuote.MiniFuture.ChildQuoteNbr = this.nbr;
    if (Boolean(this.minifitUnder)) {
      this.primaryQuote.MiniFuture.Underlyings = this.minifitUnder;

    }
    if (Boolean(this.issuer)) {
      this.primaryQuote.MiniFuture.IssuerCode = this.issuer;

    }
    
  }
  SQIssuerValue(event) {
    this.primaryQuote.SecondaryQuote.IssuerCode = event;
    this.quoteService.GetSolveForValue(this.primaryQuote.QuoteNbr, this.primaryQuote.SecondaryQuote.IssuerCode).subscribe(v => {
      this.primaryQuote.SolveForValue = Boolean(v) ? parseFloat(v) : 0
      if (this.primaryQuote.firstDeal != null && this.isSecondaryQuote && this.primaryQuote.SecondaryQuote.Side == 'Bid') {
        this.primaryQuote.firstDeal.Settlement = this.primaryQuote.SolveForValue;
      }
    });
  }

  issuerChange(event) {
    let issuer = event.substring(
      event.lastIndexOf("!") + 1,
      event.lastIndexOf(":")
    );
    let issuerCode = event.substring(
      event.lastIndexOf(":") + 1,
      event.lastIndexOf(";")
    );
    this.IsBestIssuer = event.includes("Best Between requested Issuer");
    if (event != null && !event.includes("Best Between requested Issuer")) {
      this.quoteService.GetSolveForValue(this.primaryQuote.QuoteNbr, issuerCode).subscribe(v => {
        this.primaryQuote.SolveForValue = Boolean(v) ? parseFloat(v) : 0
        this.primaryQuote.IssuerCode = issuerCode;
        this.primaryQuote.Issuer = issuer;
        if (this.primaryQuote.firstDeal != null && this.isSecondaryQuote && this.primaryQuote.SecondaryQuote.Side == 'Bid') {
          this.primaryQuote.firstDeal.Settlement = this.primaryQuote.SolveForValue;
        }
        if (this.isSecondaryQuote) {
          this.primaryQuote.SecondaryQuote.Issuer = issuer;
          this.primaryQuote.SecondaryQuote.IssuerCode = issuerCode;
        }
      });
      this.primaryQuote.SolveForValue = Boolean(this.primaryQuote.SolveForValue) ? this.primaryQuote.SolveForValue : 0

    } else {
      this.primaryQuote.SolveForValue = 0;
    }
  }

  custoSelect(event, custo: SettlementDto) {
    if (custo != null) {
      custo.Description = event;
    } else {
      this.primaryQuote.CustodianDescription = event;
    }

  }


  parseClient(client: ClientDto) {
    this.primaryQuote.firstDeal.ClientCode = client.ClientCode;
    this.primaryQuote.firstDeal.ClientId = client.Id;
  }
  parseDealClient(client: ClientDto) {
    if (!this.doneDeal && !this.doneDealMinifut) {
      this.primaryQuote.deals[this.primaryQuote.deals.length - 1].ClientCode = client.ClientCode;
      this.primaryQuote.deals[this.primaryQuote.deals.length - 1].ClientId = client.Id;
    }

  }
  copySettlements() {
    if (this.primaryQuote.deals.length > 0) {
      var originalVal = (this.primaryQuote.firstDeal) ? this.primaryQuote.firstDeal.Settlement : this.primaryQuote.deals[0].Settlement;
      this.primaryQuote.deals.forEach(c => {
        c.Settlement = originalVal;
      })
    }
    else return;
  }

  addItem() {
    if (this.primaryQuote.deals == null) {
      this.primaryQuote.deals = [];
    }
    if (this.primaryQuote.deals.length > 0) {
      let lastIndex = this.primaryQuote.deals.length - 1;

      if (this.primaryQuote.deals[lastIndex].ClientCode != null && this.primaryQuote.deals[lastIndex].Quantity != null && (this.primaryQuote.deals[lastIndex].Settlement != null || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value))
        && this.primaryQuote.deals[lastIndex].ClientCode != "" && this.primaryQuote.deals[lastIndex].Quantity.toString() != "" && ((this.primaryQuote.deals[lastIndex].Settlement && this.primaryQuote.deals[lastIndex].Settlement.toString() != "") || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value))) {


        this.primaryQuote.deals.push({
          Quantity: null,
          Settlement: null,
          ClientId: "",
          OrderId: "",
          Trader: "",
          Client: "",
          side: "",
          isin: "",
          issuer: "",
          title: "",
          QuoteChildNbr: "",
          maturity: "",
          size: "",
          currency: "",
          underlyings: "",
          ShowAllSize: false,
          ClientCode: "",
          Custodians: [{ CustodianSize: null, CustodianCode: null, IsDeleted: false, IsNew: true, Description: null, Id: null }],
          Comment: '',
          TBD: false,
          IsTBD: false,
          AllSize: 0,
          IsEqualSizes: false,
          DealNbr: "",
          Status: "",
          Color: "",
          ClientPrice: 0,
          Margins: [],
          OrderDescription:"",
          DealDescription: "",
          Selected: false


        })
      }
    } else {
      if (this.primaryQuote.firstDeal.ClientId != null && (this.primaryQuote.firstDeal.Settlement != null || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value)) && this.primaryQuote.firstDeal.Quantity != null
        && this.primaryQuote.firstDeal.ClientId != "" && ((this.primaryQuote.firstDeal.Settlement && this.primaryQuote.firstDeal.Settlement.toString() != "") || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value)) && this.primaryQuote.firstDeal.Quantity.toString() != "") {
        this.primaryQuote.deals.push({
          Quantity: null,
          Settlement: null,
          ClientId: "",
          OrderId: "",
          Trader: "",
          Client: "",
          side: "",
          isin: "",
          issuer: "",
          title: "",
          QuoteChildNbr: "",
          maturity: "",
          size: "",
          currency: "",
          underlyings: "",
          ClientCode: "",
          ShowAllSize: false,
          Custodians: [{ CustodianSize: null, CustodianCode: null, IsDeleted: false, IsNew: true, Description: null, Id: null }],
          Comment: '',
          TBD: false,
          IsTBD: false,
          AllSize: 0,
          IsEqualSizes: false,
          DealNbr: "",
          Status: "",
          Color: "",
          ClientPrice: 0,
          Margins: [],
          OrderDescription: "",
          DealDescription: "",
          Selected: false




        })
      }


    }



  }

  totalSize(): number {
    this.primaryQuote.TotalSize = 0;
    if (this.primaryQuote.firstDeal) {
      if (this.primaryQuote.firstDeal.ClientId != null && (this.primaryQuote.firstDeal.Settlement != null || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value)) && this.primaryQuote.firstDeal.Quantity != null
        && this.primaryQuote.firstDeal.ClientId != "" && ((this.primaryQuote.firstDeal.Settlement && this.primaryQuote.firstDeal.Settlement.toString() != "") || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value)) && this.primaryQuote.firstDeal.Quantity.toString() != "") {
        this.primaryQuote.TotalSize = this.primaryQuote.TotalSize + this.primaryQuote.firstDeal.Quantity;
      }
    }
   
    if (this.primaryQuote.deals != null) {
      this.primaryQuote.deals.forEach(p => {
        if ((p.ClientId != null && p.Settlement != null && p.Quantity != null 
          && p.ClientId != "" && p.Settlement.toString() != "" && p.Quantity.toString() != "") || this.isMinif || (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value)) {
          this.primaryQuote.TotalSize = this.primaryQuote.TotalSize + p.Quantity;
        }
      })
    }

    return this.primaryQuote.TotalSize;

  }
  removeDeal(index: number) {
    this.primaryQuote.deals.splice(index, 1);
  }
  removeCustodianFirstDeal(index: number) {
    this.primaryQuote.firstDeal.Custodians.splice(index, 1);
  }
  removeCustodian(deal: DealDto, index: number, isFirstDeal?: boolean) {
    if (isFirstDeal) {
      if (deal.Custodians[index].IsNew == false) {
        deal.Custodians[index].IsDeleted = true;
      } else {
        deal.Custodians.splice(index, 1);
      }
      
    }
  }
  addCusto(deal?: DealDto) {
    if (this.primaryQuote.firstDeal.Custodians == null) {
      this.primaryQuote.firstDeal.Custodians = [];
    }

    if (deal != null) {
      let lastIndex = deal.Custodians.length - 1;
      if (deal.Custodians[lastIndex].CustodianCode != null && deal.Custodians[lastIndex].CustodianSize != null
        && deal.Custodians[lastIndex].CustodianCode != "" && deal.Custodians[lastIndex].CustodianSize.toString() != "" || deal.IsTBD) {
        deal.Custodians.push({
          CustodianSize: null,
          CustodianCode: null,
          IsDeleted: false,
          IsNew: true,
          Description: null,
          Id: null

        });
        deal.ShowAllSize = false;
      }
    } else {


      if (this.primaryQuote.firstDeal.Custodians.length > 0) {
        let lastIndex = this.primaryQuote.firstDeal.Custodians.length - 1;

        if (this.primaryQuote.firstDeal.Custodians[lastIndex].CustodianCode != null && this.primaryQuote.firstDeal.Custodians[lastIndex].CustodianSize != null
          && this.primaryQuote.firstDeal.Custodians[lastIndex].CustodianCode != "" && this.primaryQuote.firstDeal.Custodians[lastIndex].CustodianSize.toString() != "" || this.primaryQuote.firstDeal.IsTBD) {
        this.primaryQuote.firstDeal.Custodians.push( {
          CustodianSize: null,
          CustodianCode: null,
          IsDeleted: false,
          IsNew: true,
          Description: null,
          Id:null


        });
          this.primaryQuote.firstDeal.ShowAllSize = false;
      }
      } else {
        if (this.primaryQuote.CustodianCode != null && this.primaryQuote.CustodianSize != null
          && this.primaryQuote.CustodianCode != "" && Boolean(this.primaryQuote.CustodianSize) || this.primaryQuote.firstDeal.IsTBD) {
          this.primaryQuote.firstDeal.Custodians.push({
            CustodianSize: null,
            CustodianCode: null,
            IsDeleted: false,
            IsNew: true,
            Description: null,
            Id: null


          });
          this.primaryQuote.firstDeal.ShowAllSize = false;

        }

      }


    }

  }

  allSizeFirstCusto(): string {
    this.primaryQuote.firstDeal.AllSize = 0;
   
    if (this.primaryQuote.CustodianSize != null
      && this.primaryQuote.CustodianSize.toString() != "") {
      this.primaryQuote.firstDeal.AllSize = this.primaryQuote.CustodianSize + this.primaryQuote.firstDeal.AllSize;
    
    }
    if (this.primaryQuote.firstDeal.Custodians != null) {
      this.primaryQuote.firstDeal.Custodians.forEach(c => {
        if (!c.IsDeleted) {
        this.primaryQuote.firstDeal.AllSize = this.primaryQuote.firstDeal.AllSize + c.CustodianSize;

        }
      })
    }
    return this.sizeFormatted(this.primaryQuote.firstDeal.AllSize) + '/' + this.sizeFormatted(this.primaryQuote.firstDeal.Quantity);

  }


  ordinalSuffix(i: number) {
    let j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + '-st';
    }
    if (j == 2 && k != 12) {
      return i + '-nd';
    }
    if (j == 3 && k != 13) {
      return i + '-rd';
    }
    return i + '-th';
  }
  allSizeCusto(deal: DealDto): string {
    deal.AllSize = 0;
    if (deal != null) {
      deal.Custodians.forEach(c => {
        deal.AllSize = deal.AllSize + c.CustodianSize;
      })

    }
    return this.sizeFormatted(deal.AllSize) + '/' + this.sizeFormatted(deal.Quantity);

  }
   sizeFormatted(size: number) {
    if (!size) {
      return "0";
    }

    if (size >= 1000000) {
      return String(size / 1000000) + " M";
    }
    else if (size >= 1000) {
      return String(size / 1000) + " K";
    }
    else {
      return String(size);
    }
  }
  onChange(type: string) {
    this._chRef.detectChanges();
    switch (type) {
      case 'AtMarketSolve':
        this.primaryQuote.AtMarketReoffer.Value = false;
        this.primaryQuote.AtMarketReoffer.IsShown = false;

        this.primaryQuote.OrBetterReoffer.IsShown = false;
        this.primaryQuote.OrBetterReoffer.Value = false;

        this.primaryQuote.OrBetterSolve.Value = false;
        this.primaryQuote.OrBetterSolve.IsShown = false;
        break;
      case 'AtMarketReoffer':
        this.primaryQuote.AtMarketSolve.Value = false;
        this.primaryQuote.AtMarketSolve.IsShown = false;

        this.primaryQuote.OrBetterReoffer.IsShown = false;
        this.primaryQuote.OrBetterReoffer.Value = false;

        this.primaryQuote.OrBetterSolve.Value = false;
        this.primaryQuote.OrBetterSolve.IsShown = false;
        break;
      case 'OrBetterReoffer':
        this.primaryQuote.AtMarketReoffer.Value = false;
        this.primaryQuote.AtMarketReoffer.IsShown = false;

        this.primaryQuote.AtMarketSolve.IsShown = false;
        this.primaryQuote.AtMarketSolve.Value = false;

        this.primaryQuote.OrBetterSolve.Value = false;
        this.primaryQuote.OrBetterSolve.IsShown = false;
        break;
      case 'OrBetterSolve':
        this.primaryQuote.AtMarketReoffer.Value = false;
        this.primaryQuote.AtMarketReoffer.IsShown = false;

        this.primaryQuote.OrBetterReoffer.IsShown = false;
        this.primaryQuote.OrBetterReoffer.Value = false;

        this.primaryQuote.AtMarketSolve.Value = false;
        this.primaryQuote.AtMarketSolve.IsShown = false;
        break;
      default:
        break;
    }

  }

  onChangeBid(IsOrBetter: boolean) {
    this._chRef.detectChanges();

    if (IsOrBetter) {
      if (this.primaryQuote.SecondaryQuote.OrBetterBid.Value == true) {
        this.primaryQuote.SecondaryQuote.AtMarketBid.Value = false;
        this.primaryQuote.SecondaryQuote.AtMarketBid.IsShown = false;

      }
    } else {
      if (this.primaryQuote.SecondaryQuote.AtMarketBid.Value == true) {
        this.primaryQuote.SecondaryQuote.OrBetterBid.Value = false;
        this.primaryQuote.SecondaryQuote.OrBetterBid.IsShown = false;


      }
    }
  


  }

  onChangeTbd(firstDeal: boolean, deal: DealDto) {
    if (firstDeal) {
      if (deal.TBD == true) {
        this.primaryQuote.CustodianCode = 'custodian.ff85bb46-6388-43a6-bbf2-a1e1174af8ad';
        this.primaryQuote.CustodianDescription = 'TBD'
        if (deal.Custodians != null) {
          deal.Custodians.forEach(c => {
            c.CustodianCode = 'custodian.ff85bb46-6388-43a6-bbf2-a1e1174af8ad';
            c.Description = 'TBD'
          })
        }

        //All size behaviour

        if ((!deal.Custodians || deal.Custodians.length == 0) && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          this.primaryQuote.CustodianSize = deal.Quantity;
        } else if (deal.Custodians && deal.Custodians.length > 0 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize; 
        }

      
      } else {
        this.primaryQuote.CustodianCode = '';
        if (deal.Custodians != null) {
          deal.Custodians.forEach(c => {
            c.CustodianCode = '';
          })
        }
      }

    } else {
      if (deal.TBD == true) {
        if (deal.Custodians != null) {
          deal.Custodians.forEach(c => {
            c.CustodianCode = 'custodian.ff85bb46-6388-43a6-bbf2-a1e1174af8ad';
            c.Description = 'TBD'

          })
        }
        //All size behaviour
        if (deal.Custodians.length == 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity;
        } else if (deal.Custodians.length > 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize;
        }

      } else {
        if (deal.Custodians != null) {
          deal.Custodians.forEach(c => {
            c.CustodianCode = '';
          })
        }
      }
    }

  }

  onChangeSize(firstDeal: boolean, deal: DealDto) {
    if (firstDeal) {
      if (deal.ShowAllSize == true) {
    
        //All size behaviour
        if ((!deal.Custodians || deal.Custodians.length == 0) && !deal.IsEqualSizes) {
          this.primaryQuote.CustodianSize = deal.Quantity;
        } else if (deal.Custodians && deal.Custodians.length > 0 && !deal.IsEqualSizes) {
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize;
        }


      } 

    } else {
      if (deal.ShowAllSize == true) {
       
        //All size behaviour
        if (deal.Custodians && deal.Custodians.length == 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity;
        } else if (deal.Custodians && deal.Custodians.length > 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize;
        }
      } 
    }

  }
  checkDoneDealValidation() {
    this.valError = false;
    this.errors = [];

    if (this.primaryQuote.deals.length > 0) {
      let errorAdded = false;
      this.primaryQuote.deals.forEach(d => {
        if (d.Settlement == 0) {
          this.errors.push('Settlement cannot be 0');
          this.valError = true;
          errorAdded = true;
        }
        else if (!Boolean(d.Settlement) && !errorAdded) {
          this.errors.push('Settlement is required');
          this.valError = true;
          errorAdded = true;

        }
      });
    }

    if (!this.primaryQuote.Reoffer && this.primaryQuote.SolveForCode != 'Reoffer' && this.primaryQuote.IsPrimaryQuote) {
      this.errors.push('Reoffer is required');
      this.valError = true;
    }
    if (!this.primaryQuote.Reoffer && !this.primaryQuote.IsPrimaryQuote) {
      this.errors.push('Reoffer is required');
      this.valError = true;
    }
    if (!this.primaryQuote.TradeDate) {
      this.errors.push('Trade date is required');
      this.valError = true;
    }
    if (!this.primaryQuote.TradeTime) {
      this.errors.push('Trade time is required');
      this.valError = true;
    }
    if (!this.primaryQuote.Support) {
      this.errors.push('Support type is required');
      this.valError = true;
    }
    if (this.primaryQuote.IssuerCode == 'issuer.hsbc' && !this.primaryQuote.Team) {
      this.errors.push('Team is required');
      this.valError = true;
    }
    if (!this.primaryQuote.QuotationTypeCode) {
      this.errors.push('Quotation type is required');
      this.valError = true;
    }
    if (!this.primaryQuote.IssuerCode) {
      this.errors.push('Issuer is required');
      this.valError = true;
    }

    if (this.primaryQuote.IsPrimaryQuote) {

      if (!this.primaryQuote.StrikeType) {
        this.errors.push('Strike Type is required');
        this.valError = true;
      }
      if (!this.primaryQuote.DeliveryType) {
        this.errors.push('Delevery Type is required');
        this.valError = true;
      }
      if (!this.primaryQuote.Amortissement) {
        this.errors.push('Amortissement is required');
        this.valError = true;
      }
      if (!this.primaryQuote.IssueDates) {
        this.errors.push('Issue Date is required');
        this.valError = true;
      }
   
      if (!this.primaryQuote.SolveForValue) {
        this.errors.push('Solve value is required');
        this.valError = true;
      }
   

    }

  }
  checkMinifutDoneDealValidation() {
    this.valError = false;
    this.errors = [];
    if (this.primaryQuote.deals.length > 0) {
      let errorAdded = false;
      this.primaryQuote.deals.forEach(d => {
        if (d.Settlement == 0) {
          this.errors.push('Settlement cannot be 0');
          this.valError = true;
          errorAdded = true;
        }
        else if (!Boolean(d.Settlement) && !errorAdded) {
          this.errors.push('Settlement is required');
          this.valError = true;
          errorAdded = true;

        }
      });
    }

    if (!this.primaryQuote.Reoffer) {
      this.errors.push('USS Price is required');
      this.valError = true;
    }
    if (!this.primaryQuote.TradeDate) {
      this.errors.push('Trade date is required');
      this.valError = true;
    }
    if (!this.primaryQuote.TradeTime) {
      this.errors.push('Trade time is required');
      this.valError = true;
    }
    if (!this.primaryQuote.Support) {
      this.errors.push('Support type is required');
      this.valError = true;
    }
 
    if (!this.primaryQuote.IssuerCode) {
      this.errors.push('Issuer is required');
      this.valError = true;
    }
    if (!this.primaryQuote.NbUnit) {
      this.errors.push('Nb certificats is required');
      this.valError = true;
    }



  }
  checkValidation() {
    this.valError = false;
    this.errors = [];

    if (this.isSecondaryQuote) {
      if (!this.primaryQuote.SecondaryQuote.AtMarketBid.Value && !this.primaryQuote.SecondaryQuote.OrBetterBid.Value) {
        this.errors.push('At least one of OR BETTER or AT MARKET boxes must be checked');
        this.valError = true;
      }
      if (!this.primaryQuote.SecondaryQuote.IssuerCode) {
        this.errors.push('Issuer is required');
        this.valError = true;
      }
    }
    if (!this.isSecondaryQuote && !this.isMinif && !this.idParam) {
      if (!this.primaryQuote.AtMarketReoffer.Value && !this.primaryQuote.OrBetterReoffer.Value && !this.primaryQuote.AtMarketSolve.Value && !this.primaryQuote.OrBetterSolve.Value && !this.IsBestIssuer) {
        this.errors.push('At least one of OR BETTER or AT MARKET boxes must be checked');
        this.valError = true;
      }
      if (!this.primaryQuote.StrikeType) {
        this.errors.push('Strike Type is required');
        this.valError = true;
      }
      if (!this.primaryQuote.DeliveryType) {
        this.errors.push('Delevery Type is required');
        this.valError = true;
      }
      if (!this.primaryQuote.Amortissement) {
        this.errors.push('Amortissement is required');
        this.valError = true;
      }
      if (!this.primaryQuote.Amortissement) {
        this.errors.push('Issue Date is required');
        this.valError = true;
      }
      if (!this.primaryQuote.IssuerCode) {
        this.errors.push('Issuer is required');
        this.valError = true;
      }
    }
    if (!this.toEdit) {
      if (!Boolean(this.primaryQuote.firstDeal.ClientCode) || !Boolean(this.primaryQuote.firstDeal.Quantity) || (!Boolean(this.primaryQuote.firstDeal.Settlement) && !this.isMinif && (this.primaryQuote.SecondaryQuote && !this.primaryQuote.SecondaryQuote.AtMarketBid.Value))) {
        this.errors.push('At least one completed deal is required');
        this.valError = true;
      }
      if ((!Boolean(this.primaryQuote.firstDeal.ClientCode) || !Boolean(this.primaryQuote.firstDeal.Quantity) || (!Boolean(this.primaryQuote.firstDeal.Settlement) && !this.isMinif)) && !this.isSecondaryQuote) {
        this.errors.push('At least one completed deal is required');
        this.valError = true;
      }
      if (!Boolean(this.primaryQuote.CustodianCode) || (!Boolean(this.primaryQuote.CustodianSize) && !this.primaryQuote.firstDeal.IsTBD)) {
        this.errors.push('Custudian\'s information is required');
        this.valError = true;

      }
      if (!this.primaryQuote.firstDeal.IsEqualSizes && !this.primaryQuote.firstDeal.IsTBD) {
        this.errors.push('Error on the ' + this.ordinalSuffix(1) + ' deal: Total quantities must be equal to allocation quantities');
        this.valError = true;

      }
    }
    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        if (!this.primaryQuote.deals[i].IsEqualSizes && !this.primaryQuote.deals[i].IsTBD) {
          this.errors.push('Error on the ' + this.ordinalSuffix(i+2) + ' deal: Total quantities must be equal to allocation quantities');
          this.valError = true;

        }
      }
    }
    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        for (let j = 0; j < this.primaryQuote.deals[i].Custodians.length; j++) {
          if ((!Boolean(this.primaryQuote.deals[i].Custodians[j].CustodianCode) || (!Boolean(this.primaryQuote.deals[i].Custodians[j].CustodianSize) && !this.primaryQuote.deals[i].IsTBD))) {
            this.errors.push('Custudian\'s information is required');
            this.valError = true;
          }
        }
     
      }
    }
    if (this.primaryQuote.SecondaryQuote && this.primaryQuote.SecondaryQuote.AtMarketBid.Value) {
      if (this.primaryQuote.firstDeal) this.primaryQuote.firstDeal.Settlement = 0;
     
      if (this.primaryQuote.deals.length > 0) {
        this.primaryQuote.deals.forEach(d => {
          d.Settlement = 0;
        });
      }
    }

    if (this.isMinif) {
      if (this.primaryQuote.firstDeal != null) {
        this.primaryQuote.firstDeal.Settlement = 0;

      }
    if (this.primaryQuote.MiniFuture.Type == 'MKT') {
      this.primaryQuote.MKTSolve = true;
    }
     
      if (this.primaryQuote.deals && this.primaryQuote.deals.length > 0) {
        this.primaryQuote.deals.forEach(d => {
          d.Settlement = 0;
        });
      }
      if (!Boolean(this.primaryQuote.MiniFuture.ProductId)) {
        this.errors.push('Isin is required');
        this.valError = true;

      }
      if (!Boolean(this.primaryQuote.MiniFuture.Side)) {
        this.errors.push('Side is required');
        this.valError = true;

      }
      if (!Boolean(this.primaryQuote.MiniFuture.Type)) {
        this.errors.push('Type is required');
        this.valError = true;

      }
      if (this.primaryQuote.MiniFuture.Type != 'MKT') {
        if (!Boolean(this.primaryQuote.MiniFuture.Validity)) {
          this.errors.push('Validity is required');
          this.valError = true;
        }
      }
      if (this.primaryQuote.MiniFuture.Type != 'MKT') {
        if (!Boolean(this.primaryQuote.MiniFuture.UnderlyingPrice)) {
          this.errors.push('Underlying price is required');
          this.valError = true;
        }
      }

      if (this.primaryQuote.MiniFuture.Validity == 'GTD') {
        let dateReg = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
        if (!dateReg.test(this.primaryQuote.MiniFuture.GTDDate.toString())) {
          this.errors.push('GTD date is required');
          this.valError = true;
        } 
      }
    }

  }
  cleanEmptyDeals() {
    //clear first deal's custo
    if (this.primaryQuote.firstDeal != null && this.primaryQuote.firstDeal.Custodians != null) {
      for (let i = 0; i < this.primaryQuote.firstDeal.Custodians.length; i++) {
        if (!Boolean(this.primaryQuote.firstDeal.Custodians[i].CustodianCode) || (!Boolean(this.primaryQuote.firstDeal.Custodians[i].CustodianSize) && !this.primaryQuote.firstDeal.IsTBD)) {
          this.primaryQuote.firstDeal.Custodians.splice(i, 1);
        }
      }
    }
    //clear empty additional deals
    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        if (!Boolean(this.primaryQuote.deals[i].ClientCode) || !Boolean(this.primaryQuote.deals[i].Quantity) || (!Boolean(this.primaryQuote.deals[i].Settlement) && !this.isMinif && (this.primaryQuote.SecondaryQuote && !this.primaryQuote.SecondaryQuote.AtMarketBid.Value) )) {
          this.primaryQuote.deals.splice(i, 1);
        }
      }
    }
    //Clear empty additional custo
    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        if (this.primaryQuote.deals[i].Custodians != null) {
          for (let j = 0; j < this.primaryQuote.deals[i].Custodians.length; j++) {
            if ((!Boolean(this.primaryQuote.deals[i].Custodians[j].CustodianCode) || (!Boolean(this.primaryQuote.deals[i].Custodians[j].CustodianSize) && !this.primaryQuote.firstDeal.IsTBD)) && j>0) {
              this.primaryQuote.deals[i].Custodians.splice(j, 1);
            }
          }
          
        }
      }
    }
    
  }
  formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month,day].join('-');
  }
  sendOrder() {
   
    this.cleanEmptyDeals();
    this.checkValidation();

    //addOnBehalf
    this.primaryQuote.OnBehalf = this.onBehalfSales;

    if (!this.isMinif || this.toEdit) {
      if (!this.toEdit) {
        if (!this.valError) {
          this.clicked = true;
          this.quoteService.SendOrder(this.primaryQuote).subscribe(c => {
            this.notifierService.notify("success", "Order sent");
            var self = this;
            setTimeout(() => {
              self.updateDealCatcher();
              self.router.navigate(['/quoting/list']);
            }, 500);

        },
          e => {
            if (e.error && e.error.Message && e.error.Message.indexOf("another Order is already") > 0) {
              this.notifierService.notify("error", e.error.Message.replace("--",", "));
            }
            else
            this.notifierService.notify("error", "Retry later");
          });
      } else {
        this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
        scroll(0, 0);

        }
      }
      else {
        if (!this.valError) {
          this.clicked = true;
          if (this.isMinif) {
            if (this.primaryQuote.MiniFuture.Validity == 'GTD') {
              let dateReg = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
              if (dateReg.test(this.primaryQuote.MiniFuture.GTDDate.toString())) {
                this.primaryQuote.MiniFuture.GTDDate = new Date(this.primaryQuote.MiniFuture.GTDDate);
              } else {
                this.primaryQuote.MiniFuture.GTDDate = new Date("2001-01-01");
              }
            }
          }
          this.quoteService.EditOrder(this.primaryQuote).subscribe(c => {
            this.notifierService.notify("success", "Order Edited");
            var self = this;
            setTimeout(() => {
              self.updateDealCatcher();
              self.router.navigate(['/quoting/list']);
            }, 500);

          },
            e => {
              this.notifierService.notify("error", "Retry later");
            });
        } else {
          this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
          scroll(0, 0);

        }
      }
    
    } else {
      if (!this.valError) {
     
        this.clicked = true;
        if (this.primaryQuote.MiniFuture.Validity == 'GTD') {
          let dateReg = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
          if (dateReg.test(this.primaryQuote.MiniFuture.GTDDate.toString())) {
            this.primaryQuote.MiniFuture.GTDDate = new Date(this.primaryQuote.MiniFuture.GTDDate);
          } else {
            this.primaryQuote.MiniFuture.GTDDate = new Date("2001-01-01");
          }
        }
        /*this.primaryQuote.MiniFuture.ChildQuoteNbr = nbr;*/
        this.quoteService.SendMinifutOrder(this.primaryQuote).subscribe(c => {

          this.notifierService.notify("success", "Order sent");
          var self = this;
          setTimeout(() => {
            self.updateDealCatcher();
            self.router.navigate(['/quoting/list']);
          }, 500);

        },
          e => {
            this.notifierService.notify("error", "Retry later");
          });
      } else {
        this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
        scroll(0, 0);
    }
    }
 
  }

  goToHome() {
    this.router.navigate(['/']);

  }

  goToProducts() {
    this.router.navigate(['/products']);

  }
  SendMinifutDoneDeal() {
    this.checkMinifutDoneDealValidation();
    if (!this.valError) {
      this.clicked = true;
      this.primaryQuote.TotalSize = this.totalSize();

 

      this.quoteService.SendMinifutDoneDeal(this.primaryQuote).subscribe(c => {      
        this.notifierService.notify("success", "Minifut Done deal executed");
        setTimeout(() => {
          this.router.navigate(['/quoting/list']);
        }, 500);

      },
        e => {
          this.notifierService.notify("error", "Retry later");
        });
    } else {
      this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
      scroll(0, 0);
    }
  }

  sendDoneDeal() {
    this.checkDoneDealValidation();

    if (!this.valError) {
      this.clicked = true;

      this.quoteService.SendDoneDeal(this.primaryQuote).subscribe(c => {
        this.notifierService.notify("success", "Done deal executed");
        setTimeout(() => {
          this.router.navigate(['/quoting/list']);
        }, 500);

      },
        e => {
          this.notifierService.notify("error", "Retry later");
        });
    } else {
      this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
      scroll(0, 0);
    }

  }

  async updateDealCatcher() {

    let CefSharp = (window as any).CefSharp;
    if (!CefSharp)
      return;

    let dealcatcherevents = (window as any).dealcatcherevents;

    if (!dealcatcherevents) {
      await CefSharp.BindObjectAsync("dealcatcherevents");
    }

    dealcatcherevents = (window as any).dealcatcherevents;
    dealcatcherevents.ordersent();
  }
  
}
