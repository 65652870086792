import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ProductService } from '../services/product.service';
import { Observable, of } from 'rxjs';
import { FormControl } from '@angular/forms';
import { startWith, map, debounceTime, tap, switchMap, finalize, distinctUntilChanged, catchError } from 'rxjs/operators';
import { ProductDto } from '../quoting/ProductDto.Model';
import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'product-autocomplete',
  templateUrl: './product-autocomplete.component.html',
  styleUrls: ['./product-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductAutocompleteComponent implements OnInit {

  _isin: string;
  _productId: string;

  searchProductCtrl = new FormControl();
  filteredProducts: any;
  disabledControls: boolean;
  isLoading = false;
  searching = false;
  searchFailed = false;
  _product: ProductDto;

  @Input() type = '';
  @Input()
  set isin(val: string) {
    this._isin = val;
    this.isinChange.emit(val);
  }

  get isin() {
    return this._isin;
  }

  @Output()
  isinChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  set productId(val: string) {
    if (this._productId != val) {
      this._productId = val;
      this.productService.GetProductById(val).subscribe(c => {
        this.searchProductCtrl.setValue(c.ISIN);
        this.setProduct(c);
      });

    }
  }

  @Output()
  productIdChange: EventEmitter<string> = new EventEmitter<string>();

  get product() {
    return this._product;
  }

  set product(val: ProductDto) {
    this._product = val;
  }



  @Output()
  productChange: EventEmitter<ProductDto> = new EventEmitter<ProductDto>();


  constructor(private productService: ProductService) { }

  // search = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(300),
  //     distinctUntilChanged(),
  //     tap(() => this.searching = true),
  //     switchMap(term =>
  //       this.productService.GetProductsByIsin(term).pipe(
  //         tap(() => this.searchFailed = false),
  //         catchError(() => {
  //           this.searchFailed = true;
  //           return of([]);
  //         }))
  //     ),
  //     tap(() => this.searching = false)
  //   )

  ngOnInit(): void {

    if (this.type == 'secondaryOrder') {
      this.searchProductCtrl.valueChanges
        .pipe(
          debounceTime(500),
          tap(() => {
            this.filteredProducts = [];
            this.isLoading = true;
          }),
          switchMap(value => this.productService.GetDeDraftSecondaryProductsByIsin(value)
            .pipe(
              finalize(() => {
                this.isLoading = false
                this.isin = value;
              }),
            )
          )
        )
        .subscribe(data => {
          this.filteredProducts = data;


        });
    }
    else {
      this.searchProductCtrl.valueChanges
        .pipe(
          debounceTime(500),
          tap(() => {
            this.filteredProducts = [];
            this.isLoading = true;
          }),
          switchMap(value => this.productService.GetProductsByIsin(value)
            .pipe(
              finalize(() => {
                this.isLoading = false
                this.isin = value;
              }),
            )
          )
        )
        .subscribe(data => {
          this.filteredProducts = data;


        });
    }

  
  }


  setProduct(val: ProductDto) {
    console.log(val);
    this._productId = val.Id;
    this.product = val;
    this.isin = val.ISIN;
    this.productIdChange.emit(val.Id);
    this.productChange.emit(val);
  }
}
