import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr";  // or from "@microsoft/signalr" if you are using a new library
import { DatasModel } from '../models/DatasModel';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  public data: DatasModel[];
  public hubConnection: signalR.HubConnection

  public startConnection = () => {

    console.log("************************ Connecting to SignalR");

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/api/notifications')
      .build();

      this.hubConnection
      .start()
      .then(() => console.log('Connected to SignalR'))
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  //  public subscribeToQuotingCount = () => {
     
  //   this.hubConnection.on('QuotingCount', (count: string, body: string) => {
  //     //this.messages.push({ from, body });
  //     console.log("************************ Message Receveid from SignalR");
  //   });
  //  }




  constructor() { }
}
