<ol class="breadcrumb row">
  <li><a ui-sref="home">Home</a></li>
  <li>Secondary Quote</li>
  <li *ngIf="quote.QuoteChildNbr">{{quote.QuoteChildNbr}}</li>
</ol>
<div class="row">&nbsp;</div>
<div class="row">
  <h4>Secondary Quote</h4>
</div>

<section>
  <div class="row" *ngIf="valError">
    <span *ngFor="let item of errors" style="margin-left:5px ;padding: 5px;" class="alert-danger">
      {{item}}
    </span>
  </div>
  <div class="row">
    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Name">CLIENT</label>
      <div class="col-md-3">
        <client-autocomplete [clientId]="quote.ClientId" (clientChange)="parseClient($event);checkStampduty($event)" [isForTrading]="true" class="col-md-12">
        </client-autocomplete>
      </div>
    </div>

    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Sides">Side</label>
      <div class="col-md-3">
        <select required name="Side" #refSide="ngModel" class="form-control" [(ngModel)]="quote.Side">
          <!--<option value="undefined">Please select Value</option>-->
          <option *ngFor="let c of sides" [ngValue]="c.name">
            {{c.name}}
          </option>
        </select>
      </div>
      <br><br>
    </div>
  </div>

  <div class="row">

    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Name">ISIN</label>
      <div class="col-md-3">

        <product-autocomplete required [(productId)]="quote.ProductId" (productChange)="parseProd($event);checkStampduty($event)"
                              (isinChange)="parseIsin($event)" class="col-md-12">
        </product-autocomplete>
      </div>
    </div>
    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Name">Issuer</label>
      <div class="col-md-3">
        <code-value [(code)]="quote.IssuerCode" [disabled]="disabledControls" [type]="'Issuer'">
        </code-value>
      </div>
    </div>
  </div>

  <div class="row">

    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Name">Currency</label>
      <div class="col-md-3">
        <code-value [(code)]="quote.CurrencyCode" [disabled]="disabledControls" [type]="'Currency'">
        </code-value>
      </div>
    </div>
    <div class="form-group form-group-sm">
      <label class="col-md-1" for="Name">Size</label>
      <div class="col-md-3">
        <size-input [(size)]="quote.Size"></size-input>
      </div>
    </div>
    <!--
    <div style="margin-top: 10px;" class="col-md-2">{{ quote.SizeValue }}</div> -->

  </div>



  <div class="row">&nbsp;</div>
  <div class="row">
    <div class="form-group">
      <label class="col-md-1" for="Active"><input type="checkbox" [(ngModel)]="quote.UssBroker" />&nbsp;USS Broker</label>
    </div>
  </div>
  <div class="row">&nbsp;</div>
  <div class="row">&nbsp;</div>

  <div class="row ">
    <label class="col-md-1">Comments</label>
    <div class="col-md-7">
      <textarea class="form-control" id="comment" rows="3" [(ngModel)]="quote.Comment"></textarea>
    </div>
  </div>
  <div class="row">&nbsp;</div>
  <div class="row">&nbsp;</div>
  <div class="row">&nbsp;</div>
  <div class="row" style="text-align: center; color: red; font-size: x-large; font-weight: bold;" *ngIf="isStampDuty">WARNING - THIS DEAL MAY BE SUBJECT TO STAMP DUTY</div>
  <div class="row">&nbsp;</div>

  <div style="text-align:-webkit-center;" class="col-md-8">
    <div *ngIf="quote.Product && quote.Product.NplDescription && quote.Product.NplDescription != ''"
         style="text-align:-webkit-center; width: 600px; padding:10px; border: 2px solid #383737; background: #f3f3f3;">
      <div [innerHTML]="quote.Product.NplDescription"></div>
    </div>
  </div>
</section>

<div class="footer">
  <div class="command">
      <div class="form-group col-md-12" style="text-align: right;">
        <button id="Rqst" [disabled]="isSaving" type="button" class="btn btn-primary" (click)="sendRequest()">Send Request to trading</button>
      </div>
  </div>
</div>
