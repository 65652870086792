<div class="container">
  <h3 mat-dialog-title>Add new pricings</h3>

  <form (ngSubmit)="submit" #formControl="ngForm">
    <div class="col-md-12">
      <mat-form-field class="col-md-12">
        <input type="text" placeholder="Select Issuers" aria-label="Select Issuers" matInput [matAutocomplete]="auto" [formControl]="userControl">
        <mat-hint>Enter text to find issuers by name</mat-hint>
      </mat-form-field>

      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
        <mat-option *ngFor="let issuer of filteredIssuers | async" [value]="selectedIssuers">
          <div (click)="toggleSelection(issuer,true,$event)">
            <input type="checkbox" [(ngModel)]="issuer.Selected" (change)="toggleSelection(issuer,false,$event)" (click)="$event.stopPropagation()" [ngModelOptions]="{standalone: true}" />
            <span>{{ issuer.Description }}</span>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>
    <br>
    <br>
    <!--<div class="col-md-6">
      <label>Selected Issuers:</label>
      <mat-list dense>
        <mat-list-item *ngIf="selectedIssuers?.length === 0">(None)</mat-list-item>
        <mat-list-item *ngFor="let issuer of selectedIssuers">
          {{ issuer.Description }}
        </mat-list-item>
      </mat-list>
    </div>-->
    <!--<pre>{{data.issuers.length}}</pre>-->
    <div class="col-md-12">
      <div mat-dialog-actions>
        <button class="button" [type]="submit" [disabled]="!formControl.valid" [mat-dialog-close]="1" (click)="confirmAdd()">Save</button>
        <button class="button" (click)="onNoClick()" tabindex="-1">Cancel</button>
      </div>
    </div>
  </form>

</div>
