<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<ol class="breadcrumb row">
  <li><a (click)="goToHome()">Home</a></li>
  <li><a (click)="goToProducts()">Products</a></li>
  <li>{{isin}} - {{productName}} </li>
</ol>
<br />
<div class="row" *ngIf="valError">
  <span *ngFor="let item of errors" style="margin-left:5px ;padding: 5px;" class="alert-danger">
    {{item}}
  </span>
</div>
<h4>Order allocation</h4>

<div class="bloc">
  <div class="{{group=='sales' || IsExternal || group=='super-user'  ? 'extended-bloc-extra' :'extended-bloc'}}">

    <div *ngFor="let deal of primaryQuote.deals; let i = index">
      <div class="row">
        <div class="form-group form-group-sm">
          <label class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}" for="Name">CLIENT</label>
        </div>

        <div class="form-group form-group-sm ">
          <label class="col-md-2 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}" for="Sides">Quantity</label>
        </div>
        <div class="form-group form-group-sm ">
          <label class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}"></label>
          <label class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}">Percent/Unit</label>
        </div>
        <div class="form-group form-group-sm ">

          <label class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}" for="ussPrice">USS price</label>
        </div>


        <div class="form-group form-group-sm ">
          <label class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}" for="Sides">Client price</label>
        </div>

        <div class="form-group form-group-sm ">
          <label class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}" for="Sides">Settlement price</label>
        </div>

        <div *ngIf="(group=='sales' && IsExternal) || group=='super-user' " class="form-group form-group-sm  ">
          <label class="col-md-1 {{(group=='sales' && IsExternal) || group=='super-user'  ? 'col-bloc-extra' :''}}">Client Reference 1</label>
        </div>
        <div *ngIf="(group=='sales' && IsExternal) || group=='super-user'" class="form-group form-group-sm  ">
          <label class="col-md-1 {{(group=='sales' && IsExternal)|| group=='super-user'  ? 'col-bloc-extra' :''}}">Client Reference 2</label>
        </div>



      </div>
      <div class="row">
        <div class="form-group form-group-sm ">
          <div class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}">
            <client-autocomplete (clientChange)="parseDealClient($event, i)" [clientId]="deal.ClientId" [onBehalfSales]="onBehalfSales" [isForTrading]="true" class="col-md-12">
            </client-autocomplete>
          </div>
        </div>

        <div class="form-group form-group-sm ">
          <div class="col-md-2 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}">
            <size-input class="{{group=='sales' || IsExternal || group=='super-user'  ? 'adj-width' :''}}" [(size)]="deal.Quantity"></size-input>

          </div>

        </div>


        <div class="form-group form-group-sm  ">
          <div class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}"></div>
          <div class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}">
            {{primaryQuote.QuotationTypeCode}}
          </div>

        </div>

        <div class="form-group form-group-sm">

          <div class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}} ">
            <input class="{{group=='sales' || IsExternal || group=='super-user'  ? 'adj-width' :''}}" type="number" [disabled]="primaryQuote.ProductType != 'producttype.otc-warrant'"
                   [(ngModel)]="deal.FairPrice" step="0.01" required />
          </div>


        </div>


        <div class="form-group form-group-sm ">
          <div class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}} ">
            <input class="{{group=='sales' || IsExternal || group=='super-user'  ? 'adj-width' :''}}" type="number" [disabled]="true"
                   [(ngModel)]="deal.ClientPrice" step="0.01" required />
          </div>


        </div>
        <div class="form-group form-group-sm ">
          <div class="col-md-1 {{group=='sales' || IsExternal || group=='super-user'  ? 'col-bloc-extra' :''}}">
            <input class="{{group=='sales' || IsExternal || group=='super-user'  ? 'adj-width' :''}}" type="number" [disabled]="primaryQuote.ProductType != 'producttype.otc-warrant'"
                   [(ngModel)]="deal.Settlement" step="0.01" required />
          </div>
        </div>
        <div *ngIf="(group=='sales' && IsExternal) || group=='super-user' " class="form-group form-group-sm ">
          <div class="col-md-1 {{(group=='sales' && IsExternal) || group=='super-user'  ? 'col-bloc-extra' :''}}">
            <input class="{{(group=='sales' && IsExternal) || group=='super-user'  ? 'adj-width' :''}}" type="text"
                   [ngModel]="clientRef1.get(deal.DealNbr)" (ngModelChange)="clientRef1.set(deal.DealNbr,$event)"/>
          </div>
        </div>
        <div *ngIf="(group=='sales' && IsExternal) || group=='super-user' " class="form-group form-group-sm ">
          <div class="col-md-1 {{(group=='sales' && IsExternal) || group=='super-user'  ? 'col-bloc-extra ' :''}}">
            <input class="{{(group=='sales' && IsExternal) || group=='super-user'  ? 'adj-width' :''}}" type="text"
                   [ngModel]="clientRef2.get(deal.DealNbr)" (ngModelChange)="clientRef2.set(deal.DealNbr,$event)" />
          </div>
        </div>




      </div>
    </div>

  </div>
</div>

<!-- 2nd part -Marges-->
<div class="bloc">

  <div class="extended-bloc">
    <div *ngFor="let deal of primaryQuote.deals; let i = index ">
      <h4>{{deal.ClientCode}}</h4>

      <div class="bloc">

        <div class="extended-bloc scroll">
          <div *ngFor="let margin of deal.Margins; let m = index">
            <div class="row">
              <div class="form-group form-group-sm">
                <div class="col-md-2">
                </div>
              </div>

              <div class="form-group form-group-sm">
                <label class="col-md-2">
                  Fees

                </label>

              </div>
            </div>
            <div class="row">
              <div class="form-group form-group-sm">
                <div class="col-md-2">
                  <client-autocomplete [clientId]="margin.ClientId" [onBehalfSales]="onBehalfSales" (clientChange)="parseMarginClient($event,i,m)">
                  </client-autocomplete>
                </div>
              </div>

              <div class="form-group form-group-sm">
                <div class="col-md-2">
                  <input type="number" [disabled]=""
                         [(ngModel)]="margin.Fees" step="0.01" required />
                </div>

              </div>
              <div class="form-group form-group-sm">
                <div *ngIf="m > 0" class="delete-link"><i style=" margin-left: 40px;" class="glyphicon glyphicon-remove" (click)="removeMargin(deal,m)"></i></div>
              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="dynamic-link" [class.disabled]="" title="Add New Line" (click)="addMargin(deal)">
                <i class="far fa-plus-circle"></i>
                <span class="dynamic-link-span">Add New Line</span>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>

  </div>
</div>

<!--3rd part Custodians-->
<div class="bloc">

  <div class="extended-bloc">
    <div *ngFor="let deal of primaryQuote.deals; let i = index">
      <h4>{{deal.ClientCode}}</h4>

      <div class="bloc">
        <div class="extended-bloc">

          <div class="row">
            <div class="form-group form-group-sm">
              <label class="col-md-1" for="Name">Custo</label>
              <!--<div class="col-md-1">
              </div>-->
              <label class="col-md-1" for="Active"><input type="checkbox" (change)="onChangeTbd(false,deal)" [(ngModel)]="deal.TBD" />&nbsp;TBD</label>

              <div class="col-md-1">
                <label for="Name">Size</label>

              </div>
              <label class="col-md-1" for="Active"><input type="checkbox" [(ngModel)]="deal.IsTBD" />&nbsp;TBD</label>


            </div>
            <div class="form-group form-group-sm">

              <label class="col-md-1" for="Active">
              </label>

              <div class="col-md-2">
                <label for="Name" class="all-size" style="color:red" *ngIf="!deal.IsEqualSizes && !deal.IsTBD">{{allSizeCusto(deal)}}</label>
                <label *ngIf="deal.IsEqualSizes && !deal.IsTBD" style="color:green" for="Name" class="all-size">{{allSizeCusto(deal)}}</label>
                <label *ngIf="deal.IsTBD" style="color:green" for="Name" class="all-size">TBD</label>

              </div>


            </div>
          </div>
          <div class="row" *ngFor="let c of deal.Custodians; let i = index">
            <div class="form-group form-group-sm">

              <div class="col-md-2">
                <code-value [(code)]="c.CustodianCode" [type]="'Custodian'" (changeCusto)="custoSelect($event, c)">
                </code-value>
              </div>
              <!--<label class="col-md-1"></label>-->

              <div class="col-md-1">
                <label *ngIf="deal.Custodians?.length == i + 1" class="" for="Active"><input type="checkbox" (change)="onChangeSize(false,deal)" [(ngModel)]="deal.ShowAllSize" />&nbsp;All Size</label>


              </div>
              <div class="col-md-1">
                <size-input [(size)]="c.CustodianSize" [label]="deal.IsTBD ? 'TBD' : ''"></size-input>


              </div>
              <div>
                <div *ngIf="c.IsNew" class="delete-link"><i class="glyphicon glyphicon-remove delete-custo" (click)="removeCustodian(deal,i)"></i></div>
              </div>


            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <div class="dynamic-link" [class.disabled]="deal.IsEqualSizes" title="Add New Custodian" (click)="addCusto(deal)">
                <i class="far fa-plus-circle"></i>
                <span class="dynamic-link-span">Add New Custodian</span>
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</div>
<div class="footer">
  <div class="command">
    <div class="form-group col-md-12" style="text-align: right;">
      <button id="Rqst" [ngClass]="{'disabled': clicked }" type="button" class="btn btn-primary" (click)="sendBooking()">Send Booking</button>
    </div>
  </div>
</div>


