<mat-form-field  style="width: 100%;">
    <input #myInput  matInput aria-label="Client" [matAutocomplete]="auto" [formControl]="searchClientCtrl" [readonly]="disabled"  (blur)="onBlur()">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
      <ng-container *ngIf="!isLoading" [disabled]="disabled">
        <mat-option *ngFor="let client of filteredClients" [value]="client.ClientCode" (onSelectionChange)="setClient(client)">
          <span><b>{{client.ClientCode}}</b> </span>
        </mat-option>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
