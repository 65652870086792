import { ProductDto } from './ProductDto.Model';
import { CheckBoxDto } from '../order/CheckBoxDto.Model';


export class SecondaryQuoteDto {
  public ProductId: string;
  public ProductDesc: string;
  public Product: ProductDto;
  public ISIN: string;
  public Currency: any;
  public CurrencyCode: string;
  public CurrencyDesc: string;
  public ClientId: string;
  public ClientCode: string;
  public Side: string;
  public Issuer: any;
  public IssuerCode: string;
  public Size: number;
  public Version: number;
  public SizeValue: string;
  public UssBroker: boolean;
  public SendAuto: boolean;
  public Comment: string;
  public QuoteChildNbr: string;
  public ProductName: string;
  public New: boolean;
  public CloneChild: boolean;
  public ParentQuoteId: string;
  public OrBetterBid?: CheckBoxDto = { Value: false, IsShown: true };
  public AtMarketBid?: CheckBoxDto = { Value: false, IsShown: true };
 
}
