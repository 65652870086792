
<div mat-dialog-content >
  <div *ngIf="isSaving" class="spinner-container">
    <mat-spinner [diameter]="30"></mat-spinner>
    <span class="spinner-message">Save in progress ...</span>
  </div>

  <div *ngIf="!isSaving" class="message-container">
    <div class="alert alert-success" role="alert" *ngIf="!hasError">
      Operation saved with success
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="hasError">
      {{errorMessage}}
    </div>
  </div>
</div>
 
<div mat-dialog-actions *ngIf="!isSaving" align="end">
  <button mat-button mat-dialog-close *ngIf="!isSaving" class="btn btn-primary">Close</button>
</div>




