import { Component, Inject } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static/';
import { AppSettingsService } from './app-settings.service';
import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'dss-front';
  phones: any[] = [];
  window: any;

  constructor(@Inject('Window') win: Window, private upgrade: UpgradeModule, private appSettingsService: AppSettingsService, private adalService: MsAdalAngular6Service, private titleService: Title) {
    this.window = win;
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  init() {
    this.appSettingsService.getAll().subscribe((data: any) => {
      this.window.app = {};
      this.window.appSettings = data;
      this.window.app.assemblyTimestamp = this.window.app.signature = data.AssemblySignature;
      this.window.environnementType = data.EnvironmentType;
      //adding title
      var windowTitle = (this.window.environnementType == "USS") ? "USS Online" : "DSS Online";
      this.setTitle(windowTitle);
      // Microsoft Insights
      var appInsights: any = function (config: any) {
        function s(config) { t[config] = function () { var i = arguments; t.queue.push(function () { t[config].apply(t, i) }) } } var t: any = { config: config }, r = document, f: any = window, e = "script", o: any = r.createElement(e), i, u; for (o.src = config.url || "//az416426.vo.msecnd.net/scripts/a/ai.0.js", r.getElementsByTagName(e)[0].parentNode.appendChild(o), t.cookie = r.cookie, t.queue = [], i = ["Event", "Exception", "Metric", "PageView", "Trace"]; i.length;) s("track" + i.pop()); return config.disableExceptionTracking || (i = "onerror", s("_" + i), u = f[i], f[i] = function (config, r, f, e, o) { var s = u && u(config, r, f, e, o); return s !== !0 && t["_" + i](config, r, f, e, o), s }), t
      }({
        instrumentationKey: data.InsightKey
      });

      this.window.appInsights = appInsights;
      appInsights.trackPageView();

      if (data.Authentication != "Windows") {
        /* renew session all 5 minutes */
        setInterval(function () {
          var renewUrl = "/home/forcesignin";
          var element: any = document.getElementById("renewSession");
          element.src = renewUrl;
        },
          300000
        );
      }
  
      this.upgrade.bootstrap(document.body, ['app']);
    });
    this.appSettingsService.setCookie().subscribe((data: any) => {
      console.log(data);
    });
  }

  ngOnInit() {
    // Retrieve authentication mode (Azure or Windows)
    let self = this;
    //self.init();
    this.appSettingsService.getAuthentication().subscribe((data: any)=>{      
			  if (data.Authentication === "Azure") {
            // Authenticate to Azure
            console.log("AUTHENTICATION TYPE AZURE");
            self.adalService.acquireToken(this.adalService.GetResourceForEndpoint("/api")).subscribe((token: string) => {
              console.log("Authenticated to Azure successful.");
              self.init();
            }, (err: any) => {
                console.error("Unable to acquire token. " + err);
                self.adalService.login();
            });
        } else {
          // Windows Authentication
          console.log("AUTHENTICATION TYPE Windows");
          self.init();
        }        
    });
  }

}
