import { DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'size-input',
  templateUrl: './size-input.component.html',
  styleUrls: ['./size-input.component.scss']
})
export class SizeInputComponent implements OnInit {

  private _size: number;
  private _sizeValue: string;
  @Input() label = '';
  @Input() disabled = false;

  // set sizeValue(val: string) {
  //   if (this._sizeValue != val) {
  //     this.formatValue(val);
  //   }
  // }

  get sizeValue() {
    return this.size ? this.size.toString() : "";
  }

  get sizeFormatted() {
    if (!this.size) {
      return "";
    }

    if (this.size >= 1000000) {
      return String(this.size / 1000000) + " M";
    }
    else if (this.size >= 1000) {
      return String(this.size / 1000) + " K";
    }
    else {
      return String(this.size);
    }
  }


  @Input()
  set size(val: number) {
    if (this._size != val) {
      this._size = val;
      this.sizeChange.emit(val);
    }
  }

  get size() {
    return this._size;
  }

  @Output()
  sizeChange = new EventEmitter<number>();

  charCodeAccepted: number[] = [];
  constructor(private pipe: DecimalPipe) {
    let val = "0123456789.kmKM'";
    for (let index = 0; index < val.length; index++) {
      let element = val.charCodeAt(index);
      this.charCodeAccepted.push(element);
    }
  }

  ngOnInit(): void {
  }

  formatValue(val: string) {
    let value = val.toLowerCase().replace("k", "000").replace("m", "000000").replace("'", "");
    let res = parseFloat(value);
    console.log("input", val, "value", value, "res", res);
    if (res) {
      this.size = res;
    } else {
      this.size = 0;
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    // on check si le caractère entré est accepté
    return this.charCodeAccepted.indexOf(charCode) != -1;
  }


}
