import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { QuoteService } from '../../services/quote.service';
import { PricingObjectDto } from '../PricingObjectDto.Model';
import { CustomCheckBoxDto } from './../CustomCheckBoxDto.Model';


@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  pricingObject: PricingObjectDto = new PricingObjectDto();
  issuers: CustomCheckBoxDto[];
  pricingQuotes: CustomCheckBoxDto[];
  public errors = [];
  valError = false;
  clicked = false;
  buttonClicked = false;
  allIssuersSelected = false;
  allQuotesSelected = false;
  eraseIssuerValues = false;
  eraseQuoteValues = false;



  isin = "";
  productName = "";

  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute, private notifierService: NotifierService, private router: Router, private _chRef: ChangeDetectorRef) {

    this.activatedRoute.params.subscribe(params => {

      let nbr = '';
      nbr = params['nbr'] ? params['nbr'].toString() : '';

      if (nbr) {

        this.quoteService.GetPricingQuotes(nbr).subscribe(r => {
          this.pricingQuotes = r;
        });

        this.quoteService.GetNominalQuote(nbr).subscribe(r => {
          this.pricingObject.Nominal = +r;
        });
      }

    });
  }

  ngOnInit(): void {
    this.issuers = [

      {
        Id: 'Barclays',
        Label: 'Barclays',
        Checked: false,
        Disabled : false,

      },
       {
        Id: 'BNP EU Mail',
        Label: 'BNP EU Mail',
         Checked: false,
         Disabled: false,

      },
      {
        Id: 'BNP EU API',
       Label: 'BNP EU API',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'EFG',
        Label: 'EFG',
        Checked: false,
        Disabled: false,

      },
{
        Id: 'BIL',
        Label: 'BIL',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'BBVA',
        Label: 'BBVA',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'Citi',
        Label: 'Citi',
        Checked: false,
        Disabled: false,
        

      },
      {
        Id: 'Goldman Sachs',
        Label: 'Goldman Sachs',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'HSBC',
        Label: 'HSBC',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'JP Morgan',
        Label: 'JP Morgan',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'JP Morgan CFC',
        Label: 'JP Morgan CFC',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'RBC',
        Label: 'RBC',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'Leonteq',
        Label: 'Leonteq',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'LUKB',
        Label: 'LUKB',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'Marex',
        Label: 'Marex',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'Morgan Stanley',
        Label: 'Morgan Stanley',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'Raiffeisen',
        Label: 'Raiffeisen',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'SG',
        Label: 'SG',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'UBS',
        Label: 'UBS',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'SCB',
        Label: 'SCB',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'Julius Baer',
        Label: 'Julius Baer',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'CIBC',
        Label: 'CIBC',
        Checked: false,
        Disabled: false,

      },
      {
        Id: 'BOFA',
        Label: 'BOFA',
        Checked: false,
        Disabled: false,

      }
    ];

    this.issuers.sort(function (a, b) {
      return compareStrings(a.Label, b.Label);
    });

    this.pricingObject.From = 85;
    this.pricingObject.To = 100;

    function compareStrings(a, b) {
      // Assuming you want case-insensitive comparison
      a = a.toLowerCase();
      b = b.toLowerCase();

      return (a < b) ? -1 : (a > b) ? 1 : 0;
    }
  }



  goToHome() {
    this.router.navigate(['/']);

  }

  goToProducts() {
    this.router.navigate(['/products']);

  }

  allIssuersSelect(event) {
    if (this.allIssuersSelected) {
      
      this.issuers.forEach(obj => {
        if (obj.Id != "BNP US") { 
          obj.Checked = true;
        }
      });
    }
    else {
      this.issuers.forEach(obj => {
        if (obj.Id != "BNP US") {
          obj.Checked = false;
        }
      });
    }

  }

  allQuotesSelect(event) {
    if (this.allQuotesSelected) {
      this.pricingQuotes.forEach(obj => {
        obj.Checked = true;
      });
    }
    else {
      this.pricingQuotes.forEach(obj => {
        obj.Checked = false;
      });
    }

  }

  eraseIssuerValue(event) {
    if (this.eraseIssuerValues) {
      this.issuers.forEach(obj => {
        obj.Checked = false;
      });
    }
  }
  eraseQuoteValue(event) {
    if (this.eraseQuoteValues) {
      this.pricingQuotes.forEach(obj => {
        obj.Checked = false;
      });
    }
  }
  checkValidation() {
    this.valError = false;
    this.errors = [];

    if (!this.pricingQuotes.map(e => e.Checked).includes(true)) {
      this.errors.push('At least one quote must be selected');
      this.valError = true;
    }
    if (!this.issuers.map(e => e.Checked).includes(true)) {
      this.errors.push('At list one issuer must be selected');
      this.valError = true;
    }
  }
  ExecutePricing() {
    this.checkValidation();
    this.pricingObject.From = 0;
    this.pricingObject.To = 0;
    if (!this.valError) {
      this.clicked = true;
      this.pricingObject.issuers = this.issuers.filter(e => e.Checked);
      this.pricingObject.pricingQuotes = this.pricingQuotes.filter(e => e.Checked);

      this.quoteService.ExecutePricing(this.pricingObject).subscribe(c => {
        console.log(c);
        this.notifierService.notify("success", "Pricing executed");
        setTimeout(() => {
          this.router.navigate(['/quoting/list']);
        }, 500);

      },
        e => {
          this.notifierService.notify("error", "Retry later");
        });
    } else {
      this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
      scroll(0, 0);
    }

  }

  checkOptimizerValidation() {
    this.valError = false;
    this.errors = [];

    if ((this.pricingObject.From > this.pricingObject.To) || (this.pricingObject.From == 0 && this.pricingObject.To == 0)) {
      this.errors.push('Invalid values of optimizer');
      this.valError = true;
    }
    if (this.pricingObject.From < 0 || this.pricingObject.From > 100) {
      this.errors.push('From value must be between 0 and 100');
      this.valError = true;
    }
    if (this.pricingObject.To < 0 || this.pricingObject.To > 100) {
      this.errors.push('To value must be between 0 and 100');
      this.valError = true;
    }
  
  }

  ExecuteOptimizedPricer() {
    this.checkOptimizerValidation();
    if (!this.valError) {
      this.checkValidation();

      if (!this.valError) {
        this.buttonClicked = true;
        this.pricingObject.issuers = this.issuers.filter(e => e.Checked);
        this.pricingObject.pricingQuotes = this.pricingQuotes.filter(e => e.Checked);

        this.quoteService.ExecutePricing(this.pricingObject).subscribe(c => {

          this.notifierService.notify("success", "Pricing executed");
          setTimeout(() => {
            this.router.navigate(['/quoting/list']);
          }, 500);

        },
          e => {
            this.notifierService.notify("error", "Retry later");
          });
      } else {
        this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
        scroll(0, 0);
      }
    } else {
      this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
      scroll(0, 0);
    }
  }

}
