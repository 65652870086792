<div>
    <div class="nullable">
      <select class="form-control" (change)="getDescription($event)" required [(ngModel)]="code" [disabled]="disabled">
        <!--<option value="undefined">Please select Value</option>-->
        <option *ngIf="isPQ">
          {{bestIssuer}}
        </option>
        <option *ngFor="let cv of codeValues" [ngValue]="cv.Code">
          {{cv.Description}}
        </option>

      </select>
    </div>
</div>
