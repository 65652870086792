import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DcSyncService {

  constructor(private httpClient: HttpClient) {
  }

  public Ping = (): Observable<any> => {
    return this.httpClient.get('/api/dcsyncadmin/ping');
  }

  public SyncEntity(entity: string): Observable<any> {
    return this.httpClient.post('/api/dcsyncadmin/syncentity/' + entity, null);
  }

}