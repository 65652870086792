import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';
import { QuoteChildIssuerDto } from '../models/QuoteChildIssuerDto';
import { map, share } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { PrimaryQuoteDto } from '../order/PrimaryQuoteDto.Model';
import { UnderlyingIssuerDto } from '../models/UnderlyingIssuerDto';
import { QuoteChildDto } from '../models/QuoteChildDto';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  codeValues: Observable<Array<CodeValueDto>>;
  issuerCodeValues: Observable<Array<CodeValueDto>>;

  constructor(private httpClient: HttpClient) {
  }

  public GetCodeValuesByType(codeType: string): Observable<Array<CodeValueDto>> {
    return this.GetCodeValues().pipe(
      map(result => result.filter(r => r.CodeType == codeType))
    );
  }
  public GetOpenedIssuers(): Observable<Array<CodeValueDto>> {
    return this.GetIssuers().pipe(
      map(result => result.filter(r => r.IsOpen == true))
    );
  }
  public GetAlternativeBrokerIssuers(): Observable<Array<CodeValueDto>> {
    return this.GetIssuers().pipe(
      map(result => result.filter(r => r.AlternativeBroker == true))
    );
  }
  public GetIssuers(): Observable<Array<CodeValueDto>> {
   
    this.issuerCodeValues = this.httpClient.get<any>('/datas/GetIssuerCodeValues').pipe(share());
    return this.issuerCodeValues;
  }
  public GetPricingIssuers(nbr: string): Observable<Array<CodeValueDto>> {
    let datas = new HttpParams().set('nbr', nbr);

    this.issuerCodeValues = this.httpClient.get<any>('/datas/GetPricingIssuers', { params: datas }).pipe(share());
    return this.issuerCodeValues;
  }

  public GetQuoteChildId(nbr: string): Observable<PrimaryQuoteDto> {
    let datas = new HttpParams().set('nbr', nbr);

    let result = this.httpClient.get<any>('/datas/GetQuoteChildId', { params: datas }).pipe(share());
    return result;
  }

  public GetCodeValues(): Observable<Array<CodeValueDto>> {
    if (this.codeValues) {
      return this.codeValues;
    }
    this.codeValues = this.httpClient.get<any>('/datas/GetCodeValues').pipe(share());
    return this.codeValues;
  }

//*******************************************************Blotter************************************************************************

  dataChange: BehaviorSubject<QuoteChildIssuerDto[]> = new BehaviorSubject<QuoteChildIssuerDto[]>([]);
  dataUIChange: BehaviorSubject<UnderlyingIssuerDto[]> = new BehaviorSubject<UnderlyingIssuerDto[]>([]);
  dialogData: any;
  dialogDataUI: any;

  hideSidebar = new Subject<{}>();



  get data(): QuoteChildIssuerDto[] {
    return this.dataChange.value;
  }

  getDialogData() {
    return this.dialogData;
  }

  addPrice(quoteChildIssuerDto: QuoteChildIssuerDto): void {
    this.dialogData = quoteChildIssuerDto;
  }

  addBatchPrice(quoteChildIssuerDtos: QuoteChildIssuerDto[]): void {
    this.dialogData = quoteChildIssuerDtos;
  }

  updatePrice(quoteChildIssuerDto: QuoteChildIssuerDto): void {
    this.dialogData = quoteChildIssuerDto;
  }

  updateDescription(quoteChildDto: QuoteChildDto): void {
    this.dialogData = quoteChildDto;
  }

  deletePrice(quoteChildIssuerDto: QuoteChildIssuerDto): void {
    this.dialogData = quoteChildIssuerDto;
  }




  get dataUI(): UnderlyingIssuerDto[] {
    return this.dataUIChange.value;
  }

  getDialogDataUI() {
    return this.dialogDataUI;
  }
  addUnderlyingIssuer(underlyingIssuerDto: UnderlyingIssuerDto): void {
    this.dialogData = underlyingIssuerDto;
  }

  updateUnderlyingIssuer(underlyingIssuerDto: UnderlyingIssuerDto): void {
    this.dialogData = underlyingIssuerDto;
  }

  deleteUnderlyingIssuer(underlyingIssuerDto: UnderlyingIssuerDto): void {
    this.dialogData = underlyingIssuerDto;
  }

}
