import { Component, OnInit, ViewChild} from '@angular/core';
import { QuoteService } from '../services/quote.service';
import { NotifierService } from 'angular-notifier';
import { MatTableDataSource } from '@angular/material/table';
import { BookingDto } from 'src/app/models/BookingDto';
import { MatPaginator } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';


export interface DataOfFootballers {
  playerName: string;
  playerCountry: string;
  playerClub: string;
}

@Component({
  selector: 'app-logbook',
  templateUrl: './logbook.component.html',
  styleUrls: ['./logbook.component.scss']
})
export class LogbookComponent  implements OnInit  {

  constructor(private quoteService: QuoteService, private notifierService: NotifierService) {
    this.quoteService.GetAllCurrency().subscribe(data => this.currencies = data,
      error => console.log(error),
      () => console.log('Get all currencies complete'));
  }

  dataSource: MatTableDataSource<BookingDto>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  bookings: BookingDto[];
  currencies: CodeValueDto[];


  idFilter = new FormControl();
  nameFilter = new FormControl();
  isinFilter = new FormControl();
  accountFilter = new FormControl();
  dateFilter = new FormControl();
  currencyFilter = new FormControl();
  typeFilter = new FormControl();
  sideFilter = new FormControl();
  counterpartFilter = new FormControl();

  filteredValues = {
    id: '',
    name: '',
    isin: '',
    account: '',
    date: '',
    currency: '',
    type: '',
    side: '',
    counterpart: ''
  };

  ngOnInit(): void {

    this.quoteService.GetAllBookings().subscribe(data => {
      this.bookings = data;
      this.dataSource = new MatTableDataSource(this.bookings);
      this.dataSource.paginator = this.paginator;

      //this.dataSource.filterPredicate = (data, filter) => {
      //  return data.Id.indexOf(filter) != -1;
      //}

        this.idFilter.valueChanges.subscribe((idFilterValue) => {
          this.filteredValues['id'] = idFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

        this.nameFilter.valueChanges.subscribe((nameFilterValue) => {
          this.filteredValues['name'] = nameFilterValue;
          this.dataSource.filter = JSON.stringify(this.filteredValues);
        });

      this.accountFilter.valueChanges.subscribe((accountFilterValue) => {
        this.filteredValues['account'] = accountFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });


      this.isinFilter.valueChanges.subscribe((isinFilterValue) => {
        this.filteredValues['isin'] = isinFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });


      this.dateFilter.valueChanges.subscribe((dateFilterValue) => {
        this.filteredValues['date'] = dateFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });


      this.currencyFilter.valueChanges.subscribe((currencyFilterValue) => {
        this.filteredValues['currency'] = currencyFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });


      this.typeFilter.valueChanges.subscribe((typeFilterValue) => {
        this.filteredValues['type'] = typeFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });


      this.sideFilter.valueChanges.subscribe((sideFilterValue) => {
        this.filteredValues['side'] = sideFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });


      this.counterpartFilter.valueChanges.subscribe((counterpartFilterValue) => {
        this.filteredValues['counterpart'] = counterpartFilterValue;
        this.dataSource.filter = JSON.stringify(this.filteredValues);
      });
      this.dataSource.filterPredicate = this.customFilterPredicate();
    },
      error => this.processError(error),
      () => console.log('Get all bookings complete'));
  }

  displayedColumns: string[] = ['Id', 'SecurityType', 'ISIN', 'Security', 'Name', 'Ticker', 'PrincipalAgency', 'OrderReceptionDate', 'OrderReceptionTime', 'Status', 'Account', 'Sell', 'OpenCLose', 'NatureOfTransaction', 'OrderType', 'OrderQuantity', 'TIF', 'Type', 'LimitPrice', 'Strike', 'Currency', 'ReceptionChannel', 'ExecutionDate', 'ExecutionTime', 'ExecutionQuantity', 'Market', 'Originator', 'Trader', 'BrokerBank', 'Counterparty', 'BeneficialOwner', 'ValueDate', 'PercentUnit', 'Comment', 'ExecutionPrice', 'ClientPrice', 'SettlementPrice', 'CFICode'];


  //applyFilter(filterValue: string) {
  //  this.dataSource.filterPredicate = boolean {
  //    return this.bookings.Name.toLowerCase().includes(filter) ;
  //  };
  //}


  //applyFilter(filterValue: string) {
  //  //const filterValue = (event.target as HTMLInputElement).value;
  //  this.dataSource.filter = filterValue.trim().toLowerCase();
  //  console.log(this.dataSource.filteredData);
  //}

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue;
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: BookingDto, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let nameFound = data.Name.toString().trim().toLowerCase().indexOf(searchString.name.toLowerCase()) !== -1
      let isinFound = data.ISIN.toString().trim().toLowerCase().indexOf(searchString.isin.toLowerCase()) !== -1
      let accountFound = data.Account.toString().trim().toLowerCase().indexOf(searchString.account.toLowerCase()) !== -1
      let currencyFound = data.Currency.toString().trim().toLowerCase().indexOf(searchString.currency.toLowerCase()) !== -1
      let dateFound = data.ExecutionDate.toString().trim().toLowerCase().indexOf(searchString.date.toLowerCase()) !== -1
      let typeFound = data.Type.toString().trim().toLowerCase().indexOf(searchString.type.toLowerCase()) !== -1
      let sideFound = data.Sell.toString().trim().toLowerCase().indexOf(searchString.side.toLowerCase()) !== -1
      let counterpartFound = data.Counterparty.toString().trim().toLowerCase().indexOf(searchString.counterpart.toLowerCase()) !== -1
      let idFound = data.Id.toString().trim().toLowerCase().indexOf(searchString.id.toLowerCase()) !== -1
      if (searchString.topFilter) {
        return nameFound || idFound || isinFound || accountFound || typeFound || counterpartFound || sideFound || dateFound || currencyFound
      } else {
        return nameFound && idFound && isinFound && accountFound && typeFound && counterpartFound && sideFound && dateFound && currencyFound
      }
    }
    return myFilterPredicate;
  }

  private processError(e: any) {
    console.log(e);

    if (e.status == 401 || e.status == 403) {
      this.notifierService.notify("warning", "You are not authorized to do this action.");
    } else {
      let message = "Unexpected error";
      if (e.error && e.error.Message) {
        message = e.error.Message;
      } else if (e.error) {
        message = e.error;
      } else if (e.status) {
        message += " (" + e.status + ") ";
      }

      console.log(message);
      this.notifierService.notify("error", message);
    }
  }
}
