import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FileInputComponent } from '../file-input/file-input.component';
import { FileDto } from '../file-input/FileDto.Model';
import { CategoryDto } from './CategoryDto.Model'

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, AfterViewInit {

  @Input()
  category: CategoryDto = new CategoryDto();
  @Input() group: string = "";
  @Input() isSuperUser: boolean;
  @Input() index: number;

  @ViewChild('childComp') fileInput: FileInputComponent;



  constructor(private _chRef: ChangeDetectorRef) { }

  ngOnInit(): void {

    if (this.category.Files == null) {

      this.category.Files = [];
      this.category.Files.push(new FileDto());

    }

  }
  ngAfterViewInit() {
    this.category.Files.forEach(file => {
      this.fileInput.showFileName(file);

    });

  }
  ngDoCheck() {

    //this.category.IsComplete = this.category.Support != null && this.category.Files[0].file != null;

  }

  addNewLine() {
    let lastIndex = this.category.Files.length - 1;
    if (this.category.Files[lastIndex].file != null) {
      this.category.Files.push(new FileDto());

    }


  }
  hasEmptyLine() {
    return this.category.Files.some(x => !x.IsDeleted && x.file == null)
  }

  downloadFile(file) {
    if (file != null) {
      this.changeFile(file).then((base64: string): any => {
        base64 = base64.slice(base64.lastIndexOf('base64,') + 7);
        this.downloadBase64File(file.type, base64, file.name);

      });

    }
  }

  changeFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  isComplete(c: CategoryDto) {
    c.IsComplete = ((c.Support != null && (c.Files.find(e => e.content != null && !e.IsDeleted) != null)) || ((c.Support == 'channel.phone') && (c.Code == '1' || c.Code == '4')) || ((c.Support == 'channel.am') && (c.Code == '1' || c.Code == '4')) || ((c.Support == 'channel.externalBroker') && (c.Code == '5')));
    return c.IsComplete ? "4px solid green" : "4px solid red";
  }

  fileOnChange(event, file: FileDto) {
    if (event != null) {
      file.file = event;
      let extension = event.name.split('.').pop();
      if (extension == 'msg') {
        this.category.Support = 'channel.mail';
      } else if (extension == "png" || extension == "jpeg" || extension == "jpg") {
        this.category.Support = 'channel.bloomberg';
      }

      this.changeFile(file.file).then((base64: string): any => {
        base64 = base64.slice(base64.lastIndexOf('base64,') + 7);
        file.content = base64;
        file.Name = event.name;
        file.Type = event.type;

      });
    } else {
      file = null;
    }



  }

  deleteFile(file: FileDto, disabled: boolean) {
    if (!disabled) {
      if (file != null && file.Id != null) {
        file.IsDeleted = true;
        this.addNewLine();
      } else if (this.category.Files.length > 1) {
        this.category.Files.splice(this.category.Files.indexOf(file), 1);
      } else {
        file.file = null;
        file.content = null;
        file.Name = null;
        file.Id = null;
        file.Type = null;
        this.fileInput.showFileName(file);
      }

    }

  }

  downloadBase64File(contentType, base64Data, fileName) {
    const linkSource = `data:${contentType};base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  bgColor(index: string) {
    return index == '1' || index == '4' ? 'rgb(255, 204, 203)' : 'rgb(224, 255, 255)'
  }
  isSales() {
    return (this.group == 'sales' || this.group == 'middle-office') ? true : false;
  }
  isDisabled(index: string) {
    return ((this.group == 'sales' || this.group == 'middle-office') && (index == '2' || index == '3' || index == '5')) || ((this.group == 'trader') && (index == '1' || index == '4')) ? true : false
  }


}
