import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { ProductDto } from '../quoting/ProductDto.Model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient) {
  }

  public GetAllProduct = (): Observable<any> => {
    return this.httpClient.get('/api/product/GetProductById');
  }

  public GetProductById(id: string): Observable<ProductDto> {
    let datas = new HttpParams().set('id', id);
    return this.httpClient.get<ProductDto>('/api/product/GetProductById', { params: datas });
  }

  public GetProductsByIsin(isin: string): Observable<any> {
    let datas = new HttpParams().set('isin', isin).set('take', "25");
    return this.httpClient.get<Array<ProductDto>>('/api/product/GetProductsByIsin', { params: datas });
  }
  public GetDeDraftSecondaryProductsByIsin(isin: string): Observable<any> {
    let datas = new HttpParams().set('isin', isin).set('take', "25");
    return this.httpClient.get<Array<ProductDto>>('/api/product/GetDeDraftSecondaryProductsByIsin', { params: datas });
  }
  
}
