import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../services/data.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-addunderlyingissuer',
  templateUrl: './addunderlyingissuer.component.html',
  styleUrls: ['./addunderlyingissuer.component.scss']
})
export class AddunderlyingissuerComponent implements OnInit {

  @ViewChild('inputsolvefor', { static: false }) inputEl: ElementRef;

  constructor(public dialogRef: MatDialogRef<AddunderlyingissuerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public dataService: DataService
  ) { }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {
    this.dataService.addUnderlyingIssuer(this.data);
  }

  ngOnInit(): void {
    setTimeout(() => {
    //  this.inputEl.nativeElement.focus();
    }, 100)

  }

  //ngAfterViewInit() {
  //  setTimeout(() => this.inputEl.nativeElement.focus());
  //}
  onBlur(event) {
    this.inputEl.nativeElement.focus();
  }
}
