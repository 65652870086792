
import { CustomCheckBoxDto } from './CustomCheckBoxDto.Model';

export class PricingObjectDto {
 

  public issuers: CustomCheckBoxDto[] = [];
  public pricingQuotes: CustomCheckBoxDto[] = [];
  public From: number = 0;
  public To: number = 0;
  public Nominal: number = 0;

}
