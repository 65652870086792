import { Component, OnInit } from '@angular/core';
import { InstrumentService } from '../services/instrument.service';
import { ECBCurrencyDto } from '../models/ECBCurrencyDto';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

  dateList: Array<Date> = [];
  dateSelected: Date;
  prices: Array<ECBCurrencyDto>;
  dateFrom: Date = new Date();
  dateTo: Date = new Date();

  constructor(private instrumentService: InstrumentService, private notifierService: NotifierService) {

  }

  ngOnInit(): void {
    let date: Date = new Date();
    this.instrumentService.getECBCurrencyDate().subscribe(res => {
      if (res && res.length) {
        res.forEach(element => {
          this.dateList.push(element);
        });
        this.dateSelected = this.dateList[0];
        this.getPrice(this.dateSelected);
      }

    });
  }

  getPrice(date: Date) {
    this.instrumentService.getECBCurrencyPrice(date).subscribe(c => this.prices = c);
  }

  copy() {
    this.instrumentService.copyECBCurrency(this.dateFrom, this.dateTo).subscribe(c => this.notifierService.notify("success", c),
      e => this.notifierService.notify("error", e));;
  }

}
