import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../services/data.service';

@Component({
  selector: 'app-deleteunderlyingissuer',
  templateUrl: './deleteunderlyingissuer.component.html',
  styleUrls: ['./deleteunderlyingissuer.component.scss']
})
export class DeleteunderlyingissuerComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteunderlyingissuerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public dataService: DataService
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
  submit() {
  }
  confirmDelete(): void {
    this.dataService.deleteUnderlyingIssuer(this.data);
  }

  ngOnInit(): void {
  }

}
