

export class FileDto {
 

  public file: File;
  public content?: string;
  public Id: string;
  public Type: string;
  public Name: string;
  public IsDeleted: boolean;


}
