<div>
  <table style="width: 100%;">
    <thead>
      <tr>
        <th style="width:  3%;"></th>
        <th style="width: 10%;">Quote</th>
        <th style="width: 10%;">Isin</th>
        <th style="width: 10%;">Client</th>
        <th style="width: 10%;">Issuer</th>
        <th style="width: 5%;">Side</th>
        <th style="width: 10%;">Size</th>
        <th style="width: 3%;">Currency</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of quotes;let even = even; let odd = odd">
        <tr [ngClass]="{ odd: odd, even: even } ">
          <td><button class="btn btn-xs btn-info" style="font-weight: 900;width: 20px;"
              (click)="item.ShowChilds=!item.ShowChilds">{{(item.ShowChilds?'-':'+')}}</button></td>
          <td>
            {{item.QuoteNbr}}
          </td>
          <td>
            {{item.ISIN}}
          </td>
          <td colspan="5"></td>
        </tr>
        <ng-container *ngIf="item.ShowChilds">
          <tr *ngFor="let child of item.QuoteChilds;" [ngClass]="{ odd: odd, even: even } ">
            <td></td>
            <td colspan="2"><a [routerLink]="['/quoting/rfq', child.QuoteChildNbr]">{{child.QuoteChildNbr}}</a></td>
            <td>
              {{child.Client}}
            </td>
            <td>
              {{child.Issuer}}
            </td>
            <td>
              {{child.Side}}
            </td>
            <td>
              {{child.Size}}
            </td>
            <td>
              {{item.Currency}}
            </td>
          </tr>
        </ng-container>
      </ng-container>
    </tbody>
  </table>
</div>