
<div class="container">
  <h3 mat-dialog-title>Edit Quote Description</h3>

  <form (ngSubmit)="submit" #formControl="ngForm">

    <div class="row"></div>
    <div class="row">
      <div class="form-group form-group-sm">
        <label class="col-md-1">Description</label>
        <div class="col-md-7">
          <textarea class="form-control" [(ngModel)]="data.quoteChild.Description2" id="desc" rows="20" name="desc"></textarea>
        </div>
      </div>

    </div>
    <div mat-dialog-actions>
      <button class="btn-primary" [type]="submit" [mat-dialog-close]="1" (click)="stopEdit()" style="width: 100px; margin-right: 10px;">Save</button>
      <button class="btn-primary" (click)="onNoClick()" tabindex="-1" style="width: 100px; ">Cancel</button>
    </div>
  </form>
</div>
