<ejs-sidebar id="default-sidebar" #sidebar (open)="open($event)" (close)="close($event)" [hidden]="hide">
  <div id="sidebar-wrapper" [ngClass]="getMainClass()"> <!--class="no-v-scroll"-->
    <span id="sidebar-env-name" style=" font-size:28px;color:whitesmoke">
      {{ (settings) ? settings.EnvironmentType : "" }}
      <!--DSS-->
    </span>
    <div class="panel panel-default">
      <div class="panel-heading">
        <h4 class="panel-title">
          <a ui-sref="home" routerLink="/">
            <span ng-class="{'text-muted': isDisabled}" class="ng-binding">Home</span>

          </a>
        </h4>
      </div>
    </div>
    <ngb-accordion [closeOthers]="true" activeIds="static-1" *ngIf="settings && userRights">
      <ngb-panel id="sidebar-menu-1" title="Structured Bookings">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.Edit && (userRights.Sales || userRights.SalesAllClients || userRights.MiddleOffice || userRights.Trader)">
            <a routerLink="/deals/structured/booking/">New Trade</a><br />
          </div>
          <a routerLink="/structured/bookings/">Trades list</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-2" title="Open bookings" *ngIf="settings.IsDss || settings.IsUss">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.Edit && (userRights.Sales || userRights.SalesAllClients || userRights.MiddleOffice || userRights.Trader)">
            <a routerLink="/deals/open/booking/">New Trade</a><br />
          </div>
          <a routerLink="/open/bookings/">Trades list</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-3" title="Future &amp; opt. bookings" *ngIf="settings.IsDss || settings.IsUss">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.Edit && (userRights.Sales || userRights.SalesAllClients || userRights.MiddleOffice || userRights.Trader)">
            <a routerLink="/deals/future/booking/">New Trade</a><br />
          </div>
          <a routerLink="/future/bookings/">Trades list</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-4" title="Equities bookings" *ngIf="settings.IsDss || settings.IsUss">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.Edit && (userRights.Sales || userRights.SalesAllClients || userRights.MiddleOffice || userRights.Trader)">
            <a routerLink="/deals/equities/booking/">New Trade</a><br />
          </div>
          <a routerLink="/equities/bookings/">Trades list</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-5" title="Mutual Fund bookings" *ngIf="settings.IsDss || settings.IsUss">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.Edit && (userRights.Sales || userRights.SalesAllClients || userRights.MiddleOffice || userRights.Trader)">
            <a routerLink="/deals/mutualfunds/booking/">New Trade</a><br />
          </div>
          <a routerLink="/mutualfunds/bookings/">Trades list</a>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="sidebar-menu-6" title="Quotings" *ngIf="(userRights.Sales || userRights.SuperUser || userRights.DealManager || userRights.Trader || userRights.MiddleOffice || userRights.ComplianceRisk)">
        <ng-template ngbPanelContent>
          <!--<div>
        <a routerLink="/quoting/all/">Secondary RFQ List</a><br />
      </div>-->
          <div *ngIf="!userRights.MiddleOffice && !userRights.ComplianceRisk">
            <a routerLink="/quoting/rfq/">New Secondary RFQ</a><br />
          </div>
          <div *ngIf="!userRights.MiddleOffice && !userRights.ComplianceRisk">
            <a routerLink="/productdraftedit/">New Primary RFQ</a><br />
          </div>
          <div *ngIf="(userRights.Sales || userRights.SuperUser)">
            <a routerLink="/order/">Send Secondary Order</a><br />
          </div>
          <div>
            <a routerLink="quoting/list/">Blotter</a>
            <span *ngIf="(userRights.SuperUser || userRights.Trader) && numberQuoteChildSent > 0" style="background-color: red; color: white; font-weight: bold; border-radius: 30px; box-shadow: 1px 1px 1px gray; padding: 2px 3px 2px 4px; top: -6px; right: -6px; font-size: 1em; margin-left: 4px;" title="there is {{numberQuoteChildSent}} QuoteChilds with Status Order Sent">{{numberQuoteChildSent}}</span>
            <br />
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="sidebar-menu-7" title="Notifs & Valuation" *ngIf="userRights.ComplianceRisk || userRights.Accountant  || userRights.MiddleOffice || userRights.NotificationsAndValuations || userRights.Sales || userRights.SalesAllClients || userRights.Trader">
        <ng-template ngbPanelContent>
          <a routerLink="/deal-notifications/">Notifications</a><br />
          <a routerLink="/valuations/">Valuations</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-8" title="Products" *ngIf="userRights.ComplianceRisk || userRights.Accountant || userRights.MiddleOffice || userRights.Products || userRights.ProductManager || userRights.Sales || userRights.SalesAllClients || userRights.Trader">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.ProductManager || userRights.ComplianceRisk || userRights.MiddleOffice || userRights.Trader || userRights.Accountant">
            <a routerLink="/productedit/">New product</a><br />
          </div>
          <a routerLink="/products/">Products list</a><br />
          <a routerLink="/products-equities/">Products equities list</a><br />
          <a routerLink="/products-mutualfunds/">Products mutual funds list</a><br />
          <a routerLink="/instruments-underlyings/">Underlying quotes</a><br />
          <a routerLink="/instruments-products/">Product quotes</a><br />
          <a id="instrumentspricing" routerLink="/instrumentspricing/">Instruments Pricing</a><br />

        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-8a" title="Banks" *ngIf="userRights.Trader">
        <ng-template ngbPanelContent>
          <a routerLink="/invoices/">Invoices</a><br />
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-8b" title="Banks" *ngIf="userRights.Accountant || ((userRights.HasNoGroup || !userRights.Trader) && (userRights.InvoiceManager || userRights.UserBeListHasValue || userRights.UserIssuersListHasValue))">
        <ng-template ngbPanelContent>
          <a routerLink="/invoices/">Invoices</a><br />
          <div *ngIf="userRights.Accountant || userRights.InvoiceManager || userRights.UserBeListHasValue">
            <a routerLink="/invoicing/structured/">Invoicing Structured &amp; Open deals</a><br />
            <a routerLink="/invoicing/futures/">Invoicing Fut. &amp; opt. deals</a>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-9" title="Clients" *ngIf="userRights.Accountant || ((userRights.InvoiceManager || userRights.UserBeListHasValue) && !userRights.Trader)">
        <ng-template ngbPanelContent>
          <a routerLink="/clientsPayingCommissions/">Paying retros</a><br />
          <a routerLink="/commissions/">Retros</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-10a" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && (userRights.SuperUser || userRights.Accountant)">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/issuers-stat/">Issuer statistics</a><br />
          <a routerLink="/reports/brokers-stat/">Broker statistics</a><br />
          <a routerLink="/reports/client-statistics/">Client statistics</a><br />
          <a routerLink="/reports/clients-open-stat/">Open client statistics</a><br />
          <a routerLink="/reports/clients-future-stat/">Future client statistics</a><br />
          <a routerLink="/reports/clients-equities-stat/">Equities client statistics</a><br />
          <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
          <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
          <a routerLink="/reports/payouts-stat/">Payout statistics</a><br />
          <a routerLink="/payouts/">Payouts details</a><br />
          <a routerLink="/reports/reportISINs/">ISIN statistics</a><br />
          <div *ngIf="settings.IsDss">
            <a routerLink="/reports/smarket/">Secondary market</a>
          </div>
          <div *ngIf="settings.IsUss">
            <a routerLink="/reports/smarket2/">Secondary market</a>
          </div>
          <div *ngIf="settings.IsUss">
            <a routerLink="/reports/logbook/">Transaction report</a>
          </div>
          <div *ngIf="settings.IsDss">
            <a routerLink="/reports/reportTicketFees">Ticket fee statistics</a><br />
            <a routerLink="/cub-tickets">CUB Tickets</a><br />
            <a routerLink="/reports/reportsBeStats">BE statistics</a>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-10b" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && userRights.Sales && !userRights.SuperUser">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/client-statistics/">Client statistics</a><br />
          <a routerLink="/reports/clients-open-stat/">Open client statistics</a><br />
          <a routerLink="/reports/clients-future-stat/">Future client statistics</a><br />
          <a routerLink="/reports/clients-equities-stat/">Equities client statistics</a><br />
          <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
          <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
          <a routerLink="/reports/payouts-stat/">Payout statistics</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-10c" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && userRights.Trader && !userRights.SuperUser">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/client-portfolio/">Client reporting</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-10d" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && userRights.MiddleOffice && !userRights.SuperUser">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
          <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
          <a routerLink="/reports/smarket2/">Secondary market</a>
        </ng-template>
      </ngb-panel>
      <!--<ngb-panel id="sidebar-menu-10e" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && userRights.Accountant && !userRights.SuperUser">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/issuers-stat/">Issuer statistics</a><br />
          <a routerLink="/reports/brokers-stat/">Broker statistics</a><br />
          <a routerLink="/reports/client-statistics/">Client statistics</a><br />
          <a routerLink="/reports/clients-open-stat/">Open client statistics</a><br />
          <a routerLink="/reports/clients-future-stat/">Future client statistics</a><br />
          <a routerLink="/reports/clients-equities-stat/">Equities client statistics</a><br />
          <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
          <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
          <a routerLink="/reports/payouts-stat/">Payout statistics</a><br />
          <a routerLink="/payouts/">Payouts details</a>
        </ng-template>
      </ngb-panel>-->
      <ngb-panel id="sidebar-menu-10f" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && userRights.SuperOffice && !userRights.SuperUser">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/issuers-stat/">Issuer statistics</a><br />
          <a routerLink="/reports/brokers-stat/">Broker statistics</a><br />
          <a routerLink="/reports/client-statistics/">Client statistics</a><br />
          <a routerLink="/reports/clients-open-stat/">Open client statistics</a><br />
          <a routerLink="/reports/clients-future-stat/">Future client statistics</a><br />
          <a routerLink="/reports/clients-equities-stat/">Equities client statistics</a><br />
          <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
          <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
          <a routerLink="/reports/smarket2/">Secondary market</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-10f" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && userRights.ComplianceRisk && !userRights.SuperUser">
        <ng-template ngbPanelContent>
          <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
          <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
          <a routerLink="/reports/smarket2/">Secondary market</a>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-10g" title="Reporting" *ngIf="(settings.IsDss || settings.IsUss) && !userRights.SuperUser && (userRights.hasNoGroup && userRights.Sales || userRights.SalesAllClients || userRights.DealManager)">
        <ng-template ngbPanelContent>
          <div *ngIf="userRights.SuperUser || userRights.DealManager">
            <a routerLink="/reports/issuers-stat/">Issuer statistics</a><br />
            <a routerLink="/reports/brokers-stat/">Broker statistics</a><br />
          </div>
          <div *ngIf="userRights.Sales || userRights.SalesAllClients">
            <a routerLink="/reports/client-statistics/">Client statistics</a><br />
            <a routerLink="/reports/clients-open-stat/">Open client statistics</a><br />
            <a routerLink="/reports/clients-future-stat/">Future client statistics</a><br />
            <a routerLink="/reports/clients-equities-stat/">Equities client statistics</a><br />
            <a routerLink="/reports/client-retros/">Client retro statistics</a><br />
            <a routerLink="/reports/client-portfolio/">Client reporting</a><br />
            <div *ngIf="userRights.SuperUser">
              <div *ngIf="settings.IsDss">
                <a routerLink="/cub-tickets">CUB Tickets</a><br />
              </div>
              <a routerLink="/reports/reportISINs/">ISIN statistics</a><br />
              <div *ngIf="settings.IsDss">
                <a routerLink="/reports/reportsBeStats">BE statistics</a>
              </div>
              <a routerLink="/payouts/">Payouts details</a>
            </div>
          </div>
          <a routerLink="/reports/smarket2/">Secondary market</a>
          <div *ngIf="settings.IsUss">
            <a routerLink="/reports/logbook/">Transaction report</a>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel id="sidebar-menu-11" title="Configuration" *ngIf="(settings.IsDss || settings.IsUss) && (userRights.SuperUser || userRights.Batch || userRights.PrivilegedUser || userRights.MiddleOffice || userRights.Trader)">
        <ng-template ngbPanelContent>
          <div *ngIf="(settings.IsDss || settings.IsUss) && (userRights.SuperUser || userRights.Batch || userRights.PrivilegedUser || userRights.MiddleOffice)">
            <div *ngIf="userRights.SuperOffice || userRights.PrivilegedUser || userRights.SuperUser ">
              <div *ngIf="userRights.SuperUser">
                <a id="UsersPart" routerLink="/users/">Users</a><br />
                <div *ngIf="settings.HaveJob">
                  <a id="Jobs" routerLink="/jobs/">Jobs</a><br />
                </div>
              </div>


              <a id="SchemaPart" routerLink="/distributions/">Distribution schemes</a><br />
              <a id="ClientsPart" routerLink="/clients/">Clients</a><br />
              <a id="IssuersPart" routerLink="/issuers/">Issuers</a><br />
              <a id="CustodiansPart" routerLink="/custodians/">Custodians</a><br />
            </div>
            <div *ngIf="userRights.SuperUser || userRights.Batch || userRights.MiddleOffice">
              <a routerLink="/batch/">Batch</a><br />
              <a routerLink="/import-json/">Import json</a><br />
            </div>
            <div *ngIf="userRights.SuperUser">
              <div *ngIf="settings.IsUss">
                <a routerLink="/import-json/">Import json</a><br />
                <a routerLink="/admin/dcsync/">Deal-Catcher Sync. Service</a><br />
              </div>
              <a id="PeriodPart" routerLink="/currencies/">Exhange rates</a><br />
              <a routerLink="/ecb-currency">ECB rates</a><br />
              <a id="BookingEntitiesPart" routerLink="/bookingEntities/">Booking entities</a><br />
              <div *ngIf="settings.IsDss">
                <a routerLink="/serviceFees">Ticket fees</a><br />
              </div>
            </div>
          </div>
          <div *ngIf="userRights.SuperUser || userRights.Trader">
            <a id="underlyingIssuers" routerLink="/underlyingIssuers/">Underlying Issuers</a><br />
          </div>
          <div>
            <a id="empty"></a><br />
          </div>
          <div>
            <a id="empty"></a><br />
          </div>
          <div>
            <a id="empty"></a><br />
          </div>

        </ng-template>
      </ngb-panel>
      <!-- <ngb-panel id="sidebar-menu-12" title="Angular 10">
    <ng-template ngbPanelContent>
      <a routerLink="ng10-route">Angular 10 Component</a><br />
    </ng-template>
  </ngb-panel> -->
    </ngb-accordion>
    <!--<button [ngClass]="isOpened ? 'is-open': '' (click)="isOpened=!isOpened">Toggle</button>-->
  </div>
  <div class="versioninfo-block">
    <br />
    <span class="muted credit" style="color: #333333;">
      <strong>{{ userRights && userRights.Username }}</strong>
    </span><br />
    <span class="muted credit" style="color: lightgray;">
      <strong>{{ settings && settings.Version }}</strong>
    </span>
  </div>
</ejs-sidebar>
