import { Component, OnInit } from '@angular/core';
import { DcSyncService } from '../services/dcsync.service';

@Component({
  selector: 'app-dcsyncadmin',
  templateUrl: './dcsyncadmin.component.html',
  styleUrls: ['./dcsyncadmin.component.scss']
})
export class DcsyncadminComponent implements OnInit {

  message: string;

  constructor(private dcsyncService: DcSyncService) { }

  ngOnInit(): void {
    //this.message = "ready";
  }

  ping(): void {
    this.message = "Ping dc.sync service...";
    this.dcsyncService.Ping().subscribe(res => {
      console.log(res);
      this.message += res.StatusCode;
    }, err => {
      console.error(err);
      this.message += "Error: " + err.error.Message;
    });
  }

  sync(entity: string) {
    this.message = "Sync " + entity + "...";
    this.dcsyncService.SyncEntity(entity).subscribe(res => {
      console.log(res);
      this.message += "Correctly triggered (see dc.sync log for more details)";
    }, err => {
      console.error(err);
      this.message += "Error: " + err.error.Message;
    });
  }

}
