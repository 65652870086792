
import { FileDto } from './../file-input/FileDto.Model'

export class CategoryDto {
 

  public Label: string;
  public Support: string;
  public Code: string;
  public CategoryCode: string;
  public IsComplete: boolean;
  public Files: FileDto[] = [];

}
