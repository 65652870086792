import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { SecondaryQuoteDto } from '../quoting/SecondaryQuoteDto.Model';
import { QuoteChildIssuerDto } from '../models/QuoteChildIssuerDto';
import { QuoteDto } from '../models/QuoteDto';
import { QuoteData } from '../models/QuoteData';
import { DealDto } from '../order/DealDto.Model';
import { PrimaryQuoteDto } from '../order/PrimaryQuoteDto.Model';
import { SolveForDto } from '../order/SolveForDto.Model';
import { UnderlyingDto } from '../order/UnderlyingDto.Model';
import { QuoteChildDto } from '../models/QuoteChildDto';
import { CustomCheckBoxDto } from '../order/CustomCheckBoxDto.Model';
import { PricingObjectDto } from '../order/PricingObjectDto.Model';
import { SalesDto } from '../order/SalesDto.Model';
import { UnderlyingIssuerDto } from '../models/UnderlyingIssuerDto';
import { BookingDto } from '../models/BookingDto';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';
import { InstrumentDto } from '../models/InstrumentDto';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class QuoteService {
  private actionUrlCurrency: string;
  private actionUrlClient: string;
  private actionUrlProduct: string;
  private actionUrlUssuer: string;
  private actionUrlSend: string;

  constructor(private httpClient: HttpClient) {
    this.actionUrlCurrency = `/datas/currency`;
    this.actionUrlClient = `/datas/clients`;
    this.actionUrlUssuer = `/datas/issuers`;
    this.actionUrlProduct = `/datas/products`;

    this.actionUrlSend = `/datas/sendrequest`;
  }

  public GetAllCurrency = (): Observable<any> => {
    return this.httpClient.get(this.actionUrlCurrency);
    //.map((response: Response) => <any>response.json());
  }

  public GetAllClient = (): Observable<any> => {
    return this.httpClient.get(this.actionUrlClient);
  }

  public GetAllIssuer = (): Observable<Array<CodeValueDto>> => {
    return this.httpClient.get<Array<CodeValueDto>>(this.actionUrlUssuer);
  }

  public GetAllSales = (): Observable<Array<SalesDto>> => {
    return this.httpClient.get<Array<SalesDto>>('/datas/GetAllSales');
  }

  public GetAllProduct = (): Observable<any> => {
    return this.httpClient.get(this.actionUrlProduct);
  }

  public GetAll(take: number, skip: number): Observable<Array<QuoteDto>> {
    let datas = new HttpParams().set('take', take.toString()).set('skip', skip.toString());
    return this.httpClient.get<Array<QuoteDto>>('/api/SecondaryQuote/GetAll', { params: datas });
  }
  //public GetAllQuotes(pageNumber: number, pageSize: number, scope: string, filter: string): Observable<Array<QuoteDto>> {
  //  let datas = new HttpParams().set('pageNumber', pageNumber.toString()).set('pageSize', pageSize.toString()).set('scope', scope.toString()).set('filter', filter.toString());
  //  return this.httpClient.get<Array<QuoteDto>>('/api/quotes/GetAllQuotes', { params: datas });
  //}
  public GetAllQuotes(pageNumber: number, pageSize: number, scope: string, search: string, sortStatus: string, sortActive: string): Observable<QuoteData> {
    let datas = new HttpParams().set('pageNumber', pageNumber.toString()).set('pageSize', pageSize.toString()).set('scope', scope.toString()).set('search', search.toString()).set('sortStatus', sortStatus.toString()).set('sortActive', sortActive.toString());
    return this.httpClient.get<QuoteData>('/api/quotes/GetAllQuotes', { params: datas });
  }
  public GetAllUnderlyingIssuers(): Observable<Array<UnderlyingIssuerDto>> {
    return this.httpClient.get<Array<UnderlyingIssuerDto>>('/datas/GetAllUnderlyingIssuers');
  }

  public GetAllPricingInstruments(): Observable<Array<InstrumentDto>> {
    return this.httpClient.get<Array<InstrumentDto>>('/datas/GetAllPricingInstruments');
  }
  
  public PriceAllInstruments(): Observable<any> {
    return this.httpClient.get('/datas/PriceAllInstruments');
  }

  public GetAllBookings(): Observable<Array<BookingDto>> {
    return this.httpClient.get<Array<BookingDto>>('/datas/GetAllBookings');
  }
  
  public GetAllQuote(take: number, skip: number): Observable<Array<QuoteDto>> {
    let datas = new HttpParams().set('take', take.toString()).set('skip', skip.toString());
    return this.httpClient.get<Array<QuoteDto>>('/api/quotes/GetAllQuote', { params: datas });
  }
  public GetCountOfQuotes(): Observable<any> {
    return this.httpClient.get<Array<QuoteDto>>('/api/quotes/GetCountOfQuotes');
  }

  public GetAllDeals(take: number, skip: number, scope: string, group: string): Observable<Array<DealDto>> {
   
    if ((group == 'sales' || group == 'middle-office') && scope == 'today') {
      let datas = new HttpParams().set('take', take.toString()).set('skip', skip.toString()).set('scope', scope.toString());
      return this.httpClient.get<Array<DealDto>>('/api/quotes/GetAllDeals', { params: datas }).pipe(
        map(result => result.filter(r => r.Categories.length == 0 || (!r.Categories[0].IsComplete && !r.Categories[3].IsComplete)))
      );
    } else if (group == 'trader' && scope == 'today') {
      let datas = new HttpParams().set('take', take.toString()).set('skip', skip.toString()).set('scope', scope.toString());
      return this.httpClient.get<Array<DealDto>>('/api/quotes/GetAllDeals', { params: datas }).pipe(
        map(result => result.filter(r => r.Categories.length == 0 || (!r.Categories[1].IsComplete || !r.Categories[2].IsComplete) || !r.Categories[4].IsComplete))
      );
    } else {

      let datas = new HttpParams().set('take', take.toString()).set('skip', skip.toString()).set('scope', scope.toString());
     return this.httpClient.get<Array<DealDto>>('/api/quotes/GetAllDeals', { params: datas });
    }

  }

  public GetGroupCode(): Observable<SalesDto> {
    return this.httpClient.get<SalesDto>('/api/quotes/GetGroupCode', );
  }
  public GetHiddenQuotes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get('/api/quotes/GetHiddenQuotes', { headers, responseType: 'text' });
  }
  public ResetHiddenQuotes(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get('/api/quotes/ResetHiddenQuotes', { headers, responseType: 'text' });
  }

  public HideQuote(quoteNbr: string): Observable<any> {
    let datas = new HttpParams().set('quoteNbr', quoteNbr);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get('/api/quotes/HideQuote', { params: datas, headers, responseType: 'text' });
  }

  public GetByChildNbr(nbr: string): Observable<SecondaryQuoteDto> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get<SecondaryQuoteDto>('/api/SecondaryQuote/GetByChildNbr', { params: datas });
  }
  public GetPQByChildNbr(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetPQByChildNbr', { params: datas });
  }
  public GetOrder(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetOrder', { params: datas });
  }
  public GetDealInfo(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetDealInfo', { params: datas });
  }
  public GetSQByChildNbr(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/api/SecondaryQuote/GetByChildNbr', { params: datas });
  }
  public GetSolveForValue(nbr: string, issuerCode: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr).set('issuerCode', issuerCode);
    return this.httpClient.get('/datas/GetResolvedValueFor', { params: datas });
  }
  public GetPricingIssuers(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get('/datas/GetPricingIssuers', { params: datas, headers, responseType: 'text'  });
  }
  // public PostSolveForValue(solveFor: SolveForDto): Observable<any> {
  //   return this.httpClient.post('/datas/GetPostSolvedValue', solveFor);
  // }

  public SetQuoteCancelled(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/SetCancelled', quote);
  }

  public SetOrderCancelled(quote: DealDto): Observable<any> {
    return this.httpClient.post('/api/quotes/SetOrderCancelled', quote);
  }

  public SetQuoteToUser(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/SetQuoteToUser', quote);
  }
  public setOrderReceived(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/setOrderReceived', quote);
  }

  public SetStatusRequested(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/SetStatusRequested', quote);
  }
  
  
  public ReadMail(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/ReadMail', quote);
  }

  public ReadAllMail(quote: QuoteChildDto[]): Observable<any> {
    return this.httpClient.post('/api/quotes/ReadAllMail', quote);
  }

  public RefreshPrices(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/RefreshPrices', quote);
  }
  public SendPrice(quote: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/SendPrice', quote);
  }

  public AmendToMarket(trade: DealDto): Observable<any> {
    return this.httpClient.post('/api/quotes/AmendToMarket', trade);
  }

  public UpdateSecondaryQuote(quote: SecondaryQuoteDto): Observable<any> {
    return this.httpClient.post('/api/SecondaryQuote/UpdateSecondaryQuote', quote);
  }

  public SaveSecondaryQuote(quote: SecondaryQuoteDto): Observable<any> {
    return this.httpClient.post(this.actionUrlSend, quote);
  }
  
  public SendOrder(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/SendOrder', quote);
  }
  public SaveAuditTrails(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/SaveAuditTrails', quote);
  }
  public EditOrder(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/EditOrder', quote);
  }
  public SendMinifutOrder(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/SendMinifutOrder', quote);
  }
  public GetDealMinifut(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetDealMinifut', { params: datas });
  }
  public GetDraftMinifut(nbr: string): Observable<any> {
    let datas = new HttpParams().set('quotChildNbr', nbr);
    return this.httpClient.get('/api/minifutures/draft', { params: datas });
  }

  public GetDoneDeal(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetDoneDeal', { params: datas });
  }


  public GetOrderForBooking(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetOrderForBooking', { params: datas });
  }
  

  public GetMinifutDoneDeal(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/GetMinifutDoneDeal', { params: datas });
  }

  public SendDoneDeal(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/SendDoneDeal', quote);
  }
  public SendBooking(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/SendBooking', quote);
  }
  public ExecutePricing(pricingObject: PricingObjectDto): Observable<any> {
    return this.httpClient.post('/datas/ExecutePricing', pricingObject);
  }
  public SendMinifutDoneDeal(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/datas/SendMinifutDoneDeal', quote);
  }

  public UpdateQuoteChildIssuer(qci: QuoteChildIssuerDto): Observable<any> {
    return this.httpClient.post('/api/quotes/UpdateQuoteChildIssuer', qci);
  }
  public UpdateQuoteChildDescription(qci: QuoteChildDto): Observable<any> {
    return this.httpClient.post('/api/quotes/UpdateQuoteChildDescription', qci);
  }
  public DeleteQuoteChildIssuer(qci: QuoteChildIssuerDto): Observable<any> {
    return this.httpClient.post('/api/quotes/DeleteQuoteChildIssuer', qci);
  }

  public AddQuoteChildIssuer(qci: QuoteChildIssuerDto): Observable<any> {
    return this.httpClient.post('/api/quotes/AddQuoteChildIssuer', qci);
  }


  public UpdateUnderlyingIssuer(qci: UnderlyingIssuerDto): Observable<any> {
    return this.httpClient.post('/datas/UpdateUnderlyingIssuer', qci);
  }
  public DeleteUnderlyingIssuer(qci: UnderlyingIssuerDto): Observable<any> {
    return this.httpClient.post('/datas/DeleteUnderlyingIssuer', qci);
  }

  public AddUnderlyingIssuer(qci: UnderlyingIssuerDto): Observable<any> {
    return this.httpClient.post('/datas/AddUnderlyingIssuer', qci);
  }


  public AddListQuoteChildIssuer(qci: QuoteChildIssuerDto[]): Observable<any> {
    return this.httpClient.post('/api/quotes/AddListQuoteChildIssuer', qci);
  }
  public DeleteQuotes(nbr: string, fromQuote: boolean): Observable<any> {
    let data = { nbr: nbr, fromQuote: fromQuote };
    return this.httpClient.post('/api/quotes/DeleteQuotes', data);
  }

  public IsPrimaryQuote(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/datas/IsPrimaryQuote', { params: datas });
  }
  public checkStampduty(clientcode: string, isin: string): Observable<any> {
    let datas = new HttpParams().set('clientcode', clientcode).set('isin', isin);
    return this.httpClient.get('/datas/checkStampduty', { params: datas });
  }
  public GetUnderlyingsById(id: string): Observable<UnderlyingDto> {
    let datas = new HttpParams().set('id', id);
    return this.httpClient.get<UnderlyingDto>('/api/product/GetUnderlyingsById', { params: datas });
  }
  public GetUnderlyingsByCode(code: string): Observable<any> {
    let datas = new HttpParams().set('code', code).set('take', "25");
    return this.httpClient.get<Array<UnderlyingDto>>('/api/product/GetUnderlyingsByCode', { params: datas });
  }
  public CreateMinifut(quote: PrimaryQuoteDto): Observable<any> {
    return this.httpClient.post('/api/minifutures', quote);
  }
  public GetPricingQuotes(nbr: string): Observable<Array<CustomCheckBoxDto>> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get<Array<CustomCheckBoxDto>>('/datas/GetPricingQuotes', { params: datas });
  }

  public GetNominalQuote(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.get('/api/quotes/GetNominalQuote', { params: datas, headers, responseType: 'text' });
  }

  public AddSize(nbr: string): Observable<any> {
    let datas = new HttpParams().set('quoteNbr', nbr);
    return this.httpClient.get('/datas/AddSize', { params: datas });
  }
  public IsOrphan(nbr: string): Observable<any> {
    let datas = new HttpParams().set('nbr', nbr);
    return this.httpClient.get('/api/quotes/IsOrphan', { params: datas });
  }
  
}
