import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ClientDto } from '../../quoting/ClientDto.Model';
import { QuoteService } from '../../services/quote.service';
import { DealDto } from '../DealDto.Model';
import { MarginDto } from '../MarginDto.Model';
import { PrimaryQuoteDto } from '../PrimaryQuoteDto.Model';
import { SalesDto } from '../SalesDto.Model';
import { SettlementDto } from '../SettlementDto.Model';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {
  primaryQuote: PrimaryQuoteDto = new PrimaryQuoteDto();
  clientRef1 = new Map<string, string>();
  clientRef2 = new Map<string, string>();
  isin = "";
  productName = "";
  onBehalfSales = "";
  clicked = false;
  public errors = [];
  valError = false;
  public group: string = "";
  groupEntity: SalesDto;
  scopeTradeValue: string = 'today';
  deals: Array<DealDto> = [];
  public IsExternal: boolean = false;
 
  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute, private notifierService: NotifierService, private router: Router, private _chRef: ChangeDetectorRef) {

    this.activatedRoute.params.subscribe(params => {

      let nbr = '';
      nbr = params['nbr'] ? params['nbr'].toString() : '';
      this.onBehalfSales = params['sales'] ? params['sales'].toString() : '';

      if (nbr) {
  
        this.primaryQuote = new PrimaryQuoteDto();
        this.primaryQuote.QuoteNbr = nbr;
        this.quoteService.GetOrderForBooking(nbr).subscribe(r => {
          this.primaryQuote = r;
          this.isin = this.primaryQuote.ISIN;
          this.productName = this.primaryQuote.ProductName;
          if (this.primaryQuote.deals.length > 0) {
            this.primaryQuote.deals.forEach(d => {

              if (d.ExternalId !== null && d.ExternalId !== undefined && d.ExternalId != '' && d.ExternalId.includes('@')) {
                this.clientRef1.set(d.DealNbr,d.ExternalId.split('@')[0]);
                this.clientRef2.set(d.DealNbr,d.ExternalId.split('@')[1]);
              }
            })
          }
        });

      } else {
        this.primaryQuote = new PrimaryQuoteDto();
        this.primaryQuote.SendAuto = true;

      }
    });}

  ngOnInit(): void {

    this.quoteService.GetGroupCode()
      .subscribe(
        x => {
          this.groupEntity = x;
          this.group = this.groupEntity.ApplicationGroupCode.replace("group.", "");
          this.IsExternal = this.groupEntity.IsExternal;
          this.quoteService.GetAllDeals(25, 0, this.scopeTradeValue, this.group)
            .subscribe(
              x => this.deals = x,
              error => this.processError(error)
            );
          error => this.processError(error)
        });

   
  
    }

  ngDoCheck() {
   
    setTimeout(() => {
      if (this.primaryQuote.firstDeal) {
        this.primaryQuote.firstDeal.IsEqualSizes = (this.primaryQuote.firstDeal.AllSize == this.primaryQuote.firstDeal.Quantity) && this.primaryQuote.firstDeal.AllSize != 0 && this.primaryQuote.firstDeal.Quantity != 0;
      }
      if (this.primaryQuote.deals != null) {
        this.primaryQuote.deals.forEach(d => {
          d.IsEqualSizes = (d.AllSize == d.Quantity) && d.AllSize != 0 && d.Quantity != 0;
          d.ClientPrice = (d.OrderDescription == "ordertype.bid") ? d.Settlement + d.Margins.map(m => m.Fees).reduce((a, b) => a + b):d.Settlement - d.Margins.map(m => m.Fees).reduce((a, b) => a + b);
        });

      }
    });
  }



  parseDealClient(client: ClientDto, index: number) {
      this.primaryQuote.deals[index].ClientCode = client.ClientCode;
      this.primaryQuote.deals[index].ClientId = client.Id;
    
  }

  parseMarginClient(client: ClientDto, index: number, marginIndex: number) {
    this.primaryQuote.deals[index].Margins[marginIndex].ClientCode = client.ClientCode;
    this.primaryQuote.deals[index].Margins[marginIndex].ClientId = client.Id;

  }
  allSizeCusto(deal: DealDto): string {
    deal.AllSize = 0;
    if (deal != null) {
      deal.Custodians.forEach(c => {
        deal.AllSize = deal.AllSize + c.CustodianSize;
      })

    }
    return this.sizeFormatted(deal.AllSize) + '/' + this.sizeFormatted(deal.Quantity);

  }

  sizeFormatted(size: number) {
    if (!size) {
      return "0";
    }

    if (size >= 1000000) {
      return String(size / 1000000) + " M";
    }
    else if (size >= 1000) {
      return String(size / 1000) + " K";
    }
    else {
      return String(size);
    }
  }
  onChangeSize(firstDeal: boolean, deal: DealDto) {
    if (firstDeal) {
      if (deal.ShowAllSize == true) {

        //All size behaviour
        if ((!deal.Custodians || deal.Custodians.length == 0) && !deal.IsEqualSizes) {
          this.primaryQuote.CustodianSize = deal.Quantity;
        } else if (deal.Custodians && deal.Custodians.length > 0 && !deal.IsEqualSizes) {
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize;
        }


      }

    } else {
      if (deal.ShowAllSize == true) {

        //All size behaviour
        if (deal.Custodians && deal.Custodians.length == 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity;
        } else if (deal.Custodians && deal.Custodians.length > 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize;
        }
      }
    }

  }
  addMargin(deal?: DealDto) {

    if (deal != null) {
      let lastIndex = deal.Margins.length - 1;
      if (Boolean(deal.Margins[lastIndex].ClientId) && Boolean(deal.Margins[lastIndex].Fees) ) {
        deal.Margins.push({
          ClientId: null,
          Fees: null,
          ClientCode:null
          
        });
      }
    } 

  }

  removeMargin(deal: DealDto, index: number) {
    deal.Margins.splice(index, 1);

  }
  onChangeTbd(firstDeal: boolean, deal: DealDto) {
 
      if (deal.TBD == true) {
        if (deal.Custodians != null) {
          deal.Custodians.forEach(c => {
            c.CustodianCode = 'custodian.ff85bb46-6388-43a6-bbf2-a1e1174af8ad';
            c.Description = 'TBD'

          })
        }
        //All size behaviour
        if (deal.Custodians.length == 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity;
        } else if (deal.Custodians.length > 1 && !deal.IsEqualSizes) {
          deal.ShowAllSize = true;
          deal.Custodians[deal.Custodians.length - 1].CustodianSize = deal.Quantity - deal.AllSize;
        }

      } else {
        if (deal.Custodians != null) {
          deal.Custodians.forEach(c => {
            c.CustodianCode = '';
          })
        }
      }
    

  }
  ordinalSuffix(i: number) {
    let j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + '-st';
    }
    if (j == 2 && k != 12) {
      return i + '-nd';
    }
    if (j == 3 && k != 13) {
      return i + '-rd';
    }
    return i + '-th';
  }
  goToHome() {
    this.router.navigate(['/']);

  }

  goToProducts() {
    this.router.navigate(['/products']);

  }
  cleanEmptyMargins() {
   
    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        if (this.primaryQuote.deals[i].Margins != null) {
          for (let j = 0; j < this.primaryQuote.deals[i].Margins.length; j++) {
            if ((!Boolean(this.primaryQuote.deals[i].Margins[j].ClientId) || !Boolean(this.primaryQuote.deals[i].Margins[j].Fees)) && j>0) {
              this.primaryQuote.deals[i].Margins.splice(j, 1);
            }
          }

        }
      }
    }

  }
  checkValidation() {
    this.cleanEmptyMargins();

    this.valError = false;
    this.errors = [];


    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        if (!this.primaryQuote.deals[i].IsEqualSizes && !this.primaryQuote.deals[i].IsTBD) {
          this.errors.push('Error on the ' + this.ordinalSuffix(i + 1) + ' deal: Total quantities must be equal to allocation quantities');
          this.valError = true;

        }
      }
    }
    if (this.primaryQuote.deals != null) {
      for (let i = 0; i < this.primaryQuote.deals.length; i++) {
        for (let j = 0; j < this.primaryQuote.deals[i].Custodians.length; j++) {
          if ((!Boolean(this.primaryQuote.deals[i].Custodians[j].CustodianCode) || (!Boolean(this.primaryQuote.deals[i].Custodians[j].CustodianSize) && !this.primaryQuote.deals[i].IsTBD))) {
            this.errors.push('Custudian\'s information is required');
            this.valError = true;
          }
        
        }
        //for (let j = 0; j < this.primaryQuote.deals[i].Margins.length; j++) {
        
        //  if ((!Boolean(this.primaryQuote.deals[i].Margins[j].ClientId) || (!Boolean(this.primaryQuote.deals[i].Margins[j].Fees)))) {
        //    this.errors.push('Error on the ' + this.ordinalSuffix(i + 1) +' deal: Margin\'s information is required ');
        //    this.valError = true;
        //  }
        //}

      }
    }

  }
  sendBooking() {
    this.checkValidation();

    //addOnBehalf
    this.primaryQuote.OnBehalf = this.onBehalfSales;

    if (!this.valError) {
      this.clicked = true;
      
      if (this.primaryQuote.deals.length > 0 && this.clientRef1.size>0 && this.clientRef2.size>0) {
        this.primaryQuote.deals.forEach(d => {

          d.ExternalId = this.clientRef1.get(d.DealNbr) + '@' + this.clientRef2.get(d.DealNbr);
  
        })
      }

      this.quoteService.SendBooking(this.primaryQuote).subscribe(c => {
      
        this.notifierService.notify("success", "Booking executed");
        setTimeout(() => {
          this.router.navigate(['/quoting/list']);
        }, 500);

      },
        e => {
          this.notifierService.notify("error", "Retry later");
        });
    } else {
      this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
      scroll(0, 0);
    }

  }
  custoSelect(event, custo: SettlementDto) {
   
      custo.Description = event;

  }

  removeCustodian(deal: DealDto, index: number, i) {
    deal.Custodians.splice(index, 1);
  }

  addCusto(deal: DealDto) {
    let lastIndex = deal.Custodians.length - 1;
    if (deal.Custodians[lastIndex].CustodianCode != null && deal.Custodians[lastIndex].CustodianSize != null
      && deal.Custodians[lastIndex].CustodianCode != "" && deal.Custodians[lastIndex].CustodianSize.toString() != "" || deal.IsTBD) {
      deal.Custodians.push({
        CustodianSize: null,
        CustodianCode: null,
        IsDeleted: false,
        IsNew: true,
        Description: null,
        Id: null
      });
    }
    deal.ShowAllSize = false;

  }
  private processError(e: any) {
    console.log(e);

    if (e.status == 401 || e.status == 403) {
      this.notifierService.notify("warning", "You are not authorized to do this action.");
    } else {
      let message = "Unexpected error";
      if (e.error && e.error.Message) {
        message = e.error.Message;
      } else if (e.error) {
        message = e.error;
      } else if (e.status) {
        message += " (" + e.status + ") ";
      }

      console.log(message);
      this.notifierService.notify("error", message);
    }
  }

}
