import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { UpgradeModule, downgradeComponent } from '@angular/upgrade/static';
import { RouterModule } from '@angular/router';
//import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Ng10DemoComponent } from "./ng10-demo.component";
import { SideBarComponent } from './side-bar/side-bar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EmptyComponent } from './empty-component';
import { QuotingComponent } from './quoting/quoting.component';
import { PriceComponent } from './price/price.component';
import { QuoteListComponent } from './quoting/quote-list/quote-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { ProductAutocompleteComponent } from './autocomplete/product-autocomplete.component';
import { ClientAutocompleteComponent } from './autocomplete/client-autocomplete.component';
import { NotifierModule } from "angular-notifier";
import { CodeValueComponent } from './autocomplete/code-value.component';
import { DataService } from './services/data.service';
import { SizeInputComponent } from './autocomplete/size-input.component';
import { DecimalPipe } from '@angular/common';
import { MsAdalAngular6Module, MsAdalAngular6Service, AuthenticationGuard } from 'microsoft-adal-angular6';
import { InsertAuthTokenInterceptor } from './insert-auth-token-interceptor';
import { OrderComponent } from './order/order.component';
import { DcsyncadminComponent } from './admin/dcsyncadmin.component';
import { QuotesComponent } from './quoting/quotes/quotes.component';
import { DataTablesModule } from 'angular-datatables';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDialogModule } from '@angular/material/dialog';
import { AddDialogComponent } from './quoting/quotes/dialogs/add-dialog/add-dialog.component';
import { EditDialogComponent } from './quoting/quotes/dialogs/edit-dialog/edit-dialog.component';
import { DeleteDialogComponent } from './quoting/quotes/dialogs/delete-dialog/delete-dialog.component';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { MinifutCreationComponent } from './order/minifut-creation/minifut-creation.component';
import { UnderlyingAutocompleteComponent } from './autocomplete/underlying-autocomplete/underlying-autocomplete.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
//import { FlexLayoutModule } from '@angular/flex-layout';
import { BookingComponent } from './order/booking/booking.component';
import { PricingComponent } from './order/pricing/pricing.component';
import { PricingsComponent } from './pricings/pricings.component';
import { AddBatchDialogComponent } from './quoting/quotes/dialogs/add-batch-dialog/add-batch-dialog.component';
import { MatSortModule } from '@angular/material/sort';
import { MatConfirmDialogComponent } from './mat-confirm-dialog/mat-confirm-dialog.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DealsInfoComponent } from './deals-info/deals-info.component';
import { FileInputComponent } from './deals-info/file-input/file-input.component';
import { CategoryComponent } from './deals-info/category/category.component';
import { SaveAuditTrailsDialogComponent } from './deals-info/dialogs/save-audit-trails-dialog.component';
import { UnderlyingissuersComponent } from './underlyingissuers/underlyingissuers.component';
import { AddunderlyingissuerComponent } from './underlyingissuers/UIDialogs/addunderlyingissuer/addunderlyingissuer.component';
import { UpdateunderlyingissuerComponent } from './underlyingissuers/UIDialogs/updateunderlyingissuer/updateunderlyingissuer.component';
import { DeleteunderlyingissuerComponent } from './underlyingissuers/UIDialogs/deleteunderlyingissuer/deleteunderlyingissuer.component';
import { LogbookComponent } from './logbook/logbook.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { EditQuotedescComponent } from './quoting/quotes/dialogs/edit-quotedesc/edit-quotedesc.component';
import { AppSettingsService } from './app-settings.service';

// ADAL Configuration
let adalConfig: any; // will be initialized by APP_INITIALIZER

export function msAdalAngular6ConfigFactory() {
  return adalConfig;
}

export function initializeApp(appSettingsService: AppSettingsService) { 
  const promise = appSettingsService.getAdalConfig().then((config : any) => {
    adalConfig = {
      tenant: config.TenantId,
      clientId: config.ClientId,
      redirectUri: window.location.origin,
      endpoints: { // random guids
        ApiEndpoint: "ff58ac08-bd66-4bef-a991-920484d4a419",
      },
      navigateToLoginRequestUrl: false,
      cacheLocation: "localStorage",
    };
  });
  return () => promise;
}


declare var angular: any;
angular.module('app')
  .directive(
    'ng10Demo',
    downgradeComponent({ component: Ng10DemoComponent })
  );

@NgModule({
  declarations: [
    AppComponent,
    Ng10DemoComponent,
    SideBarComponent,
    QuotingComponent,
    PriceComponent,
    QuoteListComponent,
    ProductAutocompleteComponent,
    ClientAutocompleteComponent,
    CodeValueComponent,
    SizeInputComponent,
    OrderComponent,
    DcsyncadminComponent,
    QuotesComponent,
    AddDialogComponent,
    EditDialogComponent,
    DeleteDialogComponent,
    MinifutCreationComponent,
    UnderlyingAutocompleteComponent,
    BookingComponent,
    PricingComponent,
    PricingsComponent,
    AddBatchDialogComponent,
    MatConfirmDialogComponent,
    DealsInfoComponent,
    FileInputComponent,
    CategoryComponent,
    UnderlyingissuersComponent,
    AddunderlyingissuerComponent,
    UpdateunderlyingissuerComponent,
    DeleteunderlyingissuerComponent,
    LogbookComponent,
    EditQuotedescComponent,
    SaveAuditTrailsDialogComponent
  ],
  imports: [
    MsAdalAngular6Module,
    BrowserModule,
    UpgradeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    DataTablesModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    SidebarModule,
    MatFormFieldModule,
    MatInputModule,
    ClipboardModule,
    MatTableExporterModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          /**
           * Defines the horizontal position on the screen
           */
          position: 'middle',
        },
        vertical: {
          position: 'top',
        }

      }
    }),
    RouterModule.forRoot([
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ''
      },
      {
        path: 'ng10-route',
        component: Ng10DemoComponent
      },
      {
        path: 'quoting/all',
        component: QuoteListComponent
      },
      {
      path: 'quoting/list',
        component: QuotesComponent
      },
      {
        path: 'underlyingIssuers',
        component: UnderlyingissuersComponent
      },
      {
        path: 'instrumentspricing',
        component: PricingsComponent
      },
      {
        path: 'reports/logbook',
        component: LogbookComponent
      },
      {
        path: 'quoting/rfq',
        component: QuotingComponent
      },
      {
        path: 'quoting/rfq/:nbr',
        component: QuotingComponent
      },

      {
        path: 'ecb-currency',
        component: PriceComponent
      },
      {
        path: 'deal/new/:nbr',
        component: OrderComponent
      },
      {
        path: 'deal/new/addSize/:nbr',
        component: OrderComponent
      },

      {
        path: 'deal/new/:nbr/:sales',
        component: OrderComponent
      },
      {
        path: 'deal/new',
        component: OrderComponent
      },
      {
        path: 'order',
        component: OrderComponent
      },
      {
        path: 'done/deal',
        component: OrderComponent
      },
      {
        path: 'done/deal/:nbr',
        component: OrderComponent
      },
      {
        path: 'order/edit/:nbr',
        component: OrderComponent
      },
      //Done deal minifut
      {
        path: 'done/deal/minifut/:nbr',
        component: OrderComponent
      },
      //DealsInfo
      {
        path: 'deals/:nbr',
        component: DealsInfoComponent
      },
 
      //Order Minifut
      {
        path: 'order/:nbr',
        component: OrderComponent
      },
      {
        path: 'order/addSize/:nbr',
        component: OrderComponent
      },
      {
        path: 'admin/dcsync',
        component: DcsyncadminComponent
      },
      {
        path: 'deDraft/:nbr',
        component: MinifutCreationComponent
      },
      {
        path: 'booking/:nbr',
        component: BookingComponent
      },
      {
        path: 'booking/:nbr/:sales',
        component: BookingComponent
      },
      {
        path: 'pricing',
        component: PricingComponent
      },
      {
        path: 'pricing/:nbr',
        component: PricingComponent
      },
      // Handle ui-router routes with optionnal parameters
      {
        path: 'Import/Data/All',
        pathMatch: 'full',
        redirectTo: '/Import/Data/All/'
      },
      {
        path: 'products',
        pathMatch: 'full',
        redirectTo: '/products/'
      },
      {
        path: 'products-equities',
        pathMatch: 'full',
        redirectTo: '/products-equities/'
      },
      {
        path: 'products-mutualfunds',
        pathMatch: 'full',
        redirectTo: '/products-mutualfunds/'
      },
      {
        path: 'productedit',
        pathMatch: 'full',
        redirectTo: '/productedit/'
      },
      {
        path: 'productdraftedit',
        pathMatch: 'full',
        redirectTo: '/productdraftedit/'
      },
      {
        path: 'instrumentspricing',
        pathMatch: 'full',
        redirectTo: '/instrumentspricing/'
      },
      {
        path: 'logbook',
        pathMatch: 'full',
        redirectTo: '/reports/logbook/'
      },
      {
        path: 'notifications',
        pathMatch: 'full',
        redirectTo: '/notifications/'
      },
      {
        path: 'deals/all/booking',
        pathMatch: 'full',
        redirectTo: '/deals/all/booking/'
      },
      {
        path: 'deals/structured/booking',
        pathMatch: 'full',
        redirectTo: '/deals/structured/booking/'
      },
      {
        path: 'deals/open/booking',
        pathMatch: 'full',
        redirectTo: '/deals/open/booking/'
      },
      {
        path: 'deals/future/booking',
        pathMatch: 'full',
        redirectTo: 'deals/future/booking/'
      },
      {
        path: 'deals/equities/booking',
        pathMatch: 'full',
        redirectTo: '/deals/equities/booking/'
      },
      {
        path: 'deals/mutualfunds/booking',
        pathMatch: 'full',
        redirectTo: '/deals/mutualfunds/booking/'
      },
      {
        path: 'structured/bookings',
        pathMatch: 'full',
        redirectTo: '/structured/bookings/'
      },
      {
        path: 'open/bookings',
        pathMatch: 'full',
        redirectTo: '/open/bookings/'
      },
      {
        path: 'future/bookings',
        pathMatch: 'full',
        redirectTo: '/future/bookings/'
      },
      {
        path: 'equities/bookings',
        pathMatch: 'full',
        redirectTo: '/equities/bookings/'
      },
      {
        path: 'mutualfunds/bookings',
        pathMatch: 'full',
        redirectTo: '/mutualfunds/bookings/'
      },
      {
        path: 'instruments-products',
        pathMatch: 'full',
        redirectTo: '/instruments-products/'
      },
      {
        path: 'instruments',
        pathMatch: 'full',
        redirectTo: '/instruments/'
      },
      {
        path: 'instruments-underlyings',
        pathMatch: 'full',
        redirectTo: '/instruments-underlyings/'
      },
      {
        path: 'invoices',
        pathMatch: 'full',
        redirectTo: '/invoices/'
      },
      {
        path: 'instruments/:instrumentCode',
        pathMatch: 'full',
        redirectTo: '/instruments/:instrumentCode'
      },
     
      // Display NG10 EmptyComponenent when displaying a legacy route
      {
        path: '**',
        component: EmptyComponent
      }
    ],
      {
        useHash: false,
        enableTracing: false
      }
    ),
    NgbModule,
    BrowserAnimationsModule
  ],
  entryComponents: [
    Ng10DemoComponent,
    MatConfirmDialogComponent
  ],
  providers: [
    //phoneServiceProvider,
    //{ provide: UrlHandlingStrategy, useClass: CustomHandlingStrategy },
    { provide: 'Window', useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InsertAuthTokenInterceptor,
      multi: true
    },
    DecimalPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppSettingsService], 
      multi: true
    },
    {
      provide: 'adalConfig',
      useFactory: msAdalAngular6ConfigFactory,
      deps: []
    },
    MsAdalAngular6Service
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  constructor() {
    
   }

}

