import { ElementRef, Input, ViewChild, Component, OnInit, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FileDto } from './FileDto.Model';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit, OnChanges {
  @ViewChild('labelImport')
  labelImport: ElementRef;

  @Input()
  fileToUpload: FileDto = null;
  @Input() disabled = false;
  @Input() fileName = '';

  @Output()
  fileChange: EventEmitter<File> = new EventEmitter<File>();
  @ViewChild('myInput')
  myInputVariable: ElementRef;


  constructor(private _chRef: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.fileName && changes.fileName.previousValue) {
      this.fileName = '';
    }
  }


  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload.file = files.item(0);
    this.fileChange.emit(this.fileToUpload.file);

  }

  //showFileName() {
  //  if (this.fileToUpload != null) {
  //    this.labelImport.nativeElement.innerText = this.fileToUpload.name;

  //  }
  showFileName(file: FileDto) {
    if (file.Name != null) {

    } else {
      this.fileName = "";
      this.labelImport.nativeElement.innerText = "Choose file";
    }
    this._chRef.detectChanges();

  }

  deleteFile(file: File, disabled: boolean) {
    if (!disabled) {
      file = null;
      this.fileToUpload = null;
      this.myInputVariable.nativeElement.value = "";
      this.labelImport.nativeElement.innerText = "Choose file";
    }
  }


}
