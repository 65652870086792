<div>
  <mat-form-field>
    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="col-md-12">

    <ng-container matColumnDef="ISIN">
      <th mat-header-cell *matHeaderCellDef style="width: 20%;">ISIN</th>
      <td mat-cell *matCellDef="let row">{{row?.ISIN}}</td>
    </ng-container>

    <ng-container matColumnDef="BloombergCode">
      <th mat-header-cell *matHeaderCellDef style="width: 20%;">Bloomberg Code</th>
      <td mat-cell *matCellDef="let row"> {{row?.BloombergCode}}</td>
    </ng-container>


    <ng-container matColumnDef="IssuerCode">
      <th mat-header-cell *matHeaderCellDef style="width: 20%;">Issuer</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.IssuerCode}}" >
        {{row?.Issuer}}
      </td>
    </ng-container>

    <ng-container matColumnDef="CurrencyCode">
      <th mat-header-cell *matHeaderCellDef style="width: 20%;">Currency</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.CurrencyCode}}" >
        {{row?.Currency}}
      </td>
    </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef style="width: 20%;">
        <button mat-icon-button title="Add" color="primary" class="icon-display" (click)="addNew()">
          <mat-icon aria-label="Example icon-button with a heart icon">add</mat-icon>
        </button>
      </th>

      <td mat-cell *matCellDef="let row; let i=index;">
        <button mat-icon-button title="Edit" color="primary"
                (click)="startEdit(i,row,row.Id , row.IssuerCode, row.CurrencyCode,row.ISIN, row.BloombergCode)">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button mat-icon-button title="Delete" color="danger" (click)="deleteItem(i,row.Id)">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    <!--<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row" (dblclick)="(group =='trader' || group =='super-user') ? startEdit(i,row,row.Id, row.IssuerCode,row.IssuerName, row.SolveFor, row.Comment,row.PriceOff) : noResponse()"></tr>-->

  </table>
  <mat-paginator [pageSizeOptions]="[25, 10, 50]" showFirstLastButtons></mat-paginator>
</div>
