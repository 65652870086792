import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, iif, forkJoin } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { NotifierService } from "angular-notifier";


import { PrimaryQuoteDto } from '../order/PrimaryQuoteDto.Model';
import { QuoteService } from '../services/quote.service';
import { FileDto } from './file-input/FileDto.Model';
import { SaveAuditTrailsDialogComponent } from './dialogs/save-audit-trails-dialog.component';
import fileHelper from '../helpers/fileHelper'

@Component({
  selector: 'app-deals-info',
  templateUrl: './deals-info.component.html',
  styleUrls: ['./deals-info.component.scss']
})

export class DealsInfoComponent implements OnInit , OnDestroy {
  primaryQuote: PrimaryQuoteDto;
  group: string = "";
  isSuperUser = false;
  dataSubscription: Subscription;
  errorMessage: string = ""
  saveSubscription: Subscription;
  isSaving: boolean = false;
  hasError: boolean = false;


  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute, public dialog: MatDialog, private notifierService: NotifierService, private renderer: Renderer2) {}

  ngOnInit(): void {

    this.dataSubscription =  this.activatedRoute.params.pipe(
      mergeMap(params => iif(() => params['nbr'], forkJoin(this.quoteService.GetDealInfo(params['nbr'].toString()), this.quoteService.GetGroupCode()))),
    )
      .subscribe(([primaryQuote, groupEntity]) => {

        this.primaryQuote = primaryQuote;
        this.primaryQuote.firstDeal.Categories.forEach(c => {
          if (c.Files != null) {
            c.Files.forEach(f => {
              this.affectFile(f);
            });
          }
        });

        this.group = groupEntity.ApplicationGroupCode.replace("group.", "");
        this.isSuperUser = this.group == 'super-user';

      },
        () => this.errorMessage = "Failed to load data"
      )
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    if (this.saveSubscription)this.saveSubscription.unsubscribe();
  }
  refreshPage() {
    this.renderer.selectRootElement('body').location.reload(true);
  }
  saveAuditTrails() {
    //this.dialog.open(SaveAuditTrailsDialogComponent, {
    //  height: '250px',
    //  width: '500px',
    //  disableClose: true,
    //  data: { primaryQuote: this.primaryQuote,  }
    //});

    this.primaryQuote.firstDeal.Categories.forEach(c => {
      c.Files.forEach(f => {
        f.file = null;
      });
    });
      this.saveSubscription = this.quoteService.SaveAuditTrails(this.primaryQuote).subscribe(c => {
      this.isSaving = false;
        this.hasError = false;
        //this.refreshPage();
        this.primaryQuote.IsFirstOrder = false;
        this.notifierService.notify("success", "data has been saved successfully");
    },
      e => {
        if (e.status == 200) {
          this.notifierService.notify("success", "data has been saved successfully");
        }
        else if (e.status == 413) {
          this.notifierService.notify("error", "An error has occured. Files are too large and cannot be uploaded. please reduce file size or contact your system administrator");
        } else {
          this.notifierService.notify("error", e.message);
        }
        this.isSaving = false;
        this.hasError = true;
      });
  }

  affectFile(file: FileDto) {
    if (file.Name != 'DoneDealMail.txt' && file.Name != 'OrderMail.txt' || file.Id != null) {
      const base64 = file.content;
      const imageName = file.Name
      const imageBlob = fileHelper.dataURItoBlob(base64);
      const imageFile = new File([imageBlob], imageName, { type: file.Type });
      file.file = imageFile;
    } else {
      file.file = fileHelper.writeContents(file.content, file.Name, 'text/plain');
      file.content = file.content.replace(/[\u00A0-\u2666]/g, function (c) {
        return '&#' + c.charCodeAt(0) + ';';
      })
      file.content = btoa(unescape(encodeURIComponent(file.content)));

    }
  }
}
