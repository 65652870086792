import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, finalize, switchMap, tap } from 'rxjs/operators';
import { UnderlyingDto } from '../../order/UnderlyingDto.Model';
import { QuoteService } from '../../services/quote.service';


@Component({
  selector: 'underlying-autocomplete',
  templateUrl: './underlying-autocomplete.component.html',
  styleUrls: ['./underlying-autocomplete.component.scss']
})
export class UnderlyingAutocompleteComponent implements OnInit {


  _underId: string;

  searchUnderCtrl = new FormControl();
  filteredUnderlyings: any;
  disabledControls: boolean;
  isLoading = false;
  searching = false;
  searchFailed = false;
  _under: UnderlyingDto;

  @Input()
  disabled = false;

  @Input()
  set underId(val: string) {
    this._underId = val;

    if (val) {
      this.quoteService.GetUnderlyingsById(val).subscribe(c => {
        this.searchUnderCtrl.setValue(c.Code);
        this.setUnder(c);
      });

    }
  }


  get under() {
    return this._under;
  }

  set under(val: UnderlyingDto) {
    this._under = val;
  }



  @Output()
  underChange: EventEmitter<UnderlyingDto> = new EventEmitter<UnderlyingDto>();

  constructor(private quoteService: QuoteService) { }

  ngOnInit(): void {

    this.searchUnderCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredUnderlyings = [];
          this.isLoading = true;
        }),
        switchMap(value => this.quoteService.GetUnderlyingsByCode(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      )
      .subscribe(data => {
        this.filteredUnderlyings = data;

      });
  }


  setUnder(val: UnderlyingDto) {
    this.under = val;
    this.underChange.emit(val);
  }

}
