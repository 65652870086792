
export default class fileHelper {

  static dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  static writeContents(content, fileName, contentType) {
    var a = document.createElement('a');
    var fileBlob = new Blob([content], { type: contentType });
    let file = new File([fileBlob], fileName);

    return file;
  }

}
