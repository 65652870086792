import { SettlementDto } from './SettlementDto.Model';
import { MarginDto } from './MarginDto.Model';
import { CategoryDto } from './../deals-info/category/CategoryDto.Model'



export class DealDto {
 
  public Quantity: number;
  public Settlement: number;
  public ClientPrice?=0;
  public Fees?: number;
  public FairPrice?: number;
  public Accrued?: number;
  public ClientId: string;
  public OrderId: string;
  public ClientCode: string;
  public Status: string;
  public Custodians: SettlementDto[] = [];
  public Margins: MarginDto[] = [];
  public ShowAllSize: boolean;
  public Comment: string;
  public TBD: boolean;
  public IsTBD: boolean;
  public AllSize: number;
  public IsEqualSizes: boolean;
  public DealNbr: string;
  public Color: string;
  public Client: string;

  public Trader: string;
  public QuoteChildNbr: string;
  public side: string;
  public Side?: string;
  public isin: string;
  public issuer: string;
  public title: string;
  public maturity: string;
  public size: string;
  public currency: string;
  public underlyings: string;
  public Underlyings?: string;
  public OrderDescription: string;
  public DealDescription: string;
  public Selected: boolean;
  public Categories?: CategoryDto[] = [];
  public Sales?: string;
  public Id?: string;
  public ExternalId?: string;




}
