import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuoteService } from '../../services/quote.service';

@Component({
  selector: 'save-audit-trails-dialog',
  templateUrl: './save-audit-trails-dialog.component.html',
  styleUrls: ['./save-audit-trails-dialog.component.scss']
})

export class SaveAuditTrailsDialogComponent implements OnInit, OnDestroy {

  isSaving: boolean = false;
  hasError: boolean = false;
  errorMessage: string = "";
  saveSubscription: Subscription;

  constructor(private quoteService: QuoteService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data.primaryQuote.firstDeal.Categories.forEach(c => {
      c.Files.forEach(f => {
        f.file = null;
      });
    });
  }

  ngOnInit() {
  

    this.saveSubscription = this.quoteService.SaveAuditTrails(this.data.primaryQuote).subscribe(c => {
      this.isSaving = false;
      this.hasError = false;
    },
      e => {
        if (e.status == 413) {
          this.errorMessage = "An error has occured. Files are too large and cannot be uploaded. please reduce file size or contact your system administrator"
        } else {
          this.errorMessage = "An error has occured.please try again later"
        }
        this.isSaving = false;
        this.hasError = true;
      });

  }

  ngOnDestroy(): void {
    this.saveSubscription.unsubscribe();
  }
}
