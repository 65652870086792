import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { ProductDto } from '../../quoting/ProductDto.Model';
import { QuoteService } from '../../services/quote.service';
import { MiniFutureDto } from '../MiniFutureDto.Model';
import { PrimaryQuoteDto } from '../PrimaryQuoteDto.Model';
import { UnderlyingDto } from '../UnderlyingDto.Model';

@Component({
  selector: 'app-minifut-creation',
  templateUrl: './minifut-creation.component.html',
  styleUrls: ['./minifut-creation.component.scss']
})
export class MinifutCreationComponent implements OnInit {

  primaryQuote: PrimaryQuoteDto = new PrimaryQuoteDto();

  public errors = [];
  clicked = false;
  valError = false;




  constructor(private quoteService: QuoteService, private activatedRoute: ActivatedRoute, private notifierService: NotifierService, private router: Router) { }

  ngOnInit(): void {
    this.primaryQuote.MiniFuture = new MiniFutureDto();
    this.activatedRoute.params.subscribe(params => {

      let nbr = '';
      nbr = params['nbr'] ? params['nbr'].toString() : '';

      if (nbr) {
        this.quoteService.GetDraftMinifut(nbr).subscribe(r => {
          //this.primaryQuote.MiniFuture = new MiniFutureDto();
          this.primaryQuote.MiniFuture = r;

        });


      } else {
        this.primaryQuote = new PrimaryQuoteDto();
        this.primaryQuote.MiniFuture = new MiniFutureDto();

      }
    });

  }



  parseUnder(val: UnderlyingDto) {
    this.primaryQuote.MiniFuture.UnderlyingId = val.Id;
    this.primaryQuote.MiniFuture.Underlyings = val.Code;

  }

  checkValidation() {
    this.valError = false;
    this.errors = [];

    if (!this.primaryQuote.MiniFuture.Isin) {
      this.errors.push('ISIN is required');
      this.valError = true;
    }
    if (!this.primaryQuote.MiniFuture.UnderlyingId) {
      this.errors.push('Underlying is required');
      this.valError = true;
    }

    if (!this.primaryQuote.MiniFuture.Currency) {
      this.errors.push('Currency is required');
      this.valError = true;
    }
    if (!this.primaryQuote.MiniFuture.Sens) {
      this.errors.push('Sens type is required');
      this.valError = true;
    }
    if (!this.primaryQuote.MiniFuture.IssuerCode) {
      this.errors.push('Issuer is required');
      this.valError = true;
    }

    if (this.primaryQuote.MiniFuture.IssuerCode && this.primaryQuote.MiniFuture.IssuerCode == "0") {
      this.errors.push('Any issuer is not valid. You must select an issuer.');
      this.valError = true;
    }


    if (!this.primaryQuote.MiniFuture.Strike) {
      this.errors.push('Strike Type is required');
      this.valError = true;
    }
    if (!this.primaryQuote.MiniFuture.StopLoss) {
      this.errors.push('StopLoss Type is required');
      this.valError = true;
    }
    if (!this.primaryQuote.MiniFuture.LevierInitial) {
      this.errors.push('Levier initial is required');
      this.valError = true;
    }
    if (!this.primaryQuote.MiniFuture.AssetClass) {
      this.errors.push('Asset class is required');
      this.valError = true;
    }


  }

  createDealDraft() {
    this.checkValidation();

    if (!this.valError) {
      this.clicked = true;

      this.quoteService.CreateMinifut(this.primaryQuote).subscribe(c => {

        this.notifierService.notify("success", "Deal Draft executed");
        setTimeout(() => {
          this.router.navigate(['/quoting/list']);
        }, 500);

      },
        e => {
          this.notifierService.notify("error", "Retry later");
        });
    } else {
      this.notifierService.notify("error", "Invalid request, check errors on the top of the page !");
      scroll(0, 0);
    }

  }

}
