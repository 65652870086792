<div>
  <mat-form-field>
    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Filter" />
  </mat-form-field>

  <table mat-table [dataSource]="dataSource" class="col-md-12">
    ['', '', '', '', '', '','','']
    <ng-container matColumnDef="Code">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;">Ticker</th>
      <td mat-cell *matCellDef="let row" [routerLink]="['/instruments', row?.Code]"  style="text-decoration: underline;">{{row?.Code}}</td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef style="width: 20%;">Name</th>
      <td mat-cell *matCellDef="let row"> {{row?.Name}}</td>
    </ng-container>


    <ng-container matColumnDef="LastPrice">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;">LastPrice</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.LastPrice}}">
        {{row?.LastPrice}}
      </td>
    </ng-container>

    <ng-container matColumnDef="Currency">
      <th mat-header-cell *matHeaderCellDef style="width: 10%;">Currency</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.CurrencyCode}}">
        {{row?.CurrencyCode}}
      </td>
    </ng-container>

    <ng-container matColumnDef="UpdatedOn">
      <th mat-header-cell *matHeaderCellDef style="width: 15%;">LastPriceDate</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.LastPriceDate}}">
        {{row?.LastPriceDate}}
      </td>
    </ng-container>

    <!--<ng-container matColumnDef="LastBar">
      <th mat-header-cell *matHeaderCellDef style="width: 15%;">LastBarDate</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.LastBarDate}}">
        {{row?.LastBarDate}}
      </td>
    </ng-container>-->

    <ng-container matColumnDef="LastResponseDate">
      <th mat-header-cell *matHeaderCellDef style="width: 15%;">LastResponseDate</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.LastResponseDate}}">
        {{row?.LastResponseDate}}
      </td>
    </ng-container>

    <!--<ng-container matColumnDef="BlCode">
      <th mat-header-cell *matHeaderCellDef style="width: 5%;">BlCode</th>
      <td mat-cell class="overflow-hidden" *matCellDef="let row" title="{{row?.BlErrorCode}}">
        {{row?.BlErrorCode}}
      </td>
    </ng-container>-->


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="table-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
    <!--<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row" (dblclick)="(group =='trader' || group =='super-user') ? startEdit(i,row,row.Id, row.IssuerCode,row.IssuerName, row.SolveFor, row.Comment,row.PriceOff) : noResponse()"></tr>-->

  </table>
  <mat-paginator [pageSizeOptions]="[25, 10, 50]" showFirstLastButtons></mat-paginator>
</div>
<div class="footer">
  <div class="col-md-2">
    

    <em>
        <!--{{items.length}} loaded on {{totalItems}} identified-->
    </em>
  </div>

  <div class="col-md-2">
    <button class="btn btn-warning" [disabled]="isPricing" (click)="PriceAllInstruments()">Request Pricing for all Instruments</button>
  </div>

  <div class="col-md-4"></div>
  <div class="col-md-2">
    <!--<button id="AddInstrument" (click)="addNew()">Add</button>-->
    <a id="AddInstrument" class="btn btn-primary" (click)="addNew()">Add</a>

  </div>
  <!--<button *ngIf="numberOfNotif==0" mat-raised-button color="primary" style=" width: 150px; margin-left: 80px;" (click)="RefreshQuotes()">Refresh Data</button>-->

</div>
