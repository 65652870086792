import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InstrumentDto } from 'src/app/models/InstrumentDto';
import { QuoteService } from 'src/app/services/quote.service';
import { NotifierService } from "angular-notifier";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../services/data.service';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifResponseDto } from '../models/NotifResponseDto';
import { SharedService } from '../services/shared.service';


@Component({
  selector: 'app-pricings',
  templateUrl: './pricings.component.html',
  styleUrls: ['./pricings.component.scss']
})
export class PricingsComponent implements OnInit {

  constructor(private quoteService: QuoteService, private notifierService: NotifierService, public dialog: MatDialog, public dataService: DataService, private router: Router, private shared: SharedService) {
    this.quoteService.GetAllIssuer().subscribe(data => this.issuers = data,
      error => this.processError(error),
      () => console.log('Get all issuers complete'));

    this.quoteService.GetAllCurrency().subscribe(data => this.currencies = data,
      error => this.processError(error),
      () => console.log('Get all currencies complete'));
  }

  lastNotif: NotifResponseDto;
  isPricing: boolean = false;

  dataSource: MatTableDataSource<InstrumentDto>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  issuers: CodeValueDto[];
  currencies: CodeValueDto[];
  pricingInstruments: InstrumentDto[];

  displayedColumns = ['Code', 'Name', 'LastPrice', 'Currency', 'UpdatedOn','LastResponseDate'];

  ngOnInit(): void {

    this.quoteService.GetAllPricingInstruments().subscribe(data => {
      this.pricingInstruments = data;
      this.dataSource = new MatTableDataSource(this.pricingInstruments);
      this.dataSource.paginator = this.paginator;
    },
      error => this.processError(error),
      () => console.log('Get all pricingInstruments complete'));
  }


  ngDoCheck() {
    this.lastNotif = this.shared.getNotifications();
    if (this.lastNotif) {
      this.isPricing = this.lastNotif.isPricing;
    }
  }

  PriceAllInstruments() {
    this.quoteService.PriceAllInstruments()
    .subscribe(x => {
      this.notifierService.notify("success", "Manual pricing is running.."), error => this.processError(error),
        () => console.log('Pricing Error')
    });
  }


  private processError(e: any) {
    console.log(e);

    if (e.status == 401 || e.status == 403) {
      this.notifierService.notify("warning", "You are not authorized to do this action.");
    } else {
      let message = "Unexpected error";
      if (e.error && e.error.Message) {
        message = e.error.Message;
      } else if (e.error) {
        message = e.error;
      } else if (e.status) {
        message += " (" + e.status + ") ";
      }

      console.log(message);
      this.notifierService.notify("error", message);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  addNew() {
//redirection
    var link = "/instruments";
    this.router.navigateByUrl(link);
  }

  private refreshTable() {
    setTimeout(() =>
      this.dataSource = new MatTableDataSource(this.pricingInstruments),
    );
  }


}
