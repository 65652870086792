import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, from } from 'rxjs';
import { ClientDto } from '../quoting/ClientDto.Model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private httpClient: HttpClient) {
  }


  public GetClientById(id: string): Observable<ClientDto> {
    let datas = new HttpParams().set('id', id);
    return this.httpClient.get<ClientDto>('/api/client/GetClientById', { params: datas });
  }

  public GetClientsByCode(code: string): Observable<any> {
    let datas = new HttpParams().set('code', code).set('take', "25");
    return this.httpClient.get<Array<ClientDto>>('/api/client/GetClientsByCode', { params: datas });
  }

  public GetOnBehalfClientsByCode(code: string, sales: string): Observable<any> {
    let datas = new HttpParams().set('code', code).set('sales', sales).set('take', "25");
    return this.httpClient.get<Array<ClientDto>>('/api/client/GetOnBehalfClientsByCode', { params: datas });
  }

}
