import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../../services/data.service';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-quotedesc',
  templateUrl: './edit-quotedesc.component.html',
  styleUrls: ['./edit-quotedesc.component.scss']
})
export class EditQuotedescComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<EditQuotedescComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public dataService: DataService
  ) { }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  stopEdit(): void {
    if (this.data && this.data.quoteChild && this.data.quoteChild.Product)this.data.quoteChild.Product.PayoffDesc = this.data.quoteChild.Description2.replaceAll('\n', '<br>');
    this.dataService.updateDescription(this.data);
  }

  ngOnInit(): void {
    if (this.data && this.data.quoteChild && this.data.quoteChild.Product && this.data.quoteChild.Product.PayoffDesc) {
      this.data.quoteChild.Description2 = this.data.quoteChild.Product.PayoffDesc.replaceAll('<br>', '\n');
    }
  }

}
