import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserRightsService {

  constructor(private httpClient: HttpClient) { }

  public get(){  
		return this.httpClient.get(`/api/userRights`);  
  }
  public GetAuth(): Observable<any> {
    return this.httpClient.get('/datas/GetAuth');
  }
}
