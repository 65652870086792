import { Component, OnInit, ViewChild } from '@angular/core';
import { AppSettingsService } from '../app-settings.service';
import { UserRightsService } from '../user-rights.service';
import { QuoteService } from '../services/quote.service';
import { DataService } from '../services/data.service';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { SignalRService } from '../services/signal-r.service';
import { HttpClient } from '@angular/common/http';
import { NotifResponseDto } from '../models/NotifResponseDto';
import { SharedService } from '../services/shared.service';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {

  constructor(public signalRService: SignalRService, private http: HttpClient, private appSettingsService: AppSettingsService, private quoteService: QuoteService, private userRightsService: UserRightsService, private dataService: DataService, private shared: SharedService) { }

  settings: any;
  userRights: any;
  isQuoting: boolean;
  hide: boolean = false;
  numberQuoteChildSent: number = 0;

  ngOnInit(): void {   

    this.appSettingsService.getAll().subscribe((data: any[])=>{  
			this.settings = data;
    });
    
    this.userRightsService.get().subscribe((data: any[])=>{  
      this.userRights = data;
   
    });
    this.userRightsService.GetAuth().subscribe((data) => {
      this.isQuoting = data;
    });

    this.dataService.hideSidebar.subscribe((msg: any) => {
      this.hide = msg.isHidden;

      //if (this.hide) {
      //  this.closeClick();
      //}
      //else {
      //  this.openClick();
      //}
    });

    this.signalRService.startConnection();

    this.signalRService.hubConnection.on('QuotingCount', (count: NotifResponseDto) => {
      this.numberQuoteChildSent = count.countQuotesOrderSent;
      this.shared.setNotifications(count);
      console.log("************************ New Quoting Count received from SignalR " + count);
    });

  }

  @ViewChild('sidebar') sidebar: SidebarComponent;
  public open(event) {
    console.log("Sidebar Opened");
  }
  public close(event) {
    console.log("Sidebar Closed");
  }
  toggleClick() {
    this.sidebar.toggle();
  }
  closeClick() {
    this.sidebar.hide();
  }
  openClick() {
    this.sidebar.open();
  }

  getMainClass(): string {
    return (this.settings) ? this.settings.EnvironmentSideBarClass : "";
  }


  //private startHttpRequest = () => {
  //  this.http.get('http://localhost:5000/api/signalR/qcSent')
  //    .subscribe(res => {
  //      console.log(res);
  //    })
  //}


}
