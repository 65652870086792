<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<ol class="breadcrumb row">
  <li><a ui-sref="home">Home</a></li>
  <li>Create Minifuture</li>
</ol>
<div class="row" *ngIf="valError">
  <br />
  <span *ngFor="let item of errors" style="margin-left:5px ;padding: 5px;" class="alert-danger">
    {{item}}
  </span>
</div>

<br />
<br />

<div class="row">
  <h4>Create Minifuture</h4>
</div>

<br />
<br />



<div class="row">

  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">ISIN</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <input required type="text" style="width: 100%;" [(ngModel)]="primaryQuote.MiniFuture.Isin" />

    </div>
  </div>

</div>
<div class="row">

  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Underlying(Ticker)</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">

      <underlying-autocomplete required [(underId)]="primaryQuote.MiniFuture.UnderlyingId"
                               (underChange)="parseUnder($event)" class="col-md-12">
      </underlying-autocomplete>
    </div>
  </div>

</div>


<div class="row">

 

  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">StopLoss</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <input required type="number" step="0.01" style="width: 100%;" [(ngModel)]="primaryQuote.MiniFuture.StopLoss" />

    </div>
  </div>

</div>
<div class="row">

  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Currency</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <code-value [(code)]="primaryQuote.MiniFuture.Currency" [type]="'Currency'">
      </code-value>
    </div>
  </div>


</div>
<div class="row">

  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Sens</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <code-value [(code)]="primaryQuote.MiniFuture.Sens" [type]="'ShortLong'">
      </code-value>
    </div>
  </div>
  <!--<div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Asset class</label>
    <div class="col-md-3">
      <code-value [(code)]="primaryQuote.MiniFuture.AssetClass" [type]="'AssetClass'">
      </code-value>
    </div>
  </div>-->

</div>

<div class="row">
  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Issuer</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <code-value [type]="'Issuer'" [(code)]="primaryQuote.MiniFuture.IssuerCode">
      </code-value>
    </div>
  </div>
</div>
<div class="row">
  <div class="form-group form-group-sm">

    <label class="col-md-1 align" for="Name">Strike</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <input required type="number" step="0.01" style="width: 100%;" [(ngModel)]="primaryQuote.MiniFuture.Strike" />

    </div>
  </div>

</div>
<div class="row">
  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Levier initial (approx)</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <input required type="number" step="0.01" style="width: 100%;" [(ngModel)]="primaryQuote.MiniFuture.LevierInitial" />

    </div>
  </div>
</div>
<div class="row">
  <div class="form-group form-group-sm">
    <label class="col-md-1 align" for="Name">Asset class</label>
    <div class="col-md-1">
    </div>
    <div class="col-md-2">
      <code-value [(code)]="primaryQuote.MiniFuture.AssetClass" [type]="'AssetClass'">
      </code-value>
    </div>
  </div>
</div>
<div class="footer">
  <div class="command">
    <div class="form-group col-md-12" style="text-align: right;">
      <button type="button" [ngClass]="{'disabled': clicked }" (click)="createDealDraft()" class="btn btn-primary">Create</button>
    </div>
  </div>
</div>

