<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>


<div>

  <h4>{{category.Code}} .{{category.Label}}</h4>
  <div class="bloc">
    <div class="extended-bloc" [style.background-color]="bgColor(category.Code)" [style.border]="isComplete(category)">
      <div class="row">

        <label class="col-1" for="support">Support</label>
        <div class="col-1">
          <code-value [type]="'Channel'" [parentCode]="index==0 || index==3 ? 'NULL' : '#1#2' " [(code)]="category.Support" [disabled]="isDisabled(category.Code)">
          </code-value>
        </div>
        <div class="col-10"></div>
      </div>

      <div class="row" *ngFor="let file of category.Files; let i = index">
        <label class="col-1" for="Sides" *ngIf="!file.IsDeleted">Title</label>
        <div class="col-7" style=" margin-right: -50px;" *ngIf="!file.IsDeleted">
          <app-file-input [disabled]="isDisabled(category.Code) || file.Name == 'OrderMail.txt' || file.Name == 'DoneDealMail.txt'" [fileToUpload]="file" [(fileName)]="file.Name" #childComp (fileChange)="fileOnChange($event,file)"></app-file-input>

        </div>


        <!--<div class="col-1">
  </div>-->
        <div *ngIf="(!isDisabled(category.Code) || isSuperUser || isSales()) && !file.IsDeleted" class="">
          <div class="donwload-icon"><i (click)="downloadFile(file.file)" style="font-size: medium;" class="col-1 fa fa-download fa-2" title="Download"></i></div>
        </div>
        <div class="" *ngIf="(!isDisabled(category.Code) && !file.IsDeleted && (file.Name != 'OrderMail.txt' && file.Name != 'DoneDealMail.txt'))">
          <i class="delete-icon fa fa-trash fa-2" (click)="deleteFile(file,disabled)"  title="Delete file"></i>
        </div>

      </div>

      <br />
      <div class="row">
        <div class="col-2">
          <div [class.disabled]="hasEmptyLine()" class="dynamic-link" title="Add New Line" (click)="addNewLine()">
            <i class="far fa-plus-circle"></i>
            <span class="dynamic-link-span">Add New Line</span>
          </div>
        </div>
        <div class="col-10"></div>

      </div>


    </div>
  </div>
</div>
