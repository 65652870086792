import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AppSettingsService {

  // localHttpClient is used to bypass any interceptors, as using interceptors
  // and app initializers together can lead to errors.
  private localHttpClient: HttpClient;

  private apiBaseUrl = '/api/settings';

  constructor(httpBackend: HttpBackend, private httpClient: HttpClient) {
    this.localHttpClient = new HttpClient(httpBackend);
  }

  public getAll() {  
    return this.httpClient.get(`${this.apiBaseUrl}`);
  }
  
  public getAuthentication() {
    return this.httpClient.get(`${this.apiBaseUrl}/authentication`);
  }

  public setCookie() {
    return this.httpClient.post(`${this.apiBaseUrl}/setcookie`,null);
  }

  public getAdalConfig(): Promise<Object> {
    return this.localHttpClient.get(`${this.apiBaseUrl}/adalconfig`).toPromise();

  }
  
}
