<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<div class="alert alert-danger" role="alert" *ngIf="errorMessage">
  {{errorMessage}}
</div>

<h4 *ngIf="primaryQuote">Deal: {{primaryQuote.firstDeal.DealNbr}}</h4>
<div class="bloc" *ngIf="primaryQuote">
  <div class="extended-bloc">
    <h4> {{primaryQuote.Issuer}} - {{primaryQuote.TradeDate | date :'short' }} - {{primaryQuote.TotalSize}} - {{primaryQuote.firstDeal.Side}} - {{primaryQuote.firstDeal.Underlyings}}</h4>
    <div class="row">

      <label class="col-1" for="support"><u>Sales</u></label>
      <div class="col-1">
        <label>{{primaryQuote.firstDeal.Sales}}</label>
      </div>
      <label class="col-1" for="support"><u>Client</u></label>
      <div class="col-1">
        <label>{{primaryQuote.ClientCode}}</label>
      </div>
      <div class="col-8"></div>
    </div>
    <div class="row">

      <label class="col-1" for="support"><u>Name</u></label>
      <div class="col-11">
        <label>{{primaryQuote.ProductName}}</label>
      </div>

      <!--<div class="col-8"></div>-->
    </div>
    <div class="row">

      <label class="col-1" for="support"><u>Isin</u></label>
      <div class="col-3">
        <label>{{primaryQuote.ISIN}}</label>
      </div>

      <div class="col-8"></div>
    </div>
  </div>
</div>
<div *ngIf="primaryQuote">
  <div *ngFor="let c of primaryQuote.firstDeal.Categories ; let i = index">
    <app-category [isSuperUser]="isSuperUser" [group]="group" [index]="i" [category]="c"></app-category>
  </div>
</div>


<div class="footer">
  <div class="command">
    <div class="form-group col-md-12" style="text-align: right;">
      <button id="Rqst" type="button" style="font-size: large;" class="btn btn-primary" (click)="saveAuditTrails()" *ngIf="primaryQuote">Save</button>
    </div>
  </div>
</div>
