import { Component, OnInit } from '@angular/core';
import { QuoteService } from 'src/app/services/quote.service';
import { QuoteDto } from 'src/app/models/QuoteDto';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {

  quotes: Array<QuoteDto> = [];

  constructor(private quoteService: QuoteService) { }

  ngOnInit(): void {
    this.quoteService.GetAll(25, 0).subscribe(x => this.quotes = x);
  }

}
