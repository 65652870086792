import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UnderlyingIssuerDto } from 'src/app/models/UnderlyingIssuerDto';
import { QuoteService } from 'src/app/services/quote.service';
import { NotifierService } from "angular-notifier";
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DataService } from '../services/data.service';
import { AddunderlyingissuerComponent } from './UIDialogs/addunderlyingissuer/addunderlyingissuer.component';
import { UpdateunderlyingissuerComponent } from './UIDialogs/updateunderlyingissuer/updateunderlyingissuer.component';
import { CodeValueDto } from '../quoting/CodeValueDto.Model';
import { DeleteunderlyingissuerComponent } from './UIDialogs/deleteunderlyingissuer/deleteunderlyingissuer.component';

@Component({
  selector: 'app-underlyingissuers',
  templateUrl: './underlyingissuers.component.html',
  styleUrls: ['./underlyingissuers.component.scss']
})
export class UnderlyingissuersComponent implements OnInit {

  constructor(private quoteService: QuoteService, private notifierService: NotifierService, public dialog: MatDialog, public dataService: DataService) {
    this.quoteService.GetAllIssuer().subscribe(data => this.issuers = data,
      error => this.processError(error),
      () => console.log('Get all issuers complete'));

    this.quoteService.GetAllCurrency().subscribe(data => this.currencies = data,
      error => this.processError(error),
      () => console.log('Get all currencies complete'));
  }


  dataSource: MatTableDataSource<UnderlyingIssuerDto>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  issuers: CodeValueDto[];
  currencies: CodeValueDto[];
  underlyingIssuers: UnderlyingIssuerDto[];

  displayedColumns = ['ISIN', 'BloombergCode', 'IssuerCode', 'CurrencyCode', 'actions'];

  ngOnInit(): void {

    this.quoteService.GetAllUnderlyingIssuers().subscribe(data => {
      this.underlyingIssuers = data;
      this.dataSource = new MatTableDataSource(this.underlyingIssuers);
      this.dataSource.paginator = this.paginator;
    },
      error => this.processError(error),
      () => console.log('Get all UnderlyingIssuers complete'));
  }


  private processError(e: any) {
    console.log(e);

    if (e.status == 401 || e.status == 403) {
      this.notifierService.notify("warning", "You are not authorized to do this action.");
    } else {
      let message = "Unexpected error";
      if (e.error && e.error.Message) {
        message = e.error.Message;
      } else if (e.error) {
        message = e.error;
      } else if (e.status) {
        message += " (" + e.status + ") ";
      }

      console.log(message);
      this.notifierService.notify("error", message);
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  addNew() {
    var Id = null;
    var IssuerCode = null;
    var CurrencyCode = null;
    var ISIN = null;
    var BloombergCode = null;
    const dialogRef = this.dialog.open(AddunderlyingissuerComponent, {
      data: { Id: Id, CurrencyCode: CurrencyCode, ISIN: ISIN, BloombergCode: BloombergCode, issuers: this.issuers, currencies: this.currencies }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

        var addedUI = this.dataService.getDialogData();
        var issuer = this.issuers.filter(i => i.Code == addedUI.IssuerCode);

        //Save database
        this.quoteService.AddUnderlyingIssuer(addedUI).subscribe(c => {
          addedUI = c;
          this.underlyingIssuers.push(addedUI);
          this.notifierService.notify("success", "Line successfully added")

          //refresh table
          this.refreshTable();
        },
          error => this.processError(error));

      }
    }, error => this.processError(error));
  }

  private refreshTable() {
    setTimeout(() =>
      this.dataSource = new MatTableDataSource(this.underlyingIssuers),
    );
  }
  startEdit(i: number, element: UnderlyingIssuerDto, Id: string, IssuerCode: string, CurrencyCode: string, ISIN: string, BloombergCode: string) {

    const dialogRef = this.dialog.open(UpdateunderlyingissuerComponent, {
      data: { Id: Id, IssuerCode: IssuerCode, CurrencyCode: CurrencyCode, ISIN: ISIN, BloombergCode: BloombergCode, issuers: this.issuers, currencies: this.currencies }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

        var editedUI = this.dataService.getDialogData();
        var issuer = this.issuers.filter(i => i.Code == editedUI.IssuerCode);
        //editedUI.IssuerName = issuer[0].Description;

        //Save database
        this.quoteService.UpdateUnderlyingIssuer(editedUI).subscribe(c => {
          editedUI = c;
        },
          error => this.processError(error));

        this.underlyingIssuers = this.underlyingIssuers.filter(i => i.Id !== editedUI.Id);
        this.underlyingIssuers.push(editedUI);
        this.notifierService.notify("success", "Line successfully updated")
        //refresh table
        this.refreshTable();
      }
    }, error => this.processError(error));
  }

  deleteItem(i: number, Id: string) {
    const dialogRef = this.dialog.open(DeleteunderlyingissuerComponent, {
      data: { Id: Id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {
        var deletedUI = this.dataService.getDialogData();

        //Save database
        this.quoteService.DeleteUnderlyingIssuer(deletedUI).subscribe(c => {
          deletedUI = c;
        },
          error => this.processError(error));
        this.underlyingIssuers = this.underlyingIssuers.filter(i => i.Id !== deletedUI.Id);
        this.notifierService.notify("success", "Line successfully deleted")
        //refresh table
        this.refreshTable();
      }
    }, error => this.processError(error));
  }
  noResponse() {
  }

}
