import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../services/data.service';
import { FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-updateunderlyingissuer',
  templateUrl: './updateunderlyingissuer.component.html',
  styleUrls: ['./updateunderlyingissuer.component.scss']
})
export class UpdateunderlyingissuerComponent implements OnInit {


  constructor(public dialogRef: MatDialogRef<UpdateunderlyingissuerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public dataService: DataService
  ) { }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  stopEdit(): void {
    this.dataService.updateUnderlyingIssuer(this.data);
  }


  ngOnInit(): void {
  }

}
