<div>
  <!--<mat-form-field class="col-md-3">
    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Id" />
  </mat-form-field>
  <mat-form-field class="col-md-3">
    <input (keyup)="applyFilter($event.target.value)" matInput placeholder="Name" />
  </mat-form-field>-->
  <div class="row">
    <fieldset class="col-md-10">
      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="idFilter" placeholder="Id">
      </mat-form-field>
      <div class="col-md-1"></div>
      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="nameFilter" placeholder="Name">
      </mat-form-field>
      <div class="col-md-1"></div>
      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="isinFilter" placeholder="ISIN">
      </mat-form-field>

      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="accountFilter" placeholder="Account">
      </mat-form-field>
      <div class="col-md-1"></div>
      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="typeFilter" placeholder="Type">
      </mat-form-field>
      <div class="col-md-1"></div>
      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="dateFilter" placeholder="date">
      </mat-form-field>

      <mat-form-field class="col-md-3">
        <!--<input matInput class="form-field" [formControl]="sideFilter" placeholder="Side Filter">-->

        <mat-label>Side </mat-label>
        <select [formControl]="sideFilter" matNativeControl>
          <option value="">---</option>
          <option value="Buy">Buy</option>
          <option value="Sell">Sell</option>
        </select>

      </mat-form-field>
      <div class="col-md-1"></div>
      <mat-form-field class="col-md-3">
        <!--<input matInput class="form-field" [formControl]="currencyFilter" placeholder="Currency Filter">-->

        <mat-label>Currency </mat-label>
        <mat-select [formControl]="currencyFilter">
          <mat-option *ngFor="let curr of currencies" [value]="curr.Description">
            {{curr.Description}}
          </mat-option>
        </mat-select>

      </mat-form-field>
      <div class="col-md-1"></div>
      <mat-form-field class="col-md-3">
        <input matInput class="form-field" [formControl]="counterpartFilter" placeholder="CounterPart">
      </mat-form-field>
    </fieldset>
    <fieldset>
      <button mat-raised-button (click)="exporter.exportTable('xlsx',{fileName:'logbook'})">Download Excel</button>
      <!--<div ></div>-->
      <button mat-raised-button (click)="exporter.exportTable('pdf',{fileName:'logbook'})">Download CSV</button>
    </fieldset>
  </div>
</div>
<br />
<div class="mat-table">
  <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" class="mat-elevation-z8">
    <ng-container matColumnDef="Id">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">ID</th>
      <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
    </ng-container>
    <ng-container matColumnDef="SecurityType">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Security Type</th>
      <td mat-cell *matCellDef="let element"> {{element.SecurityType}} </td>
    </ng-container>
    <ng-container matColumnDef="ISIN">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">ISIN</th>
      <td mat-cell *matCellDef="let element"> {{element.ISIN}} </td>
    </ng-container>
    <ng-container matColumnDef="Security">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Security</th>
      <td mat-cell *matCellDef="let element"> {{element.Security}} </td>
    </ng-container>
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Name</th>
      <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
    </ng-container>
    <ng-container matColumnDef="Ticker">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Ticker</th>
      <td mat-cell *matCellDef="let element"> {{element.Ticker}} </td>
    </ng-container>
    <ng-container matColumnDef="PrincipalAgency">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Principal Agency</th>
      <td mat-cell *matCellDef="let element"> {{element.PrincipalAgency}} </td>
    </ng-container>
    <ng-container matColumnDef="OrderReceptionDate">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Order Reception Date</th>
      <td mat-cell *matCellDef="let element"> {{element.OrderReceptionDate}} </td>
    </ng-container>
    <ng-container matColumnDef="OrderReceptionTime">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;"> Order reception time</th>
      <td mat-cell *matCellDef="let element"> {{element.OrderReceptionTime}} </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Status</th>
      <td mat-cell *matCellDef="let element"> {{element.Status}} </td>
    </ng-container>

    <ng-container matColumnDef="Account">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Account</th>
      <td mat-cell *matCellDef="let element"> {{element.Account}} </td>
    </ng-container>

    <ng-container matColumnDef="Sell">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Side</th>
      <td mat-cell *matCellDef="let element"> {{element.Sell}} </td>
    </ng-container>

    <ng-container matColumnDef="OpenCLose">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Open/Close</th>
      <td mat-cell *matCellDef="let element"> {{element.OpenCLose}} </td>
    </ng-container>

    <ng-container matColumnDef="NatureOfTransaction">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Nature Of Transaction</th>
      <td mat-cell *matCellDef="let element"> {{element.NatureOfTransaction}} </td>
    </ng-container>

    <ng-container matColumnDef="OrderType">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Order Type</th>
      <td mat-cell *matCellDef="let element"> {{element.OrderType}} </td>
    </ng-container>

    <ng-container matColumnDef="OrderQuantity">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Order Quantity</th>
      <td mat-cell *matCellDef="let element"> {{element.OrderQuantity}} </td>
    </ng-container>

    <ng-container matColumnDef="TIF">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">TIF</th>
      <td mat-cell *matCellDef="let element"> {{element.TIF}} </td>
    </ng-container>

    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element"> {{element.Type}} </td>
    </ng-container>

    <ng-container matColumnDef="LimitPrice">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Limit Price</th>
      <td mat-cell *matCellDef="let element"> {{element.LimitPrice}} </td>
    </ng-container>

    <ng-container matColumnDef="Strike">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Strike</th>
      <td mat-cell *matCellDef="let element"> {{element.Strike}} </td>
    </ng-container>

    <ng-container matColumnDef="Currency">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Currency</th>
      <td mat-cell *matCellDef="let element"> {{element.Currency}} </td>
    </ng-container>

    <ng-container matColumnDef="ReceptionChannel">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Reception Channel</th>
      <td mat-cell *matCellDef="let element"> {{element.ReceptionChannel}} </td>
    </ng-container>

    <ng-container matColumnDef="ExecutionDate">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Execution Date</th>
      <td mat-cell *matCellDef="let element"> {{element.ExecutionDate}} </td>
    </ng-container>

    <ng-container matColumnDef="ExecutionTime">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Execution Time</th>
      <td mat-cell *matCellDef="let element"> {{element.ExecutionTime}} </td>
    </ng-container>

    <ng-container matColumnDef="ExecutionQuantity">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Execution Quantity</th>
      <td mat-cell *matCellDef="let element"> {{element.ExecutionQuantity}} </td>
    </ng-container>

    <ng-container matColumnDef="Market">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Market</th>
      <td mat-cell *matCellDef="let element"> {{element.Market}} </td>
    </ng-container>

    <ng-container matColumnDef="Originator">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Originator</th>
      <td mat-cell *matCellDef="let element"> {{element.Originator}} </td>
    </ng-container>

    <ng-container matColumnDef="Trader">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Trader</th>
      <td mat-cell *matCellDef="let element"> {{element.Trader}} </td>
    </ng-container>

    <ng-container matColumnDef="BrokerBank">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Broker/Bank</th>
      <td mat-cell *matCellDef="let element"> {{element.BrokerBank}} </td>
    </ng-container>

    <ng-container matColumnDef="Counterparty">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Counterparty</th>
      <td mat-cell *matCellDef="let element"> {{element.Counterparty}} </td>
    </ng-container>

    <ng-container matColumnDef="BeneficialOwner">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Beneficial Owner</th>
      <td mat-cell *matCellDef="let element"> {{element.BeneficialOwner}} </td>
    </ng-container>

    <ng-container matColumnDef="ValueDate">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Value Date</th>
      <td mat-cell *matCellDef="let element"> {{element.ValueDate}} </td>
    </ng-container>

    <ng-container matColumnDef="PercentUnit">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Percent/Unit</th>
      <td mat-cell *matCellDef="let element"> {{element.PercentUnit}} </td>
    </ng-container>

    <ng-container matColumnDef="Comment">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Comment</th>
      <td mat-cell *matCellDef="let element"> {{element.Comment}} </td>
    </ng-container>

    <ng-container matColumnDef="ExecutionPrice">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Execution Price</th>
      <td mat-cell *matCellDef="let element"> {{element.ExecutionPrice}} </td>
    </ng-container>

    <ng-container matColumnDef="ClientPrice">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Client Price</th>
      <td mat-cell *matCellDef="let element"> {{element.ClientPrice}} </td>
    </ng-container>

    <ng-container matColumnDef="SettlementPrice">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">Settlement Price</th>
      <td mat-cell *matCellDef="let element"> {{element.SettlementPrice}} </td>
    </ng-container>

    <ng-container matColumnDef="CFICode">
      <th mat-header-cell *matHeaderCellDef style="width: 60px;">CFI Code</th>
      <td mat-cell *matCellDef="let element"> {{element.CFICode}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
