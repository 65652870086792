import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from '../../../../services/data.service';
import { FormControl, Validators } from '@angular/forms';
import { QuoteChildIssuerDto } from '../../../../models/QuoteChildIssuerDto';


@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-dialog.component.html',
  styleUrls: ['./add-dialog.component.scss']
})
export class AddDialogComponent implements OnInit {

  @ViewChild('inputsolvefor', { static: false }) inputEl: ElementRef;

  constructor(public dialogRef: MatDialogRef<AddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    , public dataService: DataService
  ) { }

  formControl = new FormControl('', [
    Validators.required
  ]);

  getErrorMessage() {
    return this.formControl.hasError('required') ? 'Required field' :
      this.formControl.hasError('email') ? 'Not a valid email' :
        '';
  }

  submit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public confirmAdd(): void {
    if (this.data.PriceOff) {
      this.data.SolveFor = 0;
    }
    this.dataService.addPrice(this.data);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.inputEl.nativeElement.focus();
    }, 100)

  }

  ngAfterViewInit() {
    setTimeout(() => this.inputEl.nativeElement.focus());
  }
  //onBlur(event) {
  //  this.inputEl.nativeElement.focus();
  //}


}
