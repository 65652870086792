import { ProductDto } from '../quoting/ProductDto.Model';
import { DealDto } from './DealDto.Model';
import { MiniFutureDto } from './MiniFutureDto.Model';
import { CheckBoxDto } from './CheckBoxDto.Model';
import { SecondaryQuoteDto } from '../quoting/SecondaryQuoteDto.Model';


export class PrimaryQuoteDto {
  public ProductId: string;
  public Product: ProductDto;
  public IsPrimaryQuote: boolean;
  public IsMinifut: boolean;
  public ClientId: string;
  public Settlement: number;
  public GrossPrice: number;
  public Quantity: number;
  public ClientCode: string;
  public IssuerCode: string;
  public Issuer: string;
  public IssueDates: number;
  public StrikeType: string;
  public DeliveryType: string;
  public ProductType: string;
  public CustodianCode: string;
  public CustodianDescription: string;
  public Currency: string;
  public QuotationTypeCode: string;
  public OrderId: string;
  public OnBehalf: string;
  public Amortissement: number;
  public CustodianSize: number;
  public TotalSize: number;
  public NbUnit: number;
  public deals: DealDto[] = [];
  public SendAuto: boolean;
  public firstDeal: DealDto = {
    Quantity: 0,
    Settlement: 0,
    ClientCode: "",
    ClientId: "",
    OrderId: "",
    Trader: "",
    Client: "",
    side: "",
    isin: "",
    issuer: "",
    title: "",
    maturity: "",
    size: "",
    currency: "",
    underlyings: "",
    ShowAllSize: false,
    Custodians: [],
    Comment: '',
    TBD: false,
    IsTBD: false,
    AllSize: 0,
    IsEqualSizes: false,
    DealNbr: "",
    Status:"",
    Color: "",
    QuoteChildNbr: "",
    ClientPrice: 0,
    Margins: [],
    OrderDescription: "",
    DealDescription: "",
    Selected: false


  };
  public SolveForCode: string;
  public Reoffer: number;
  public SolveForValue: number = 0;
  public OrBetterSolve: CheckBoxDto = { Value: false, IsShown: true };
  public AtMarketReoffer: CheckBoxDto = { Value: false, IsShown: true };
  public OrBetterReoffer: CheckBoxDto = { Value: false, IsShown: true };
  public AtMarketSolve: CheckBoxDto = { Value: false, IsShown: true };

  public SecondaryQuote: SecondaryQuoteDto;
  public MiniFuture: MiniFutureDto;
  public QuoteNbr: string;
  public Comment: string;
  public Id: string;
  public IsMinifuture: boolean;
  public MKTSolve: boolean;
  public MKTReoffer: boolean;
  public IsFirstOrder: boolean;
  public TradeDate: string;
  public TradeTime: string;
  public Support: string;
  public Team: string;
  public ISIN: string;
  public ProductName: string;
 


}
