<div class="form-group">
  <select class="form-control" (change)="getPrice(dateSelected)" style="width: 150px;" [(ngModel)]="dateSelected">
    <option *ngFor="let d of dateList" [ngValue]="d">{{d| date:'dd/MM/yyyy'}}</option>
  </select>
</div>

<div class="flex-row">
  <div *ngFor="let item of prices" class="flex-column"
       style="margin: 10px;background-color: lightgray; padding: 5px; width: 150px; height: 50px;align-items: center;justify-content: center;">
    <div style="font-weight: bold;">{{item.Name}}</div>
    <div>{{item.Price}} {{item.Currency}}</div>
  </div>
</div>

<h3>Copy Price from date</h3>
<div class="form-inline">
  <div class="form-group">
    <label style="margin-right: 10px;">From</label>
    <input type="date" [(ngModel)]="dateFrom" class="form-control" style="width: 150px;" />
    <label style="margin-right:10px; margin-left: 10px;">To</label>
    <input type="date" [(ngModel)]="dateTo" class="form-control" style="width: 150px;" />
  </div>
  <button type="button" class="btn btn-primary mb-2" (click)="copy()">Copy</button>
</div>
