import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ECBCurrencyDto } from '../models/ECBCurrencyDto';
import { CopyCurrencyDto } from '../models/CopyCurrencyDto';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InstrumentService {

  constructor(private httpClient: HttpClient) { }

  public getECBCurrencyDate(): Observable<Array<Date>> {
    return this.httpClient.get<Array<Date>>("/api/Instrument/GetECBCurrencyDate");
  }

  public getECBCurrencyPrice(date: Date): Observable<Array<ECBCurrencyDto>> {

    let params = new HttpParams().set('date', new Date(date).toJSON());
    return this.httpClient.get<Array<ECBCurrencyDto>>("/api/Instrument/GetECBCurrencyPrice", { params: params });
  }

  public copyECBCurrency(dateFrom: Date, dateTo: Date): Observable<string> {

    let params = new CopyCurrencyDto();
    params.From = new Date(dateFrom);
    params.To = new Date(dateTo);
    return this.httpClient.post<string>("/api/Instrument/CopyECBCurrency", params);
  }

}
