import { CodeValueDto } from '../quoting/CodeValueDto.Model';
import { QuoteChildDto } from "./QuoteChildDto";

export class QuoteChildIssuerDto {
  $id: string;
  Id: string;
  IssuerCode: string;
  IssuerName: string;
  QuoteChildId: string;
  QuoteChild: QuoteChildDto;
  SolveFor: string;
  SolveForValue: string;
  Comment: string;
  LastUpdate: string;
  CounterpartId: string;
  AutoPriceMessage: string;
  AutoPrice: boolean;
  PriceOff: boolean;
  Selected: boolean;
}
