import { Component, OnInit, Inject } from '@angular/core';
//import { PHONE_SERVICE } from "./phone.service";

@Component({
  selector: 'ng10-demo',
  template: `
    <h3>Baboum !</h3>
    {{ message }}
  `
})
export class Ng10DemoComponent implements OnInit {

  phones: any[] = [];
  message: string;

  constructor() {
    }

    ngOnInit() {
      //this.phones = []; // this.phoneService.query();
      this.message = "Hey ! I'am an Angular 10 component";
    }

}
