<mat-form-field style="width: 100%;">
  <input matInput aria-label="Underlying" [matAutocomplete]="auto" [formControl]="searchUnderCtrl" [readonly]="disabled">
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngIf="isLoading" class="is-loading">Loading...</mat-option>
    <ng-container *ngIf="!isLoading" [disabled]="disabled">
      <mat-option *ngFor="let under of filteredUnderlyings" [value]="under.Code" (onSelectionChange)="setUnder(under)">
        <span><b>{{under.Code}}</b> </span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
