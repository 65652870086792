<style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
</style>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css">

<div class="custom-file">
  <div class="row" style="flex-wrap: nowrap;margin-right: -36px;">
    <input #myInput type="file" class="custom-file-input" id="importFile" [disabled]="disabled" (change)="onFileChange($event.target.files)">
    <label class="custom-file-label" style="overflow: hidden;" #labelImport for="importFile"><span *ngIf="fileName != ''">{{fileName}}</span><span *ngIf="!fileName">Choose file</span></label>


    <!--<i class=" delete-icon fa fa-trash fa-2" (click)="deleteFile(fileToUpload,disabled)" style="font-size: initial;" title="Delete file"></i>-->
  </div>
</div>
<!--<link rel="stylesheet" href="file-input.component.scss">-->


